import React from "react";
import ModelFilter from "../../SingleOrder/Filters/ModelFilter/ModelFilter";
import {
    getClients,
    getInspectors, getMakes, getModels, getPorts,
    getTransportCompanies,
    getTransports
} from "../../../../../utils/utils";
import inspectionTypes from "../../../../../fixedData/inspectionTypes";
import Style from "../../SingleOrder/Filters/Filters.module.scss";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';

import countries from "../../../../../fixedData/countries";
import BaseSelect from "react-select";

const phaseOptions = [
    {
        value: 1,
        label: "Announcement"
    },
    {
        value: 2,
        label: "Order created"
    },
    {
        value: 3,
        label: "Inspection in Progress"
    },
    {
        value: 4,
        label: "Inspection Completed"
    },
    {
        value: 5,
        label: "Preliminary Report Sent"
    },
    {
        value: 6,
        label: "Final Report Sent"
    },
    {
        value: 7,
        label: "Order Completed"
    }
];
const transportTypeOptions = [
    {
        value: "train",
        label: "Train"
    },
    {
        value: "truck",
        label: "Truck"
    },
    {
        value: "vessel",
        label: "Vessel"
    },
    {
        value: "barge",
        label: "Barge"
    },
    {
        value: "compound",
        label: "Compound"
    },
    {
        value: "factory",
        label: "Factory"
    },
    {
        value: "container",
        label: "Container"
    },
    {
        value: "shuttle",
        label: "Shuttle"
    }
]

const gateOptions = [
    {
        value: "arrival",
        label: "Arrival"
    },
    {
        value: "departure",
        label: "Departure"
    },
    {
        value: "internal",
        label: "Internal"
    }
]

class OrderFilters extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            'client_id': '',
            'inspector': '',
            'phase': '',
            'transport_type': '',
            'inspection_type': '',
            'transport': '',
            'gate': '',
            clientsArr: [],
            inspectorsArr: [],
            transportsArr: [],
            fromDate: '',
            toDate: '',
            transport_company: '',
            inspectionTypeOptions: [],

            voyage: '',
            destination: '',
            port_of_discharging: '',
            port_of_origin: '',
            inspection_place: '',
            make: '',
            model: '',
        };

        this.handleChange = this.handleChange.bind(this);
        this.populateOptions = this.populateOptions.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.clearFilters = this.clearFilters.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
    }

    setFromDate(date) {

        this.setState({
            fromDate:  date
        });
    }

    setToDate(date) {

        this.setState({
            toDate:  date
        });
    }

    componentDidMount() {
        this.populateOptions();
    }

    populateOptions() {
        let clientsArr = [],
            transportsArr = [],
            transportCompaniesArr = [],
            portsArr = [],
            makesArr = [],
            modelsArr = [],
            inspectionTypeOptions = [],
            inspectorsArr = [];

        getClients()
            .then(r => {
                for (const item of r) {
                    clientsArr.push({
                        'value': item.id,
                        'label': item.name
                    });
                }
            });

        getInspectors()
            .then(r => {
                for (const item of r) {
                    inspectorsArr.push({
                        'value': item.id,
                        'label': item.name
                    });
                }
            });

        getTransports()
            .then(r => {
                for (const item of r) {
                    transportsArr.push({
                        'value': item.id,
                        'label': item.identificationName
                    });
                }
            });

        getTransportCompanies()
            .then(r => {
                for (const item of r) {
                    transportCompaniesArr.push({
                        'value': item.id,
                        'label': item.name
                    });
                }
            });

        getPorts()
            .then(resp => {
                for (const item of resp) {
                    portsArr.push({
                        'value': item.id,
                        'label': item.name + ' (' + item.code + ')'
                    });
                }
            })

        getMakes()
            .then(r => {
                for (const item of r) {
                    makesArr.push({
                        'value': item.id,
                        'label': item.name
                    });
                }
            });

        getModels()
            .then(r => {
                for (const item of r) {
                    modelsArr.push({
                        'value': item.id,
                        'label': item.name
                    });
                }
            });

        if (inspectionTypes) {
            let types = [];
            for (let it in inspectionTypes) {
                for (let item of inspectionTypes[it]) {
                    types.push(item);
                }
            }

            let unique = [...new Set(types)];

            inspectionTypeOptions = unique?.map(item => {
                return {
                    'value': item,
                    'label': item
                }
            });

        }

        this.setState({
            clientsArr,
            inspectorsArr,
            transportsArr,
            transportCompaniesArr,
            portsArr,
            modelsArr,
            makesArr,
            inspectionTypeOptions
        })
    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.populateOptions();
        }
    }

    handleSubmit(e) {
        e.preventDefault();

        const {
            client_id,
            inspector,
            phase,
            transport_type,
            inspection_type,
            transport,
            gate,
            fromDate,
            toDate,
            transport_company,
            voyage,
            destination,
            port_of_discharging,
            port_of_origin,
            inspection_place,
            make,
            model
        } = this.state;

        this.props.updateItems({
            client_id,
            inspector,
            phase,
            transport_type,
            inspection_type,
            transport,
            gate,
            fromDate,
            toDate,
            transport_company,
            voyage,
            destination,
            port_of_discharging,
            port_of_origin,
            inspection_place,
            make,
            model
        });
    }

    clearFilters() {
        this.setState({
            'client_id': '',
            'inspector': '',
            'phase': '',
            'transport_type': '',
            'inspection_type': '',
            'transport': '',
            'gate': '',
            fromDate: '',
            toDate: '',
            "transport_company": '',
            voyage: '',
            destination: '',
            "port_of_discharging": '',
            "port_of_origin": '',
            "inspection_place": '',
            make: '',
            model: '',
        });

        this.props.updateItems();
    }


    handleSelectChange(obj, e) {

        this.setState({
            [e.name]: obj
        })
    }


    render() {
        const countriesOptions = countries?.map(item => {
            return {
                value: item.name,
                label: item.name
            }
        });


        return (
            <div>
                <form action="" onSubmit={this.handleSubmit}>
                    <div className="row mb-4">
                        <h2 className={"mb-3"}>Inspection Date Range</h2>
                        <div className={Style.dateField + ' col-auto mb-3'}>
                            <label htmlFor="inspection-date-from">From date</label>
                            <DatePicker className={'form-control'} dateFormat="dd.MM.yyyy" selected={this.state.fromDate} onChange={(date) => this.setFromDate(date)}/>
                        </div>
                        <div className={Style.dateField + ' col-auto mb-3'}>
                            <label htmlFor="inspection-date-to">To date</label>
                            <DatePicker className={'form-control'} dateFormat="dd.MM.yyyy" selected={this.state.toDate} onChange={(date) => this.setToDate(date)} />
                        </div>
                    </div>

                    <div className="row">
                        <h2 className="mb-3">Cargo Data</h2>
                        <div className="col-auto mb-3">
                            <label htmlFor="client_id">Client</label>
                            <BaseSelect
                                closeMenuOnSelect={true}
                                isMulti
                                options={this.state.clientsArr}
                                name="client_id"
                                value={this.state.client_id}
                                className="basic-multi-select filter-multi-select"
                                classNamePrefix="select"
                                onChange={this.handleSelectChange}
                            />
                        </div>
                        <div className="col-auto mb-3">
                            {/*<ModelFilter*/}
                            {/*    label={"Inspector"}*/}
                            {/*    elementName={"inspector"}*/}
                            {/*    elementId={"inspector-id"}*/}
                            {/*    value={this.state.inspector}*/}
                            {/*    handleChange={this.handleChange}*/}
                            {/*    items={this.state.inspectorsArr}*/}
                            {/*    idField={"id"}*/}
                            {/*    labelField={"name"}*/}
                            {/*/>*/}
                            <label htmlFor="inspector">Inspector</label>
                            <BaseSelect
                                closeMenuOnSelect={true}
                                isMulti
                                options={this.state.inspectorsArr}
                                name="inspector"
                                value={this.state.inspector}
                                className="basic-multi-select filter-multi-select"
                                classNamePrefix="select"
                                onChange={this.handleSelectChange}
                            />
                        </div>
                        <div className="col-auto mb-3">
                            <div>
                                <label htmlFor="phase">Phase</label>
                                <BaseSelect
                                    closeMenuOnSelect={true}
                                    isMulti
                                    options={phaseOptions}
                                    name="phase"
                                    value={this.state.phase}
                                    className="basic-multi-select filter-multi-select"
                                    classNamePrefix="select"
                                    onChange={this.handleSelectChange}
                                />
                            </div>
                        </div>
                        <div className="col-auto mb-3">
                            <div>
                                <label htmlFor="transport_type">Transport Type</label>
                                <BaseSelect
                                    closeMenuOnSelect={true}
                                    isMulti
                                    options={transportTypeOptions}
                                    name="transport_type"
                                    value={this.state.transport_type}
                                    className="basic-multi-select filter-multi-select"
                                    classNamePrefix="select"
                                    onChange={this.handleSelectChange}
                                />
                            </div>
                        </div>
                        <div className="col-auto mb-3">
                            <label htmlFor="transport_company">Transport Company</label>
                            <BaseSelect
                                closeMenuOnSelect={true}
                                isMulti
                                options={this.state.transportCompaniesArr}
                                name="transport_company"
                                value={this.state.transport_company}
                                className="basic-multi-select filter-multi-select"
                                classNamePrefix="select"
                                onChange={this.handleSelectChange}
                            />
                        </div>
                        <div className="col-auto mb-3">
                            {/*<label htmlFor="port-of-origin">Port of Origin</label>*/}
                            {/*<select name="port_of_origin" id="port-of-origin" className={"form-select"} onChange={this.handleChange} value={this.state.port_of_origin}>*/}
                            {/*    <option value="">Select Port of Origin</option>*/}
                            {/*    {portOptions}*/}
                            {/*</select>*/}
                            <label htmlFor="port_of_origin">Port of Origin</label>
                            <BaseSelect
                                closeMenuOnSelect={true}
                                isMulti
                                options={this.state.portsArr}
                                name="port_of_origin"
                                value={this.state.port_of_origin}
                                className="basic-multi-select filter-multi-select"
                                classNamePrefix="select"
                                onChange={this.handleSelectChange}
                            />
                        </div>

                        <div className="col-auto mb-3">
                            <label htmlFor="port_of_discharging">Port of Discharging</label>
                            {/*<select name="port_of_discharging" id="port-of-discharging" className={"form-select"} onChange={this.handleChange} value={this.state.port_of_discharging}>*/}
                            {/*    <option value="">Select Port of Discharging</option>*/}
                            {/*    {portOptions}*/}
                            {/*</select>*/}
                            <BaseSelect
                                closeMenuOnSelect={true}
                                isMulti
                                options={this.state.portsArr}
                                name="port_of_discharging"
                                value={this.state.port_of_discharging}
                                className="basic-multi-select filter-multi-select"
                                classNamePrefix="select"
                                onChange={this.handleSelectChange}
                            />
                        </div>

                        <div className="col-auto mb-3">
                            <label htmlFor="make">Make</label>
                            {/*<select name="make" id="make" className={"form-select"} onChange={this.handleChange} value={this.state.make}>*/}
                            {/*    <option value="">Select Make</option>*/}
                            {/*    {makeOptions}*/}
                            {/*</select>*/}
                            <BaseSelect
                                closeMenuOnSelect={true}
                                isMulti
                                options={this.state.makesArr}
                                name="make"
                                value={this.state.make}
                                className="basic-multi-select filter-multi-select"
                                classNamePrefix="select"
                                onChange={this.handleSelectChange}
                            />
                        </div>

                        <div className="col-auto mb-3">
                            <label htmlFor="model">Model</label>
                            {/*<select name="model" id="model" className={"form-select"} onChange={this.handleChange} value={this.state.model}>*/}
                            {/*    <option value="">Select Model</option>*/}
                            {/*    {modelOptions}*/}
                            {/*</select>*/}
                            <BaseSelect
                                closeMenuOnSelect={true}
                                isMulti
                                options={this.state.modelsArr}
                                name="model"
                                value={this.state.model}
                                className="basic-multi-select filter-multi-select"
                                classNamePrefix="select"
                                onChange={this.handleSelectChange}
                            />
                        </div>


                        <div className="col-auto mb-3">
                            <label htmlFor="voyage">Voyage</label>
                            <input type="text" className={"form-control"} name="voyage" id={"voyage"} onChange={this.handleChange} value={this.state.voyage}/>
                        </div>
                        <div className="col-auto mb-3">
                            <label htmlFor="destination">Destination</label>
                            <BaseSelect
                                closeMenuOnSelect={true}
                                isMulti
                                options={countriesOptions}
                                name="destination"
                                value={this.state.destination}
                                className="basic-multi-select filter-multi-select"
                                classNamePrefix="select"
                                onChange={this.handleSelectChange}
                            />
                        </div>
                        <div className="col-auto mb-3">
                            <div>
                                <label htmlFor="gate">Gate</label>
                                {/*<select name="gate" id="gate" className={"form-select"} onChange={this.handleChange} value={this.state.gate}>*/}
                                {/*    <option value="">Select Gate</option>*/}
                                {/*    <option value="arrival">Arrival</option>*/}
                                {/*    <option value="departure">Departure</option>*/}
                                {/*    <option value="internal">Internal</option>*/}
                                {/*</select>*/}
                                <BaseSelect
                                    closeMenuOnSelect={true}
                                    isMulti
                                    options={gateOptions}
                                    name="gate"
                                    value={this.state.gate}
                                    className="basic-multi-select filter-multi-select"
                                    classNamePrefix="select"
                                    onChange={this.handleSelectChange}
                                />
                            </div>
                        </div>
                        <div className="col-auto mb-3">
                            <div>
                                <label htmlFor="inspection_type">Inspection Type</label>
                                <BaseSelect
                                    closeMenuOnSelect={true}
                                    isMulti
                                    options={this.state.inspectionTypeOptions}
                                    name="inspection_type"
                                    value={this.state.inspection_type}
                                    className="basic-multi-select filter-multi-select"
                                    classNamePrefix="select"
                                    onChange={this.handleSelectChange}
                                />
                            </div>
                        </div>
                        <div className="col-auto mb-3">
                            <label htmlFor="transport">Transport</label>
                            <BaseSelect
                                closeMenuOnSelect={true}
                                isMulti
                                options={this.state.transportsArr}
                                name="transport"
                                value={this.state.transport}
                                className="basic-multi-select filter-multi-select"
                                classNamePrefix="select"
                                onChange={this.handleSelectChange}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 d-flex align-items-center">
                            <button type={"submit"} className={"btn btn-primary"}>Filter</button>
                            <button onClick={this.clearFilters} className={"btn-min ms-3"}>Clear filters</button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

OrderFilters.propTypes = {};

export default OrderFilters;
