import React from "react";

class StockControls extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            bulkAction: '',
        };
        this.cargoSelectChange = this.cargoSelectChange.bind(this);
        this.handleBulkSubmit = this.handleBulkSubmit.bind(this);
        this.handleBulkChange = this.handleBulkChange.bind(this);
    }

    cargoSelectChange(e) {

        if (e.target.checked) {
            this.props.selectAllCargo();
        } else {
            this.props.unselectAllCargo();
        }
    }

    handleBulkSubmit(e) {
        e?.preventDefault();
        if (this.state.bulkAction) {
            this.props.bulkActionControl(this.state.bulkAction);
        }
    }

    handleBulkChange(e) {
        this.setState({
            bulkAction: e.target.value
        })
    }

    render() {
        return (
            <div>
                <div className="row align-items-center">
                    <div className="col-12 d-flex align-items-center">
                        <div className={"d-flex align-items-center"}>
                            <div className="form-check form-check-inline">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id={'cargo-select'}
                                    name="cargo-select"
                                    checked={this.props.selectAllChecked}
                                    onChange={this.cargoSelectChange}
                                />
                                <label className="form-check-label" htmlFor={"cargo-select"}>
                                    Select all
                                </label>
                            </div>
                        </div>
                        <form onSubmit={this.handleBulkSubmit}>
                            <div className={"d-flex align-items-center mx-4"}>
                                <select className={"form-select me-2"} name="sortBy" onChange={this.handleBulkChange}>
                                    <option value="">Bulk Actions</option>
                                    <option value="move">Move Items</option>
                                    <option value="delete">Delete Items</option>
                                </select>
                                <button type={"submit"} className={"btn btn-primary"}>Apply</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

StockControls.propTypes = {};

export default StockControls;
