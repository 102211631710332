import React from "react";
import {
    Link,
    withRouter
} from "react-router-dom";
import Store from "store";
import Axios from "axios";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft} from "@fortawesome/free-solid-svg-icons";
import {getDamageSetOptions} from "../../../../utils/utils";

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Options from "./Options/Options";
const SuccessModal = withReactContent(Swal);


class EditDamageSet extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            id: this.props.match.params.entryId,
            token: Store.get('accessToken').token,
            name: null
        };

        if (this.state.token && this.state.id) {
            Axios.get(process.env.REACT_APP_API_BASE + '/damagesets/'+this.state.id, {
                headers: {
                    'Authorization': 'Bearer ' + this.state.token
                },
            } ).then(response => {
                console.log(response);
                if (response.status === 200) {
                    this.setState({
                        name: response.data.damageSet.name
                    });
                }
            }).catch(error => {
                console.log('error', error);
            });
        }


        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {

    }

    handleSubmit(e) {
        e.preventDefault();


        let formData = {
            name: this.state.name,
        };

        Axios.put(process.env.REACT_APP_API_BASE + '/damagesets/'+this.state.id, formData, {
            headers: {
                'Authorization': 'Bearer ' + this.state.token
            }
        } )
            .then(response => {
                console.log(response);
                if (response.status === 200) {
                    SuccessModal.fire({
                        title: 'Successfully updated Damage Set',
                    }).then(() => {
                        this.props.history.push('/dashboard/damage-sets');
                    });
                }
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    'formErrors': error.response.data.message
                })
            })
    }


    handleChange(e) {

        //Sets state with the appropriate key, to the matching input's value
        this.setState({
            [e.target.name]: e.target.value
        });
    }


    render() {

        return (
            <div className="container" id="main">
                <div className="row mb-5">
                    <div className="col-12 col-md-8">
                        <Link to={"/dashboard/damage-sets/"} className={"btn-min"}>
                            <FontAwesomeIcon icon={faChevronLeft} />
                            <span className="ml-1">View all Damage Sets</span>
                        </Link>
                        <h1 className={"mt-1"}>Editing Damage Set: {this.state.name ? this.state.name : null}</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-lg-7">
                        <div className=" no-shadow no-border">
                            <section>
                                <div className="row">
                                    <div className="col-12">
                                        <h2>Basic Details</h2>
                                        <form action="" className={"mb-5"} onSubmit={this.handleSubmit}>
                                            <div className="row mb-3">
                                                <div className="col-12">
                                                    <label htmlFor="name">Title</label>
                                                    <input type="text" className={"form-control"} name={"name"} id={"name"} onChange={this.handleChange} value={this.state.name} />
                                                </div>
                                            </div>
                                            <div className="row mt-3">
                                                <div className="col-12">
                                                    <button type={"submit"} className={"btn btn-primary"}>Submit</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </section>

                            <section className={"mb-5"}>
                                <div className="row">
                                    <div className="col-12">
                                        <h2>Part options</h2>
                                        <Options parentId={this.state.id} type={"part"}/>
                                    </div>
                                </div>
                            </section>

                            <section className={"mb-5"}>
                                <div className="row">
                                    <div className="col-12">
                                        <h2>Type options</h2>
                                        <Options parentId={this.state.id} type={"type"}/>
                                    </div>
                                </div>
                            </section>

                            <section className={"mb-5"}>
                                <div className="row">
                                    <div className="col-12">
                                        <h2>Severity options</h2>
                                        <Options parentId={this.state.id} type={"severity"}/>
                                    </div>
                                </div>
                            </section>

                            <section className={"mb-5"}>
                                <div className="row">
                                    <div className="col-12">
                                        <h2>Cause options</h2>
                                        <Options parentId={this.state.id} type={"cause"}/>
                                    </div>
                                </div>
                            </section>

                            <section className={"mb-5"}>
                                <div className="row">
                                    <div className="col-12">
                                        <h2>Repair mode options</h2>
                                        <Options parentId={this.state.id} type={"repair_mode"}/>
                                    </div>
                                </div>
                            </section>

                            <section className={"mb-5"}>
                                <div className="row">
                                    <div className="col-12">
                                        <h2>Class options</h2>
                                        <Options parentId={this.state.id} type={"class"}/>
                                    </div>
                                </div>
                            </section>

                            <section className={"mb-5"}>
                                <div className="row">
                                    <div className="col-12">
                                        <h2>DLN options</h2>
                                        <Options parentId={this.state.id} type={"dln"}/>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

EditDamageSet.propTypes = {};

export default withRouter(EditDamageSet);
