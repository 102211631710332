import React from "react";
import {Link} from "react-router-dom";


import Axios from "axios";
import Store from "store";

import TransportCompany from "./TransportCompany/TransportCompany";

class TransportCompanies extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            token: Store.get('accessToken').token
        };
        //
        // this.handleSubmit = this.handleSubmit.bind(this);
        // this.handleChange = this.handleChange.bind(this);
        // this.getPlace = this.getPlace.bind(this);
        // this.readyMap = this.readyMap.bind(this);

        this.renderItems = this.renderItems.bind(this);
        this.fetchItems = this.fetchItems.bind(this);
    }


    componentDidMount() {
        if (this.state.token) {
            this.fetchItems();
        }
    }

    fetchItems() {
        Axios.get(process.env.REACT_APP_API_BASE + '/transportCompanies', {
            headers: {
                'Authorization': 'Bearer ' + this.state.token
            },
        } ).then(response => {
            console.log(response);
            if (response.status === 200) {
                this.setState({
                    items: response.data
                })
            }
        }).catch(error => {
            console.log('error', error);
            if (error.status === 401) {
                console.log('attempting reauth');
            }
        });
    }


    renderItems() {
        if (this.state.items) {
            console.log(this.state.items);
            return this.state.items.map((item, index) => {
                return (
                    <TransportCompany key={"k-" + index} entry={item} />
                );
            });
        }
    }

    render() {

        return (
            <div className="container" id="main">
                <div className="row mb-5">
                    <div className="col-12 d-flex justify-content-between align-items-center">
                        <h1 className={"mb-0"}>Transport Companies</h1>
                        <Link to={"/dashboard/transport-companies/add-new"} className={"btn btn-primary"}>Add new</Link>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="no-shadow no-border">
                            <div className="layout-grid-3 small-gap">
                                {this.renderItems()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

TransportCompanies.propTypes = {};

export default TransportCompanies;
