import React from "react";
import Select from "react-select";
import {deleteOrder, fetchItemsFromArr, getCargoItem, getInspector, isInspected} from "../../utils/utils";
import Swal from "sweetalert2";
import Store from "store";
import Axios from "axios";
import withReactContent from "sweetalert2-react-content";
const MarkInspected = withReactContent(Swal);

class InspectionController extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            // itemArr: []
        };

        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.inspectionModal = this.inspectionModal.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.markInspected = this.markInspected.bind(this);
        this.createInspection = this.createInspection.bind(this);
        this.bulkInspect = this.bulkInspect.bind(this);
    }

    bulkInspect(item, order) {
        this.markInspected(item, order);
    }

    handleSelectChange(obj, e) {

        this.setState({
            [e.name]: obj
        })
    }

    inspectionModal(inspectors) {
        return (
            <div className={"text-start"}>
                <div className="form-group mb-3 text-start">
                    <label htmlFor="inspectionWeather" className={"mb-1"}>Weather</label>
                    <select name="inspectionWeather" required id={"inspectionWeather"} className={"form-select"} onChange={this.handleChange}>
                        <option value="" disabled selected>Select weather</option>
                        <option value="cloudy">Cloudy</option>
                        <option value="sunny">Sunny</option>
                        <option value="rain">Rain</option>
                        <option value="fog">Fog</option>
                        <option value="snow">Snow</option>
                        <option value="hail">Hail</option>
                    </select>
                </div>
                <div className="form-group text-start">
                    <label htmlFor="inspectionTemperature" className={"mb-1"}>Temperature (optional)</label>
                    <input type="number" name={"inspectionTemperature"} id={"inspectionTemperature"} className={"form-control"} onChange={this.handleChange}/>
                </div>

                <div className={"d-flex justify-content-start flex-column"}>
                    <label htmlFor="inspectorsArr" className="mb-1 text-start">Inspector</label>
                    <Select
                        closeMenuOnSelect={true}
                        options={inspectors}
                        onChange={this.handleSelectChange}
                        name={"inspectionInspector"}
                    />
                </div>
            </div>
        );
    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    async markInspected(item, order) {
        let subjects = item ? [item] : this.props.cargoItems;
        // let subjectsArr = subjects;
        // console.log(subjectsArr);
        // for (let [i, s] of subjects.entries()) {
        //
        //     let inspected = await isInspected(s);
        //     console.log('cargo item', s, 'is', inspected);
        //     if (inspected) {
        //         subjectsArr.splice(i, 1);
        //     }
        // }
        // console.log(subjectsArr);

        // subjects.filter(i => { 
        //
        // })
        const notInspected = await fetchItemsFromArr(subjects, getCargoItem)
            .then(r => {
                return r?.filter(item => {
                    return !item.inspection;
                })
            });
            fetchItemsFromArr(this.props.inspectors, getInspector)
            .then(r => {
                if (r) {
                    let inspectorsArr = [];

                    for (let inspector of r) {
                        inspectorsArr.push({
                            'value': inspector.id,
                            'label': inspector.name
                        });
                    }
                    return inspectorsArr;
                }

                throw new Error('Couldnt fetch inspectors');

            })
            .catch(err => {
                console.log(err);
            })
            .then(inspectorsArr => {
                return MarkInspected.fire({
                    title: 'Inspection details',
                    html: this.inspectionModal(inspectorsArr),
                    showLoaderOnConfirm: true,
                    preConfirm: async (inputValue) => {
                        if (!document.getElementById('inspectionWeather').value) {
                            return Swal.showValidationMessage('Weather is required');
                        }
                        if (!this.state.inspectionInspector) {
                            return Swal.showValidationMessage('Inspector is required');
                        }
                        if (inputValue) {
                            let promises = [];
                            promises.push(() => this.createBulkInspection(item, order));
                            return Promise.all(promises.map(func => func()));
                        }

                        return false;
                    }
                })
            })

            .then((r) => {
                if (r.isConfirmed) {
                    MarkInspected.fire({
                        'icon': 'success',
                        'title': 'Successfully marked cargo item as inspected'
                    })
                }
            })
            .catch(err => {
                console.log(err);
                MarkInspected.fire({
                    'icon': 'error',
                    'title': 'Something went wrong'
                })
            })
            .then(() => {
                this.props.fetchCargoData();
            });
    }

    createBulkInspection(cargoItems, order) {
        if (!Store.get('accessToken').token) {
            throw new Error('Missing auth token');
        }

        return new Promise((resolve, reject) => {
            const data = {
                'item_id': cargoItems,
                'order_id': order,
                'inspected_by': this.state.inspectionInspector?.value,
                weather: this.state.inspectionWeather,
                temperature: this.state.inspectionTemperature
            };
            Axios.post(process.env.REACT_APP_API_BASE + '/inspections/bulk', data, {
                headers: {
                    'Authorization': 'Bearer ' + Store.get('accessToken').token
                }
            }).then(r => {
                if (r.status === 201 || r.status === 200) {
                    return resolve();
                } else {
                    return reject('Something went wrong with the api call');
                }

            })
        });
    }

    createInspection(cargoItemId, order) {
        if (!Store.get('accessToken').token) {
            throw new Error('Missing auth token');
        }
        return new Promise((resolve, reject) => {
            const data = {
                'item_id': cargoItemId,
                'order_id': order,
                'inspected_by': this.state.inspectionInspector?.value,
                weather: this.state.inspectionWeather,
                temperature: this.state.inspectionTemperature
            };
            Axios.post(process.env.REACT_APP_API_BASE + '/inspections', data, {
                headers: {
                    'Authorization': 'Bearer ' + Store.get('accessToken').token
                }
            }).then(r => {
                if (r.status === 201) {
                    return resolve();
                } else {
                    return reject('Something went wrong with the api call');
                }

            })
        });
    }

    render() {
        return null;
    }
}

InspectionController.propTypes = {};

export default InspectionController;
