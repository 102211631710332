import React from "react";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

import Axios from "axios";
import Store from "store";
import Attachment from "./Attachments/Attachment";
import Style from "../CargoItem.module.scss";

const GallerySwal = withReactContent(Swal);
const uploadFiles = withReactContent(Swal);

class Attachments extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            images: []
        };

        this.openUploadFileModal = this.openUploadFileModal.bind(this);
        this.uploadFiles = this.uploadFiles.bind(this);
    }


    uploadFiles(images) {

        return new Promise((resolve, reject) => {
            if (!images) throw new Error('Missing files');

            let formData = new FormData();

            for (let i = 0; i < images.length; i++) {
                formData.append('document[]', images[i]);
            }
            formData.append('item_id', this.props.itemid);

            Axios.post(process.env.REACT_APP_API_BASE + '/itemreport', formData, {
                headers: {
                    'Authorization': 'Bearer ' + Store.get('accessToken').token,
                }
            } )
                .then(response => {

                    if (response.status === 200) {
                        resolve();
                    } else {
                        reject('Smt went wrong');
                    }
                })
                .catch(error => {
                    console.log(error);
                })
        });
    }


    openUploadFileModal() {
        GallerySwal.fire({
            title: 'Select files',
            input: 'file',
            showLoaderOnConfirm: true,
            showCancelButton: true,
            inputAttributes: {
                // 'accept': 'image/*',
                'multiple': 'multiple',
            },
            preConfirm: (inputValue) => {
                if (inputValue) {
                    return this.uploadFiles(inputValue)
                        .then(() => {
                            return this.props.refreshCargoData();
                        })
                        .then(() => {
                            return GallerySwal.fire({
                                title: 'Successfully added files',
                                icon: "success"
                            });
                        })
                        .catch(err => {
                            console.log(err);
                            GallerySwal.fire({
                                title: 'Something went wrong',
                                icon: "error"
                            });
                        });
                }
            }
        });
    }

    render() {
        return (
            <div>
                <div className="layout-grid-1 attachments-list">
                    {this.props.attachments?.map(item => <Attachment data={item}/>)}
                </div>
                <button className={"btn-min text-secondary mt-2"} onClick={this.openUploadFileModal}>Add Document</button>
            </div>
        );
    }
}

Attachments.propTypes = {};

export default Attachments;
