import React from "react";
import {Link} from "react-router-dom";
import moment from "moment";

class Inspector extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
        this.displayDate = this.displayDate.bind(this);
    }

    displayDate(time) {
        if (!time) return false;

        return moment(time).format('MM DD YYYY');
    }

    render() {
        return (
            <Link className={'card no-shadow'} to={"/dashboard/inspectors/edit/"+this.props.entry.id}>
                <div className="card-header border-0">
                    <div className="meta-wrapper d-flex flex-column">
                        <span className="strong d-block mb-1">ID: {this.props.entry.id}</span>
                        <span className="strong d-block">Email: {this.props.entry.email}</span>
                    </div>
                </div>
                <div className="card-body bg-white mt-1 pt-0">
                    <h4 className={"mb-0"}>{this.props.entry.name}</h4>
                </div>
            </Link>
        );
    }
}

Inspector.propTypes = {};

export default Inspector;
