import React from "react";
import Store from "store";
import Order from "./Order/Order";

class Orders extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            token: Store.get('accessToken').token,
            items: []
        };

    }


    renderItems() {
        if (this.props.phase) {
            return this.props.items.filter(item => item.phase == this.props.phase)
                .map(item => {
                    return <Order {...this.props} key={'order-'+item.id} data={item} />
                })
        } else {
            return this.props.items.map(item => {
                return <Order {...this.props} key={'order-'+item.id} data={item} />
            })
        }

    }

    render() {

        return (
            <div className={"layout-grid-3"}>
                {this.renderItems()}
            </div>
        );
    }
}

Orders.propTypes = {};

export default Orders;
