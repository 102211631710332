import React from "react";
import {Link, withRouter} from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

import Axios from "axios";
import Store from "store";

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
const SuccessModal = withReactContent(Swal);

class EditDestination extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            token: Store.get('accessToken').token,
            destinationCountry: null,
            destinationId: this.props.match.params.entryId,
        };


        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.deleteDestination = this.deleteDestination.bind(this);
    }

    componentDidMount() {
        Axios.get(process.env.REACT_APP_API_BASE + '/destinations/' + this.state.destinationId, {
            headers: {
                'Authorization': 'Bearer ' + this.state.token
            }
        })
            .then(res => {
                this.setState({
                    destinationCountry: res.data.destination.destinationCountry
                })
            })
    }


    handleSubmit(e) {
        e.preventDefault();

        let formData = {
            destinationCountry: this.state.destinationCountry
        };

        Axios.put(process.env.REACT_APP_API_BASE + '/destinations/' + this.state.destinationId, formData, {
            headers: {
                'Authorization': 'Bearer ' + this.state.token
            }
        } )
            .then(response => {
                console.log(response);
                if (response.status === 200) {
                    SuccessModal.fire({
                        title: 'Successfully edited destination',
                    }).then(() => {
                        this.props.history.push('/dashboard/destinations');
                    });
                }
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    'formErrors': error.response.data.message
                })
            })
    }

    handleChange(e) {

        //Sets state with the appropriate key, to the matching input's value
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    deleteDestination(e) {
        e.preventDefault();
        Axios.delete(process.env.REACT_APP_API_BASE + '/destinations/' + this.state.destinationId, {
            headers: {
                'Authorization': 'Bearer ' + this.state.token
            }
        })
            .then(response => {
                if(response.status === 200) {
                    SuccessModal.fire({
                        title: 'Successfully deleted destination',
                    }).then(() => {
                        this.props.history.push('/dashboard/destinations');
                    });
                }
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    'formErrors': error.response.data.message
                })
            })
    }

    render() {

        return (
            <div className="container" id="main">
                <div className="row mb-5">
                    <div className="col-12 col-md-8">
                        <Link to={"/dashboard/destinations"} className={"btn-min"}>
                            <FontAwesomeIcon icon={faChevronLeft} />
                            <span className="ml-1">View all destinations</span>
                        </Link>
                        <h1 className={"mt-1"}>Edit destination</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-lg-7">
                        <div className=" no-shadow no-border">
                            <div className="row">
                                <div className="col-12">
                                    <form action="" className={"mb-5"} onSubmit={this.handleSubmit}>

                                        <section className={"mb-5"}>
                                            <div className="row mb-3">
                                                <div className="col-12">
                                                    <label htmlFor="name">Title</label>
                                                    <input type="text" className={"form-control"} name={"destinationCountry"} id={"destinationCountry"} onChange={this.handleChange} value={this.state.destinationCountry} />
                                                </div>
                                            </div>
                                        </section>

                                        <div className="row mt-3">
                                            <div className="col-12">
                                                <button type={"submit"} className={"btn btn-primary"}>Submit</button>
                                                <button onClick={this.deleteDestination} className={"btn btn-danger ms-2"}>Delete</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

EditDestination.propTypes = {};

export default withRouter(EditDestination);
