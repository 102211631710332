import React from "react";
import {Link} from "react-router-dom";
import Style from "./Transport.module.scss";

class Transport extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
        console.log(this.props);
    }

    render() {
        return (
            <Link className={Style.item + ' card no-shadow'} to={"/dashboard/transports/edit/"+this.props.entry.id}>
                <div className="card-body bg-white">
                    <div className="meta-wrapper d-flex flex-column">
                        <span className="d-block mb-1">ID: {this.props.entry.id}</span>
                        <span className="d-block mb-1">Transport company: {this.props.entry.transportCompany?.name || '/'}</span>
                        <span className="strong d-block mb-1">{this.props.entry.identificationName || '/'}</span>
                        <span className="d-block mb-1">Transport mode: {this.props.entry.transportMode || '/'}</span>
                        <span className="d-block">Transport type: {this.props.entry.transportType ||  '/'}</span>
                    </div>
                </div>
            </Link>
        );
    }
}

Transport.propTypes = {};

export default Transport;
