import React from "react";
import Select from "react-select";
import {deleteOrder, fetchItemsFromArr, getCargoItem, getInspector, isInspected} from "../../utils/utils";
import Swal from "sweetalert2";
import Store from "store";
import Axios from "axios";
import withReactContent from "sweetalert2-react-content";
import BaseSelect from 'react-select';

// const MarkInspected = withReactContent(Swal);
const AddTransport = withReactContent(Swal);
const DeleteTransport = withReactContent(Swal);

class TransportController extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            // itemArr: []
            transportPlate1: '',
            transportPlate2: '',
            availableTransportModes: [],
            selectedTransport: '',
            transportMode: '',
        };

        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.inspectionModal = this.inspectionModal.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.addTransport = this.addTransport.bind(this);
        this.addTransportModalContent = this.addTransportModalContent.bind(this);
        this.deleteTransports = this.deleteTransports.bind(this);
        this.deleteTransportsModalContent = this.deleteTransportModalContent.bind(this);
        this.createTransport = this.createTransport.bind(this);
        this.populateSelectedFields = this.populateSelectedFields.bind(this);
    }

    handleSelectChange(obj, e) {

        this.setState({
            [e.name]: obj
        })
    }

    componentDidMount() {
        this.populateSelectedFields();
    }

    populateSelectedFields() {
        
    }

    addTransport(orderId, transportType) {
        
        return AddTransport.fire({
            title: "Add transport",
            html: this.addTransportModalContent(transportType),
            showLoaderOnConfirm: true,
            preConfirm: async (inputValue) => {
                if(inputValue) {
                    let promises = [];
                    promises.push(() => this.createTransport(orderId));
                    return Promise.all(promises.map(func => func()));
                }
            }
        })
        .then(() => {
            this.props.fetchCargoData();
        });
    }

    deleteTransports(orderId, availableTransportModes, userToken) {
        console.log("----");
        console.log(availableTransportModes);
        console.log("----");

        this.state.availableTransportModes = availableTransportModes.map(item => {
            return {
                'value': item.id,
                'label': item.field_1 + (item.field_2 != null ? '-'+ item.field_2 :   "")
            }
        });

        return DeleteTransport.fire({
            title: "Delete selected transports",
            html: this.deleteTransportModalContent(this.state.availableTransportModes),
            showLoaderOnConfirm: true,
            confirmButtonText: "Yes, delete",
            preConfirm: async (inputValue) => {
                DeleteTransport.showLoading();
                if(this.state.selectedTransports) {
                    if(this.state.selectedTransports.length == 0) {
                        
                    } else {
                        
                        this.state.selectedTransports.forEach(selectedTransport => {
                            Axios.delete(process.env.REACT_APP_API_BASE + '/transportmode/' + selectedTransport.value, {
                                params: {
                                },
                                headers: {
                                    'Authorization': 'Bearer ' + userToken
                                }
                            })
                                .then(res => {
                                    
                                    if (res.status === 200) {
                                        DeleteTransport.hideLoading();
                                        /*this.setState({
                                            
                                        })*/
                                    }
                                })
                        })
                        
                        
                        
                    }
                }
                
            }
        })
        .then(() => {
            // this.props.fetchCargoData();
        });
    }

    addTransportModalContent(transportType) {
        return (
            <div className={"text-start"}>
                    <div className="row">
                        <div className="col-12 col-md-6">
                                <input type="text" name="transportPlate1" onChange={this.handleChange} id={"transportPlate1"} placeholder="First plate" className="vinSearch form-control w-100" itemID="plate1" required></input>
                            </div>
                            <div className="col-12 col-md-6">
                                <input type="text" name="transportPlate2" onChange={this.handleChange} placeholder="Second plate" id={"transportPlate2"} className="vinSearch form-control w-100" itemID="plate2" disabled={transportType == 'train'}></input>
                            </div>
                    </div>
                    
                </div>   
        );
    }

    deleteTransportModalContent(availableTransportModes) {
        return (
            <div className={"text-start"}>
                    <div className="row">
                            <BaseSelect
                                closeMenuOnSelect={true}
                                isMulti
                                options={this.state.availableTransportModes}
                                name="selectedTransports"
                                value={this.state.selectedTransports}
                                className="basic-multi-select filter-multi-select"
                                classNamePrefix="select"
                                onChange={this.handleSelectChange}
                            />
                    </div>
                    
                </div>   
        );
    }

    createTransport(orderId) {
        if (!Store.get('accessToken').token) {
            throw new Error('Missing auth token');
        }

        if(this.state.transportPlate1 == '') {
            return Swal.showValidationMessage('First plate is required!');

        }

        return new Promise((resolve, reject) => {
            const data = {
                field_1: this.state.transportPlate1,
                field_2: this.state.transportPlate2,
                order_id: orderId
            };
            Axios.post(process.env.REACT_APP_API_BASE + '/transportmode', data, {
                headers: {
                    'Authorization': 'Bearer ' + Store.get('accessToken').token
                }
            }).then(r => {
                if (r.status === 201 || r.status === 200) {
                    return resolve();
                } else {
                    return reject('Something went wrong with the api call');
                }

            })
        });
    }

    inspectionModal(inspectors) {
        return (
            <div className={"text-start"}>
                <div className="form-group mb-3 text-start">
                    <label htmlFor="inspectionWeather" className={"mb-1"}>Weather</label>
                    <select name="inspectionWeather" required id={"inspectionWeather"} className={"form-select"} onChange={this.handleChange}>
                        <option value="" disabled selected>Select weather</option>
                        <option value="cloudy">Cloudy</option>
                        <option value="sunny">Sunny</option>
                        <option value="rain">Rain</option>
                        <option value="fog">Fog</option>
                        <option value="snow">Snow</option>
                        <option value="hail">Hail</option>
                    </select>
                </div>
                <div className="form-group text-start">
                    <label htmlFor="inspectionTemperature" className={"mb-1"}>Temperature (optional)</label>
                    <input type="number" name={"inspectionTemperature"} id={"inspectionTemperature"} className={"form-control"} onChange={this.handleChange}/>
                </div>

                <div className={"d-flex justify-content-start flex-column"}>
                    <label htmlFor="inspectorsArr" className="mb-1 text-start">Inspector</label>
                    <Select
                        closeMenuOnSelect={true}
                        options={inspectors}
                        onChange={this.handleSelectChange}
                        name={"inspectionInspector"}
                    />
                </div>
            </div>
        );
    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handleSelectChange(obj, e) {
        console.log(e);

        this.setState({
            [e.name]: obj
        })
    }


    render() {
        return null;
    }
}

TransportController.propTypes = {};

export default TransportController;
