import React from "react";
import style from "./Order.module.scss";
import {Link} from "react-router-dom";
import ConditionalContent from "../../../../ConditionalContent";
import moment from "moment";

class Order extends React.Component {
    constructor(props) {
        super(props);

        this.selectOrder = this.selectOrder.bind(this);
    }

    selectOrder(e) {
        // e.preventDefault();

        if (e.target.checked) {
            console.log('select order triggered');
            this.props.selectOrder(e.target.value);
        } else {
            this.props.unselectOrder(e.target.value);
        }

    }

    render() {
        return (
            <Link to={"/dashboard/orders/order/" + this.props.data.id} className={"card text-decoration-none no-shadow"}>
                <div className="card-body ">
                    <div className={"d-flex"}>
                        <ConditionalContent check={this.props.bulk}>
                            <div className="form-check form-check-inline me-0">
                                <input
                                    className="form-check-input me-1"
                                    type="checkbox"
                                    value={this.props.data.id}
                                    id={'check-'+this.props.data.id}
                                    name="orderCheck"
                                    checked={this.props.selectedOrders?.includes(this.props.data.id)}
                                    onChange={this.selectOrder}
                                    onClick={(e) => {e?.stopPropagation()}}
                                />
                            </div>
                        </ConditionalContent>
                        <div className={style.metaOuter}>
                            <strong className="text-primary">ID: </strong>
                            <span>{this.props.data.idNumber}</span>
                        </div>
                        <div className={style.metaOuter + ' ms-2'}>
                            <strong className="text-primary">Inspection Type: </strong>
                            <span>{this.props.data.inspectionType}</span>
                        </div>

                        
                    </div>
                    <div>
                        <div className={""}>
                            <span className={style.name}>{this.props.data.client ? this.props.data.client['company_name'] : null}</span>
                        </div>
                    </div>
                    <div className={"d-flex align-items-center"}>
                        <div className={style.metaOuter}>
                            <strong className="text-primary">Gate: </strong>
                            <span>{this.props.data.gate}</span>
                        </div>
                        <div className={style.metaOuter + " ms-2"}>
                            <strong className="text-primary">Transport Type: </strong>
                            <span>{this.props.data.transportType}</span>
                        </div>
                    </div>
                    <div className={"d-flex"}>
                        <div className={style.metaOuter + " me-2"}>
                            <strong className="text-primary">Cargo Items: </strong>
                            <span>{this.props.data.number_of_items || 0}</span>
                        </div>
                        <div className={style.metaOuter+ " me-2"}>
                            <strong className="text-primary">Damaged: </strong>
                            <span>{this.props.data.damaged_items ? `${this.props.data.damaged_items} (${Math.round((this.props.data.damaged_items/this.props.data.number_of_items)*100)}%)`  : 0}</span>
                        </div>
                        <div className={style.metaOuter}>
                            <strong className="text-primary">Damages: </strong>
                            <span>{this.props.data.all_damages || 0}</span>
                        </div>
                    </div>
                    <div className={"d-flex"}>
                        <div className={style.metaOuter + ' ms-e'}>
                            <strong className="text-primary">Created at: </strong>
                            <span>{this.props.data ? <span>{moment(this.props.data.createdOn).format('DD.MM.YYYY HH:mm')}</span> : <span>/</span>}</span>
                        </div>
                    </div>
                </div>
            </Link>
        );
    }
}

Order.propTypes = {};

export default Order;
