import React from "react";
import SimpleReactLightbox, {SRLWrapper} from "simple-react-lightbox";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {deleteImage} from "../utils/utils";

import Style from "./GalleryGrid.module.scss";

class GalleryGrid extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
        this.deleteImage = this.deleteImage.bind(this);
    }

    deleteImage(id) {

        deleteImage(id, 'itemimage')
            .then(() => {
                this.props.refreshImages();
            });
    }

    render() {
        const srlOptions = {
            buttons: {
                showAutoplayButton: false,
                showCloseButton: false,
                showDownloadButton: false,
                showFullscreenButton: false,
                showNextButton: false,
                showPrevButton: false,
                showThumbnailsButton: false,
            }
        }

        if (this.props.images && this.props.images.length > 0) {
            return (
                <div>
                    <SimpleReactLightbox>
                        <SRLWrapper options={srlOptions}>
                            <div className="layout-grid-3">
                                {
                                    this.props.images?.map(image => {
                                        return (
                                            <div className={"position-relative"}>
                                                <button className={[Style.control, Style.controlsWrapper].join(' ')} onClick={(e) => {e?.preventDefault(); this.deleteImage(image.id)}}>
                                                    <FontAwesomeIcon icon={faTrash} />
                                                </button>
                                                <img className={"mw-100"} src={image.path}/>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </SRLWrapper>
                    </SimpleReactLightbox>
                </div>

            );
        } else {
            return (
                <h2 className={"text-center mt-2"}>No images found</h2>
            );
        }
    }
}

GalleryGrid.propTypes = {};

export default GalleryGrid;
