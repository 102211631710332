import React from "react";
import {Link, withRouter} from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';


import Axios from "axios";
import Store from "store";

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
const SuccessModal = withReactContent(Swal);


class AddNewTransport extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            token: Store.get('accessToken').token,
            identificationName: null,
            transportCompany: null,
            transportMode: null,
            transportType: null
        };


        if (this.state.token) {
            Axios.get(process.env.REACT_APP_API_BASE + '/transportCompanies', {
                headers: {
                    'Authorization': 'Bearer ' + this.state.token
                },
            } ).then(response => {
                console.log(response);
                if (response.status === 200) {
                    this.setState({
                        transportCompanies: response.data
                    });
                }
            }).catch(error => {
                console.log('error', error);
            });
        }


        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.renderTransportCompanySelect = this.renderTransportCompanySelect.bind(this);
    }

    handleSubmit(e) {
        e.preventDefault();

        let formData = {
            identificationName: this.state.identificationName,
            transportCompany: this.state.transportCompany ? parseInt(this.state.transportCompany) : null,
            transportMode: this.state.transportMode,
            transportType: this.state.transportType
        };

        console.log(formData);

        Axios.post(process.env.REACT_APP_API_BASE + '/transports', formData, {
            headers: {
                'Authorization': 'Bearer ' + this.state.token
            }
        } )
            .then(response => {
                console.log(response);
                if (response.status === 201) {
                    SuccessModal.fire({
                        title: 'Successfully added transport',
                    }).then(() => {
                        this.props.history.push('/dashboard/transports');
                    });
                }
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    'formErrors': error.response.data.message
                })
            })
    }


    handleChange(e) {

        //Sets state with the appropriate key, to the matching input's value
        this.setState({
            [e.target.name]: e.target.value
        });
    }



    renderTransportCompanySelect() {

        if (this.state.transportCompanies && this.state.transportCompanies.length > 0) {
            return (
                <select className={"form-select"} name={"transportCompany"} id={"transportCompany"} value={this.state.transportCompany} required onChange={this.handleChange}>
                    <option value="" selected disabled>Select transport company</option>
                    {this.state.transportCompanies.map((item, index) => {
                        return (
                            <option value={item.id}>{item.name}</option>
                        );
                    })}
                </select>
            );
        } else {
            return (
                <span>No Transport companies found.</span>
            );
        }
    }

    render() {

        return (
            <div className="container" id="main">
                <div className="row mb-5">
                    <div className="col-12 col-md-8">
                        <Link to={"/dashboard/transports/"} className={"btn-min"}>
                            <FontAwesomeIcon icon={faChevronLeft} />
                            <span className="ml-1">View all Transports</span>
                        </Link>
                        <h1 className={"mt-1"}>Add New Transport</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-lg-7">
                        <div className=" no-shadow no-border">
                            <div className="row">
                                <div className="col-12">
                                    <form action="" className={"mb-5"} onSubmit={this.handleSubmit}>

                                        <section className={"mb-5"}>
                                            <div className="row mb-3">
                                                <div className="col-12 col-md-6">
                                                    <label htmlFor="identificationName">Identification Name</label>
                                                    <input type="text" className={"form-control"} name={"identificationName"} id={"identificationName"} onChange={this.handleChange} value={this.state.identificationName} />
                                                </div>
                                                <div className="col-12 col-md-6">
                                                    <label htmlFor="transportCompany">Transport Company</label>
                                                    {this.renderTransportCompanySelect()}
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col-12 col-md-6">
                                                    <label htmlFor="Transport mode">Transport mode</label>
                                                    <select className={"form-select"} name={"transportMode"} id={"transportMode"} value={this.state.transportMode} required onChange={this.handleChange}>
                                                        <option value="" selected disabled>Select transport mode</option>
                                                        <option value="Aircraft">Aircraft</option>
                                                        <option value="Barge">Barge</option>
                                                        <option value="Container">Container</option>
                                                        <option value="Shunting">Shunting</option>
                                                        <option value="Train">Train</option>
                                                        <option value="Truck">Truck</option>
                                                        <option value="Vessel">Vessel</option>
                                                    </select>
                                                </div>
                                                <div className="col-12 col-md-6">
                                                    <label htmlFor="transportType">Transport type</label>
                                                    <select className={"form-select"} name={"transportType"} id={"transportType"} value={this.state.transportType} required onChange={this.handleChange}>
                                                        <option value="" selected disabled>Select transport type</option>
                                                        <option value="General cargo">General cargo</option>
                                                        <option value="Ocean Ship">Ocean Ship</option>
                                                        <option value="Ocean Vessel">Ocean Vessel</option>
                                                        <option value="RO-RO">RO-RO</option>
                                                        <option value="Truck">Truck</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </section>


                                        <div className="row">
                                            <div className="col-12">
                                                <div className="errors">
                                                    <h6 className={"mb-0"}>{this.state.formErrors}</h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-12">
                                                <button type={"submit"} className={"btn btn-primary"}>Submit</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

AddNewTransport.propTypes = {};

export default withRouter(AddNewTransport);
