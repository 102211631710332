import React from "react";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft} from "@fortawesome/free-solid-svg-icons";
import Axios from "axios";
import Store from "store";

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
const SuccessModal = withReactContent(Swal);

class AddNewMake extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            name: '',
            token: Store.get('accessToken').token
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleSubmit(e) {
        e.preventDefault();

        let formData = {
            name: this.state.name
        };

        Axios.post(process.env.REACT_APP_API_BASE + '/makes', formData, {
            headers: {
                'Authorization': 'Bearer ' + this.state.token
            }
        } )
            .then(response => {
                if (response.status === 201) {
                    SuccessModal.fire({
                        title: 'Successfully added make',
                    }).then(() => {
                        this.props.history.push('/dashboard/vin-decoder');
                    });
                }
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    'formErrors': error.response.data.message
                })
            })

    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    render() {
        return (
            <div className="container pb-10" id="main">
                <div className="row mb-5">
                    <div className="col-12 col-md-8">
                        <Link to={"/dashboard/vin-decoder/"} className={"btn-min"}>
                            <FontAwesomeIcon icon={faChevronLeft} />
                            <span className="ms-1">View all makes</span>
                        </Link>
                        <h1 className={"mt-1 mb-0"}>Add New Make</h1>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 col-lg-7">
                        <div className=" no-shadow no-border">
                            <div className="row">
                                <div className="col-12">
                                    <form action="" className={"mb-5"} onSubmit={this.handleSubmit}>

                                        <section className={"mb-5"}>
                                            <div className="row mb-3">
                                                <div className="col-12">
                                                    <label htmlFor="name">Name</label>
                                                    <input type="text" className={"form-control"} name={"name"} id={"name"} onChange={this.handleChange} value={this.state.name} />
                                                </div>
                                            </div>
                                        </section>

                                        <div className="row mt-3">
                                            <div className="col-12">
                                                <button type={"submit"} className={"btn btn-primary"}>Submit</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

AddNewMake.propTypes = {};

export default AddNewMake;
