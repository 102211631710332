import React from "react";
import {Link, withRouter} from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';


import Axios from "axios";
import Store from "store";

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import {Alert} from "react-bootstrap";
const SuccessModal = withReactContent(Swal);



class AddNewTransportCompany extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            id: this.props.match.params.entryId,
            token: Store.get('accessToken').token,
            validationErrorToggle: false,
            generalErrorToggle: false,
            validationErrors: [],
            name: null,
        };


        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.showValidationErrors = this.showValidationErrors.bind(this);
    }

    handleSubmit(e) {
        e.preventDefault();

        // console.log(this.state);

        let formData = {
            name: this.state.name
        };

        Axios.post(process.env.REACT_APP_API_BASE + '/transportCompanies', formData, {
            headers: {
                'Authorization': 'Bearer ' + this.state.token
            }
        } )
            .then(response => {
                console.log(response);
                if (response.status === 201) {
                    SuccessModal.fire({
                        title: 'Successfully added transport company',
                    }).then(() => {
                        this.props.history.push('/dashboard/transport-companies');
                    });
                }
            })
            .catch(error => {
                if(error.response.status === 422) {
                    this.setState({
                        validationErrorToggle: true
                    })
                    this.setState({
                        validationErrors: error.response.data.error
                    })
                } else if (error.response.status === 500) {
                    this.setState({
                        generalErrorToggle: true
                    })
                }

            })
    }


    handleChange(e) {

        //Sets state with the appropriate key, to the matching input's value
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    showValidationErrors() {
        for(const key in this.state.validationErrors) {
            return (
                <Alert key={'danger'} variant={'danger'}>
                    {this.state.validationErrors[key][0]}
                </Alert>
            )
        }
    }




    render() {

        return (
            <div className="container" id="main">
                <div className="row mb-5">
                    <div className="col-12 col-md-8">
                        <Link to={"/dashboard/transport-companies/"} className={"btn-min"}>
                            <FontAwesomeIcon icon={faChevronLeft} />
                            <span className="ml-1">View all transport companies</span>
                        </Link>
                        <h1 className={"mt-1"}>Add new transport company</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-lg-7">
                        <div className=" no-shadow no-border">
                            <div className="row">
                                <div className="col-12">
                                    <form action="" className={""} onSubmit={this.handleSubmit}>

                                        <section className={"mb-5"}>
                                            <div className="row mb-3">
                                                <div className="col-12">
                                                    <label htmlFor="companyName">Transport company name</label>
                                                    <input type="text" className={"form-control"} name={"name"} id={"name"} onChange={this.handleChange} value={this.state.name} />
                                                </div>
                                            </div>
                                        </section>


                                        <div className="row mt-3">
                                            <div className="col-12">
                                                <button type={"submit"} className={"btn btn-primary"}>Submit</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="errors mt-3">
                    {this.state.validationErrorToggle ? this.showValidationErrors() : ''}
                </div>
            </div>
        );
    }
}

AddNewTransportCompany.propTypes = {};

export default withRouter(AddNewTransportCompany);
