import React from "react";


class SearchSuggestion extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        return (
            <div>
                <span className="d-block strong">{this.props.suggestion.vin}</span>
            </div>
        );
    }
}

SearchSuggestion.propTypes = {};

export default SearchSuggestion;
