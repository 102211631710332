import React from "react";
import {Link} from "react-router-dom";
import Style from "./Port.module.scss";

class Port extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        return (
            <Link className={Style.item + ' card no-shadow'} to={"/dashboard/ports/edit/"+this.props.entry.id}>
                <div className="card-header px-2 border-0">
                    <div className="meta-wrapper">
                        <span className="d-block mb-1">ID: {this.props.entry.id}</span>
                        <span className="d-block mb-1">Code: {this.props.entry.code || '/'}</span>
                        <span className="strong d-block mb-1">{this.props.entry.name || '/'}</span>
                        <span className="d-block mb-1">Type: {this.props.entry.type || '/'}</span>
                        <span className="d-block">Country: {this.props.entry.country || '/'}</span>
                    </div>
                </div>
            </Link>
        );
    }
}

Port.propTypes = {};

export default Port;
