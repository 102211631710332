import React from "react";

import Store from "store";

class AccountSettings extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            password: 'placeholder',
            password_confirmation: 'placeholder',
            email: '',
            address: '',
            name: '',
            surname: '',
            token: Store.get('accessToken'),
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);

    }



    handleSubmit() {

    }

    handleChange(e) {
        //Sets state with the appropriate key, to the matching input's value
        this.setState({
            [e.target.name]: e.target.value
        });
    }


    render() {

        return (
            <div className="container" id="main">
                <div className="row mb-5">
                    <div className="col-12">
                        <h1>Account Settings</h1>
                        <p>Here you can manage your basic account settings.</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-lg-7">
                        <div className="no-shadow no-border">
                            <div className="">
                                <form action="" onSubmit={this.handleSubmit}>
                                    <div className="form-row mb-3">
                                        <div className="col-12 col-lg-6">
                                            <div className="form-group">
                                                <label htmlFor="name">First Name</label>
                                                <input type="text" className={"form-control"} name={"name"} id={"name"} value={this.state.name} onChange={this.handleChange}/>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-6">
                                            <div className="form-group">
                                                <label htmlFor="surname">Last Name</label>
                                                <input type="text" className={"form-control"} name={"surname"} id={"surname"} value={this.state.surname} onChange={this.handleChange}/>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="email">Email</label>
                                        <input type="email" className="form-control" name={"email"} id={"email"} value={this.state.email} disabled/>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="password">Password</label>
                                        <input type="password" className="form-control" name={"password"} id={"password"} value={this.state.password} onChange={this.handleChange} required/>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="password_confirmation">Repeat Password</label>
                                        <input type="password" className="form-control" name={"password_confirmation"} id={"password_confirmation"} value={this.state.password_confirmation} onChange={this.handleChange} required />
                                    </div>


                                    <div className={"mt-4"}>
                                        <button type={"submit"} className={"btn btn-primary"}>Update</button>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

AccountSettings.propTypes = {};

export default AccountSettings;
