import React from "react";
import {getOrder} from "../../../../utils/utils";
import Order from "../../Orders/Order/Order";

class Orders extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            orders:this.props.orders,
            ordersArr: []
        };

        this.fetchOrders = this.fetchOrders.bind(this);
    }

    componentDidMount() {

        this.fetchOrders().then(r => {
            let sortedOrders = [];
            if (r && r.length>0) {
                sortedOrders = r.slice().sort((a, b) => b.id - a.id);
            }

            this.setState({
                ordersArr: sortedOrders
            })

        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        // console.log(prevProps);
        // console.log(this.props);

        // if (this.state.orders !== prevState.orders) {
        //
        // }
    }

    fetchOrders() {

        if (this.props.orders) {
            let promises = [];

            for (let item of this.props.orders) {
                promises.push(() => getOrder(item));
            }

            return Promise.all(promises.map(func => func()));
        }
    }

    render() {
        return (
            <div className={"layout-grid-3"}>
                {this.state.ordersArr?.map(item => {
                    return <Order data={item} key={'order-'+item.idNumber}/>
                })}
            </div>
        );
    }
}

Orders.propTypes = {};

export default Orders;
