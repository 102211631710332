import React from "react";

class HelperControls extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectAll: false,
            bulkAction: '',
            sorting: '',
            dsgId: null
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSortChange = this.handleSortChange.bind(this);
        this.cargoSelectChange = this.cargoSelectChange.bind(this);
        this.handleBulkSubmit = this.handleBulkSubmit.bind(this);
        this.handleBulkChange = this.handleBulkChange.bind(this);
        this.handleItemsChange = this.handleItemsChange.bind(this);
        this.renderDamageSets = this.renderDamageSets.bind(this);
        this.handleDsoChange = this.handleDsoChange.bind(this);
        
        
    }

    handleSubmit(e) {
        e.preventDefault();
    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleSortChange(e) {
        this.props.orderItems(e.target.value);
    }

    handleItemsChange(e) {
        console.log('gehge')
        this.props.noItems(e.target.value);
    }

    cargoSelectChange(e) {
        console.log(e.target.checked);

        if (e.target.checked) {
            this.props.selectAllCargo();
        } else {
            this.props.unselectAllCargo();
        }
    }

    handleBulkSubmit(e) {
        e?.preventDefault();
        if (this.state.bulkAction) {
            this.props.bulkActionControl(this.state.bulkAction, this.state.dsgId);
        }
    }

    handleBulkChange(e) {
        this.setState({
            bulkAction: e.target.value
        })
    }

    renderDamageSets() {
        let damageSets = this.props.damageSetData.filter((dsg) => dsg.type === 'class');
        
        return damageSets.map((dsg) => {
            return (<option value={dsg.id}>{dsg.name}</option>)
        })
        
    }

    handleDsoChange(e) {
        this.setState({
            dsgId: e.target.value,
        })
    }

    render() {
        return (
            <div>
                <div className="row align-items-center">
                    <div className="col-12 d-flex align-items-center">
                        <div className={"d-flex align-items-center"}>
                            <div className="form-check form-check-inline">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id={'cargo-select'}
                                    name="cargo-select"
                                    checked={this.props.selectAllChecked}
                                    onChange={this.cargoSelectChange}
                                />
                                <label className="form-check-label" htmlFor={"cargo-select"}>
                                    Select all
                                </label>
                            </div>
                        </div>
                        <form onSubmit={this.handleBulkSubmit}>
                            <div className={"d-flex align-items-center mx-4"}>
                                <select className={"form-select me-2"} name="sortBy" onChange={this.handleBulkChange}>
                                    <option value="">Bulk Actions</option>
                                    <option value="move">Move Items</option>
                                    <option value="delete">Delete Items</option>
                                    <option value="unallocate">Set Unallocated</option>
                                    <option value="inspect">Set Inspected</option>
                                    <option value="changeTransport">Change transport</option>
                                    <option value="toggleItems">Toggle items</option>
                                    <option value="deleteDamages">Delete damages</option>
                                </select>
                                <button type={"submit"} className={"btn btn-primary"}>Apply</button>
                            </div>
                        </form>
                        <div className={"d-flex align-items-center mx-4"}>
                            <strong className={"me-3 nowrap"}>Sort data by</strong>
                            <select className={"form-select me-2"} name="sortBy" onChange={this.handleSortChange}>
                                <option value="">Default Sorting</option>
                                <option value="vin_asc">VIN asc</option>
                                <option value="vin_desc">VIN desc</option>
                                <option value="date_asc">Date asc</option>
                                <option value="date_desc">Date desc</option>
                            </select>
                        </div>
                        <div className={"d-flex align-items-center mx-4"}>
                            <strong className={"me-3 nowrap"}>Bulk delete damages</strong>
                            <select className={"form-select me-2"} name="sortBy" onChange={this.handleDsoChange}>
                                <option value="">Default Sorting</option>
                                {this.renderDamageSets()}
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

HelperControls.propTypes = {};

export default HelperControls;
