import React from "react";
import ModelCode from "./ModelCode/ModelCode";

class ModelCodes extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};

    }

    render() {
        if (this.props.items && this.props.items.length > 0) {
            return this.props.items.map((item, index) => {
                let key = null;
                let tempId = null;

                if (item.id) {
                    key = 'model-'+this.props.modelId+'-'+item.id;
                } else {
                    key = 'temp-model-'+this.props.modelId+'-'+item;
                    tempId = item;
                }


                return (
                    <ModelCode key={key} tempId={tempId} codeId={item.id} data={item} {...this.props} />
                );
            });
        } else {
            return null;
        }
    }
}

ModelCodes.propTypes = {};

export default ModelCodes;
