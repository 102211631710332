import React from "react";
import SimpleReactLightbox, {SRLWrapper} from "simple-react-lightbox";
import Style
    from "../Pages/Dashboard/Orders/SingleOrder/CargoItem/DamagesWrapper/Damage/DamageImages/Image/Image.module.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {deleteImage} from "../utils/utils";
import ConditionalContent from "../ConditionalContent";

import GalleryStyle from './Gallery.module.scss';

const srlOptions = {
    buttons: {
        showAutoplayButton: false,
        showCloseButton: false,
        showDownloadButton: false,
        showFullscreenButton: false,
        showNextButton: false,
        showPrevButton: false,
        showThumbnailsButton: false,
    }
};
class Gallery extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
        this.counter = this.counter.bind(this);
        this.deleteImage = this.deleteImage.bind(this);
    }

    counter() {
        if (this.props.images && this.props.images.length > 1) {
            return (
                <div className="gallery-counter">
                    +{this.props.images.length -1} Images
                </div>
            )
        }
        else {
            return null;
        }
    }

    deleteImage(id) {
        let type = '';
        switch (this.props.type) {
            case 'order':
                type = 'orderimages';
                break;
            case 'cargoItem':
                type = 'itemimage';
                break;
        }

        deleteImage(id, type).then(r => {
            this.props.refreshImages();
        });
    }

    render() {
        if (this.props.images && this.props.images.length > 0) {
            return (
                <SimpleReactLightbox>
                    <SRLWrapper options={srlOptions}>
                        <div className="gallery-wrapper">
                            {
                                this.props.images?.map(image => {
                                    return (
                                        <div className={"position-relative img-item"}>
                                            {this.counter()}
                                            <ConditionalContent check={this.props.allowDelete}>
                                                <button className={[Style.control, GalleryStyle.controlsWrapper].join(' ')} onClick={(e) => {e?.preventDefault(); this.deleteImage(image.id)}}>
                                                    <FontAwesomeIcon icon={faTrash} />
                                                </button>
                                            </ConditionalContent>
                                            <img className={"mw-100"} src={image.path}/>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </SRLWrapper>

                </SimpleReactLightbox>
            );
        } else {
            return <span>{this.props.type} has no images</span>
        }
    }
}

Gallery.propTypes = {};

export default Gallery;
