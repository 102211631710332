import React from "react";
import Axios from "axios";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faTrash} from "@fortawesome/free-solid-svg-icons";
import {deleteDamageSetOption} from "../../../../../../../utils/utils";
import Store from "store";

const SuccessModal = withReactContent(Swal);
const FailModal = withReactContent(Swal);

class Option extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            damageSetId: this.props.item.damageSetId ? this.props.item.damageSetId : this.props.parentId,
            name: this.props.item.name ? this.props.item.name : '',
            code: this.props.item.code ? this.props.item.code : '',
            type: this.props.item.type ? this.props.item.type : this.props.type,
            id: this.props.item.id ? this.props.item.id : null,
            position: this.props.item.position ? this.props.item.position : null,
            token: Store.get('accessToken').token
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.removeItem = this.removeItem.bind(this);
        this.saveData = this.saveData.bind(this);
    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    removeItem(e) {
        e.preventDefault();

        if (this.state.id) {
            //Remove real item, then refresh
            deleteDamageSetOption(this.state.id).then(r => {
                if(r.response) {
                    if(r.response.status === 422) {
                        SuccessModal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: r.response.data.message,
                        });
                    }
                    return;
                }
                this.props.updateItems();
            })
        } else {
            this.props.removeItem(this.props.item);
        }
    }

    handleSubmit(e) {
        e.preventDefault();

        this.saveData().then(resp => {
            this.props.removeItem(this.props.item);
        });
    }


    saveData() {
        return new Promise((resolve, reject) => {
            let data = {
                damageSetId: this.state.damageSetId ? parseInt(this.state.damageSetId) : null,
                code: this.state.code,
                name: this.state.name,
                type: this.state.type,
                position: this.state.position
            };

            // console.log(data);
            // return;

            if (this.state.id) {
                Axios.put(process.env.REACT_APP_API_BASE + '/damagesetoptions/'+this.state.id, data, {
                    headers: {
                        'Authorization': 'Bearer ' + this.state.token
                    }
                } )
                    .then(response => {
                        console.log(response);
                        if (response.status === 200) {
                            SuccessModal.fire({
                                title: 'Successfully updated damage set option',
                            });
                            resolve('success');
                        }
                    })
                    .catch(error => {
                        console.log(error);
                        this.setState({
                            'formErrors': error.response.data.message
                        }, () => {
                            reject(error.response.data.message);
                        });
                    })
            } else {
                Axios.post(process.env.REACT_APP_API_BASE + '/damagesetoptions', data, {
                    headers: {
                        'Authorization': 'Bearer ' + this.state.token
                    }
                } )
                    .then(response => {

                        if (response.status === 201) {
                            this.setState({
                                id: response.data.damageSetOption.id
                            }, () => {
                                this.props.updateItems();
                                SuccessModal.fire({
                                    title: 'Successfully added damage set option',
                                });
                                resolve('success');
                            });
                        }
                    })
                    .catch(error => {
                        console.log(error);
                        this.setState({
                            'formErrors': error.response.data.message
                        }, () => {
                            reject(error.response.data.message);
                        });
                    })
            }
        });
    }

    render() {
        return (
            <div>
                <form onSubmit={this.handleSubmit}>
                    <div className="row">
                        <div className="col-auto d-flex align-items-center">
                            <div className={"me-2"}>
                                <label className="visually-hidden" htmlFor="code">Code</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="code"
                                    placeholder="code"
                                    required
                                    value={this.state.code}
                                    onChange={this.handleChange}
                                    name={"code"}/>
                            </div>
                            <div>
                                <label className="visually-hidden" htmlFor="name">Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="name"
                                    placeholder="name"
                                    required
                                    value={this.state.name}
                                    onChange={this.handleChange}
                                    name={"name"}/>
                            </div>
                            <div>
                                <label className="visually-hidden" htmlFor="name">Position</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="position"
                                    placeholder="position"
                                    value={this.state.position}
                                    onChange={this.handleChange}
                                    name={"position"}/>
                            </div>
                            <div className="d-flex align-items-center ms-3">
                                <button type={"submit"} className={"control-btn"}>
                                    <FontAwesomeIcon icon={faCheck} />
                                </button>
                                <button onClick={this.removeItem} className={"control-btn ms-1"}>
                                    <FontAwesomeIcon icon={faTrash} />
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

Option.propTypes = {};

export default Option;
