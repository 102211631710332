import Store from "store";
import Axios from "axios";
import moment from "moment";

export const logout = () => {

    Store.remove('user');
    Store.remove('accessToken');
};

export const feLogout = () => {
    Store.remove('accessKey');
};

export const isLogin = () => {
    let jwt = Store.get('accessToken');
    let now = Math.round(Date.now() / 1000);


    // return true;

    if (!jwt) return false;

    //If less than 30 minutes
    if (jwt.expiration - now < 1800) {

        reAuth().then((resp) => {
            return true;
        }).catch(() => {
            return false;
        });
    }
    else if(jwt.expiration - now < 60) {
        Store.remove('accessToken');
        Store.remove('user');
        return false;
    }
    else {
        return true;
    }
};

export const reAuth = () => {
    let jwt = Store.get('accessToken');
    var expiration = new Date().getTime() + 10800;

    return new Promise((resolve, reject) => {
        if (!jwt) reject('Missing jwt');

        const params = new URLSearchParams();
        params.append('refresh_token', jwt.refreshToken);


        Axios.post(process.env.REACT_APP_API_BASE + '/refreshtoken', params, {
            withCredentials: true//SUPER FREAKIN IMPORTANT WITH AUTH IF YOU WANT TO BE ABLE TO SET COOKIE. NEVER FORGET!
        } )
            .then(response => {
                console.log('success');
                if (response.status === 200) {
                    console.log('success 2');
                    console.log('resp obj', response);
                    Store.set('accessToken', {
                        token: response.data['access_token'],
                        refreshToken: response.data['refresh_token'],
                        expiration : expiration
                    });
                }
            })
            .catch(error => {
                console.log('fail');
                console.log(error);
                reject(error);
            })
    })
};




export const getInitials = (string) => {
    if (!string) return;
    var names = string.split(' '),
        initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
};


export const isEmpty = (obj) => {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
};


export const getClient = (id) => {
    if (!id) throw new Error('Missing ID param');

    return new Promise(((resolve, reject) => {
        if (!Store.get('accessToken').token) {
            reject('Missing access token');
        }

        Axios.get(process.env.REACT_APP_API_BASE + '/clients/'+id, {
            headers: {
                'Authorization': 'Bearer ' + Store.get('accessToken').token
            }
        }).then(r => {
            console.log(r);
            if (r.status === 200) {
                // resolve(r.data.clients);
            } else {
                reject(r);
            }
        });
    }));


};

export const getCurrentUser = (id) => {
    return new Promise(((resolve, reject) => {
        if (!Store.get('accessToken').token) {
            reject('Missing access token');
        }

        Axios.get(process.env.REACT_APP_API_BASE + '/admins/'+id, {
            headers: {
                'Authorization': Store.get('accessToken').token
            }
        }).then(r => {
            if (r.status === 200) {
                Store.set('user', r.data);
                resolve(r);
            } else {
                reject(r);
            }
        });
    }));
};

export const isAdmin = () => {
    // App\\Models\\Client

    let user = Store.get('user');

    return user?.profile_type === "App\\Models\\Admin";
};

export const isClient = () => {
    // App\\Models\\Client

    let user = Store.get('user');

    return user?.profile_type === "App\\Models\\Client";
};

export const isNotClient = () => {
    // App\\Models\\Client

    let user = Store.get('user');

    return user?.profile_type === "App\\Models\\Client";
};

export const isManager = () => {
    // App\\Models\\Client

    let user = Store.get('user');

    return user?.profile_type === "App\\Models\\Manager";
};

export const isInspector = () => {
    // App\\Models\\Client

    let user = Store.get('user');

    return user?.profile_type === "App\\Models\\Inspector";
};

export const isAccountant = () => {
    // App\\Models\\Client

    let user = Store.get('user');

    return user?.profile_type === "App\\Models\\Accounting";
};


export const getCurrentUserData = () => {
    return new Promise(((resolve, reject) => {
        if (!Store.get('accessToken').token) {
            reject('Missing access token');
        }

        Axios.get(process.env.REACT_APP_API_BASE + '/user', {
            headers: {
                'Authorization': 'Bearer ' + Store.get('accessToken').token
            }
        }).then(r => {

            if (r.status === 200) {
                Store.set('user', r.data);
                resolve(r);
            } else {
                reject(r);
            }
        });
    }));
};


export const getUser = (id, property) => {
    console.log(id);
    console.log(property);
    return new Promise(((resolve, reject) => {
        if (!Store.get('accessToken').token) {
            reject('Missing access token');
        }

        Axios.get(process.env.REACT_APP_API_BASE + '/user/'+id, {
            headers: {
                'Authorization': 'Bearer ' + Store.get('accessToken').token
            }
        }).then(r => {
            console.log(property);
            if (r.status === 200) {
                if (property) {
                    resolve(r.data[property])
                } else {
                    resolve(r);
                }
            } else {
                reject(r);
            }
        });
    }));
};

export const getAdmins = () => {
    return new Promise(((resolve, reject) => {
        if (!Store.get('accessToken').token) {
            reject('Missing access token');
        }

        Axios.get(process.env.REACT_APP_API_BASE + '/admins', {
            headers: {
                'Authorization': 'Bearer ' + Store.get('accessToken').token
            }
        }).then(r => {
            if (r.status === 200) {
                resolve(r.data.admins);
            } else {
                reject(r);
            }
        });
    }));
};

export const getManagers = () => {
    return new Promise(((resolve, reject) => {
        if (!Store.get('accessToken').token) {
            reject('Missing access token');
        }

        Axios.get(process.env.REACT_APP_API_BASE + '/managers', {
            headers: {
                'Authorization': 'Bearer ' + Store.get('accessToken').token
            }
        }).then(r => {
            if (r.status === 200) {
                resolve(r.data.managers);
            } else {
                reject(r);
            }
        });
    }));
};

export const getInspectors = () => {
    return new Promise(((resolve, reject) => {
        if (!Store.get('accessToken').token) {
            reject('Missing access token');
        }

        Axios.get(process.env.REACT_APP_API_BASE + '/inspectors', {
            headers: {
                'Authorization': 'Bearer ' + Store.get('accessToken').token
            }
        }).then(r => {
            if (r.status === 200) {
                resolve(r.data.inspectors);
            } else {
                reject(r);
            }
        });
    }));
};

export const getInspector = (id) => {
    if (!id) {
        throw new Error('Missing ID param');
    }

    return new Promise(((resolve, reject) => {
        if (!Store.get('accessToken').token) {
            reject('Missing access token');
        }

        Axios.get(process.env.REACT_APP_API_BASE + '/inspectors/' + id, {
            headers: {
                'Authorization': 'Bearer ' + Store.get('accessToken').token
            }
        }).then(r => {
            if (r.status === 200) {
                resolve(r.data.inspector);
            } else {
                reject(r);
            }
        });
    }));
};

export const getInspectusComments = () => {
    return new Promise(((resolve, reject) => {
        if (!Store.get('accessToken').token) {
            reject('Missing access token');
        }

        Axios.get(process.env.REACT_APP_API_BASE + '/damages/inspectus_comments', {
            headers: {
                'Authorization': 'Bearer ' + Store.get('accessToken').token
            }
        }).then(r => {
            if (r.status === 200) {
                resolve(r.data);
            } else {
                reject(r);
            }
        });
    }));
};


export const getCargoItem = (id) => {
    if (!id) {
        throw new Error('Missing ID param');
    }

    return new Promise(((resolve, reject) => {
        if (!Store.get('accessToken').token) {
            reject('Missing access token');
        }

        Axios.get(process.env.REACT_APP_API_BASE + '/items/' + id, {
            headers: {
                'Authorization': 'Bearer ' + Store.get('accessToken').token
            }
        }).then(r => {
            if (r.status === 200) {
                resolve(r.data.item);
            } else {
                reject(r);
            }
        });
    }));
};


export const getCargoItemByVin = (vin) => {
    if (!vin) {
        throw new Error('Missing VIN param');
    }

    return new Promise(((resolve, reject) => {
        if (!Store.get('accessToken').token) {
            reject('Missing access token');
        }

        let config = {};

        config.headers = {
            'Authorization': 'Bearer ' + Store.get('accessToken').token,
        };

        config.params = {
            vin,
            'per_page': -1
        };


        Axios.get(process.env.REACT_APP_API_BASE + '/items', config).then(r => {
            if (r.status === 200) {
                resolve(r.data.items);
            } else {
                reject(r);
            }
        });
    }));
};

export const createCargoItem = (data) => {

    if (!data) {
        throw new Error('Missing cargo item data obj');
    }

    return new Promise(((resolve, reject) => {
        if (!Store.get('accessToken').token) {
            reject('Missing access token');
        }

        Axios.post(process.env.REACT_APP_API_BASE + '/items', data, {
            headers: {
                'Authorization': 'Bearer ' + Store.get('accessToken').token
            }
        }).then(r => {
            if (r.status === 201) {
                resolve(r.data.item);
            } else {
                reject(r);
            }
        });
    }));
};

export const deleteCargoItem = (id, orderId) => {
    if (!id) {
        throw new Error('Missing ID param');
    }

    return new Promise((resolve, reject) => {
        if (!Store.get('accessToken').token) {
            reject('Missing access token');
        }



        Axios.delete(process.env.REACT_APP_API_BASE + '/items/'+id+'/order/'+orderId, {
            headers: {
                'Authorization': 'Bearer ' + Store.get('accessToken').token
            }
        }).then(r => {
            if (r.status === 200) {
                resolve(r);
            } else {
                reject(r);
            }
        });
    });
};

export const assignTransportToItem = (selectedCargoItems, id) => {
    if(!selectedCargoItems) {
        throw new Error('Missing selected items');
    }

    if(!id) {
        throw new Error('Missing ID of transport');
    }

    return new Promise((resolve, reject) => {
        if (!Store.get('accessToken').token) {
            reject('Missing access token');
        }

        Axios.post(process.env.REACT_APP_API_BASE + '/transportmode/'+id+'/assign',{
            items: selectedCargoItems
        },{
            headers: {
                'Authorization': 'Bearer ' + Store.get('accessToken').token
            }
        }).then(r => {
            if (r.status === 200) {
                resolve(r);
            } else {
                reject(r);
            }
        });
    });
}

export const deleteOrder = (id) => {
    if (!id) {
        throw new Error('Missing ID param');
    }

    return new Promise((resolve, reject) => {
        if (!Store.get('accessToken').token) {
            reject('Missing access token');
        }



        Axios.delete(process.env.REACT_APP_API_BASE + '/orders/'+id, {
            headers: {
                'Authorization': 'Bearer ' + Store.get('accessToken').token
            }
        }).then(r => {
            if (r.status === 200) {
                resolve(r);
            } else {
                reject(r);
            }
        });
    });
};

export const moveCargoItem = (id, orderId, oldOrderId) => {

    return new Promise((resolve, reject) => {
        const data = {
            'order_id': oldOrderId,
            'item_id': id,
            'new_order_id': orderId
        };

        Axios.post(process.env.REACT_APP_API_BASE + '/items/move', data, {
            headers: {
                'Authorization': 'Bearer ' + Store.get('accessToken').token
            }
        } )
            .then(response => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject(response);
                }
            })
            .catch(err => {
                console.log(err);
                reject(id);
            });
    });
};

export const isDamaged = (id) => {
    return new Promise((resolve, reject) => {

        Axios.get(process.env.REACT_APP_API_BASE + '/items/'+id, {
            headers: {
                'Authorization': 'Bearer ' + Store.get('accessToken').token
            }
        } )
            .then(response => {
                if (response.status === 200) {
                    return response;
                } else {
                    reject(response);
                }
            })
            .then(r => {
                return r.data.item;
            })
            .then(r => {
                if (r.damages && r.damages.length >0) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            });
    });
}

export const isInspected = (id) => {
    return new Promise((resolve, reject) => {

        Axios.get(process.env.REACT_APP_API_BASE + '/items/'+id, {
            headers: {
                'Authorization': 'Bearer ' + Store.get('accessToken').token
            }
        } )
            .then(response => {
                if (response.status === 200) {
                    return response;
                } else {
                    reject(response);
                }
            })
            .then(r => {
                return r.data.item;
            })
            .then(r => {
                if (r.inspection) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            });
    });
}

export const moveCargoItems = (items, orderId) => {
    if (!items || !Array.isArray(items)) {
        throw new Error('Missing items, or items are not of type array');
    }
    if (!orderId) {
        throw new Error('Missing orderId parameter');
    }



    return new Promise(async (resolve, reject) => {

        getOrderItems(orderId)
            .then((existingItems) => {
                // console.log(existingItems);
                let existingIds = [];

                for (let e of existingItems) {
                    existingIds.push(e.id);
                }
                let mergedItems = [...existingIds, ...items];

                Axios.put(process.env.REACT_APP_API_BASE + '/orders/' + orderId, {
                    'items': mergedItems,
                }, {
                    headers: {
                        'Authorization': 'Bearer ' + Store.get('accessToken').token
                    }
                } )
                    .then(response => {
                        if (response.status === 200) {
                            resolve(response);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    });
            });


    });
};

export const renderImportItemsErrorSwalContent = (content) => {

    return (
        
        <div>
            <p>Some items were not imported because of errors.</p>
            <p>Please check details under "Import data detailed info"</p>
        </div>
    )
}

export const renderSwalContent = (content) => {

    return (
        <div>
            {content?.map(item => {
                return <div>{item.vin || item}</div>
            })}
        </div>
    )
}

export const getClients = () => {
    return new Promise(((resolve, reject) => {
        if (!Store.get('accessToken').token) {
            reject('Missing access token');
        }

        Axios.get(process.env.REACT_APP_API_BASE + '/clients', {
            headers: {
                'Authorization': 'Bearer ' + Store.get('accessToken').token
            }
        }).then(r => {
            if (r.status === 200) {
                resolve(r.data.clients);
            } else {
                reject(r);
            }
        });
    }));
};


export const translatePhaseByName = (name) => {
    let id = null;

    switch (name) {
        case 'First Phase':
            id = 1;
            break;
        case 'Second Phase':
            id = 2;
            break;
        case 'Third Phase':
            id = 3;
            break;
        case 'Fourth Phase':
            id = 4;
            break;
    }

    return id;
};

export const translateStatusByName = (name) => {
    let id = null;

    switch (name) {
        case 'Active':
            id = 1;
            break;
        case 'Processing':
            id = 2;
            break;
        case 'Inactive':
            id = 3;
            break;

    }

    return id;
};

export const translateStatus = (id) => {
    let name = null;

    switch (id) {
        case 1:
            name = 'Active';
            break;
        case 2:
            name = 'Processing';
            break;
        case 3:
            name = 'Inactive';
            break;

    }

    return name;
};

export const translatePhase = (id) => {
    let name = null;

    switch (id) {
        case 1:
            name = 'First Phase';
            break;
        case 2:
            name = 'Second Phase';
            break;
        case 3:
            name = 'Third Phase';
            break;
        case 4:
            name = 'Fourth Phase';
            break;
    }

    return name;
};



export const getDamageSetOptions = (parentId, type) => {
    return new Promise(((resolve, reject) => {
        if (!Store.get('accessToken').token) {
            reject('Missing access token');
        }

        let config = {
            headers: {
                'Authorization': 'Bearer ' + Store.get('accessToken').token
            },
            params: {}
        };

        if (parentId) {
            config.params.damage_set_id = parentId;
        }
        if (type) {
            config.params.type = type;
        }


        Axios.get(process.env.REACT_APP_API_BASE + '/damagesetoptions', config).then(r => {
            if (r.status === 200) {
                resolve(r.data.damageSetOptions);
            } else {
                reject(r);
            }
        });
    }));
};

export const getDamageSets = () => {

    return new Promise(((resolve, reject) => {
        if (!Store.get('accessToken').token) {
            reject('Missing access token');
        }

        let config = {
            headers: {
                'Authorization': 'Bearer ' + Store.get('accessToken').token
            },
            params: {}
        };


        Axios.get(process.env.REACT_APP_API_BASE + '/damagesets', config).then(r => {
            if (r.status === 200) {
                resolve(r.data.damagesets);
            } else {
                reject(r);
            }
        });
    }));
};

export const getDamageSetsById = (damageSetId) => {

    return new Promise(((resolve, reject) => {
        if (!Store.get('accessToken').token) {
            reject('Missing access token');
        }

        let config = {
            headers: {
                'Authorization': 'Bearer ' + Store.get('accessToken').token
            },
            params: {}
        };


        Axios.get(process.env.REACT_APP_API_BASE + '/damagesets/' + damageSetId, config).then(r => {
            if (r.status === 200) {
                resolve(r.data.damageSet);
            } else {
                reject(r);
            }
        });
    }));
};

export const deleteDamageSetOption = (id) => {
    if (!id) {
        throw new Error('Missing ID param');
    }

    return new Promise((resolve, reject) => {
        if (!Store.get('accessToken').token) {
            reject('Missing access token');
        }

        Axios.delete(process.env.REACT_APP_API_BASE + '/damagesetoptions/'+id, {
            headers: {
                'Authorization': 'Bearer ' + Store.get('accessToken').token
            }
        }).then(r => {
            console.log(r);
            if (r.status === 200) {
                resolve(r);
            } else {
                reject(r);
            }
        })
            .catch(err => {
                resolve(err);
            });
    });
};


export const getDamageSetShortcutById = (id) => {

    return new Promise(((resolve, reject) => {
        if (!Store.get('accessToken').token) {
            reject('Missing access token');
        }

        let config = {
            headers: {
                'Authorization': 'Bearer ' + Store.get('accessToken').token
            },
            params: {}
        };


        Axios.get(process.env.REACT_APP_API_BASE + '/shortcuts/' + id, config).then(r => {
            if (r.status === 200) {
                resolve(r.data);
            } else {
                reject(r);
            }
        });
    }));
};


export const getPorts = () => {
    return new Promise(((resolve, reject) => {
        if (!Store.get('accessToken').token) {
            reject('Missing access token');
        }

        Axios.get(process.env.REACT_APP_API_BASE + '/ports', {
            headers: {
                'Authorization': 'Bearer ' + Store.get('accessToken').token
            }
        }).then(r => {
            if (r.status === 200) {
                resolve(r.data.ports);
            } else {
                reject(r);
            }
        });
    }));
};

export const getPort = (id) => {
    return new Promise(((resolve, reject) => {
        if (!Store.get('accessToken').token) {
            reject('Missing access token');
        }

        Axios.get(process.env.REACT_APP_API_BASE + '/ports/' + id, {
            headers: {
                'Authorization': 'Bearer ' + Store.get('accessToken').token
            }
        }).then(r => {
            if (r.status === 200) {
                resolve(r.data.port);
            } else {
                reject(r);
            }
        });
    }));
};

export const getMakes = () => {
    return new Promise(((resolve, reject) => {
        if (!Store.get('accessToken').token) {
            reject('Missing access token');
        }

        Axios.get(process.env.REACT_APP_API_BASE + '/makes', {
            headers: {
                'Authorization': 'Bearer ' + Store.get('accessToken').token
            }
        }).then(r => {
            if (r.status === 200) {
                resolve(r.data.makes);
            } else {
                reject(r);
            }
        });
    }));
};

export const getModels = () => {
    return new Promise(((resolve, reject) => {
        if (!Store.get('accessToken').token) {
            reject('Missing access token');
        }

        Axios.get(process.env.REACT_APP_API_BASE + '/carmodels', {
            headers: {
                'Authorization': 'Bearer ' + Store.get('accessToken').token
            }
        }).then(r => {
            if (r.status === 200) {
                resolve(r.data.carModels);
            } else {
                reject(r);
            }
        }).catch(err => {
            console.log(err);
        });
    }));
};


export const getTransports = () => {
    return new Promise(((resolve, reject) => {
        if (!Store.get('accessToken').token) {
            reject('Missing access token');
        }

        Axios.get(process.env.REACT_APP_API_BASE + '/transports', {
            headers: {
                'Authorization': 'Bearer ' + Store.get('accessToken').token
            }
        }).then(r => {
            if (r.status === 200) {
                resolve(r.data.transports);
            } else {
                reject(r);
            }
        });
    }));
};

export const getTransportModes = (id) => {
    return new Promise(((resolve, reject) => {
        if (!Store.get('accessToken').token) {
            reject('Missing access token');
        }

        Axios.get(process.env.REACT_APP_API_BASE + '/transportmode?order_id=' + id, {
            headers: {
                'Authorization': 'Bearer ' + Store.get('accessToken').token
            }
        }).then(r => {
            if (r.status === 200) {
                resolve(r.data);
            } else {
                reject(r);
            }
        });
    }));
};

export const getTransportCompanies = () => {
    return new Promise(((resolve, reject) => {
        if (!Store.get('accessToken').token) {
            reject('Missing access token');
        }

        Axios.get(process.env.REACT_APP_API_BASE + '/transportCompanies', {
            headers: {
                'Authorization': 'Bearer ' + Store.get('accessToken').token
            }
        }).then(r => {
            if (r.status === 200) {
                resolve(r.data);
            } else {
                reject(r);
            }
        });
    }));
};

export const getTransportCompany = (id) => {
    return new Promise(((resolve, reject) => {

        if (!Store.get('accessToken').token) {
            reject('Missing access token');
        }
        if (!id) {
            reject('missing transport company id in getTransportCompany');
        }

        Axios.get(process.env.REACT_APP_API_BASE + '/transportCompanies/'+id, {
            headers: {
                'Authorization': 'Bearer ' + Store.get('accessToken').token
            }
        }).then(r => {
            if (r.status === 200) {
                resolve(r.data);
            } else {
                reject(r);
            }
        });
    }));
};


export const getTransport = (id) => {
    return new Promise(((resolve, reject) => {

        if (!Store.get('accessToken').token) {
            reject('Missing access token');
        }
        if (!id) {
            reject('missing transport ID in getTransport');
        }

        Axios.get(process.env.REACT_APP_API_BASE + '/transports/'+id, {
            headers: {
                'Authorization': 'Bearer ' + Store.get('accessToken').token
            }
        }).then(r => {
            if (r.status === 200) {
                resolve(r.data.transport);
            } else {
                reject(r);
            }
        });
    }));
};

export const getMake = (id) => {
    return new Promise(((resolve, reject) => {

        if (!Store.get('accessToken').token) {
            reject('Missing access token');
        }
        if (!id) {
            reject('missing transport ID in getTransport');
        }

        Axios.get(process.env.REACT_APP_API_BASE + '/makes/'+id, {
            headers: {
                'Authorization': 'Bearer ' + Store.get('accessToken').token
            }
        }).then(r => {
            if (r.status === 200) {
                resolve(r.data.make);
            } else {
                reject(r);
            }
        });
    }));
};

export const getModel = (id) => {
    return new Promise(((resolve, reject) => {

        if (!Store.get('accessToken').token) {
            reject('Missing access token');
        }
        if (!id) {
            reject('missing transport ID in getTransport');
        }

        Axios.get(process.env.REACT_APP_API_BASE + '/carmodels/'+id, {
            headers: {
                'Authorization': 'Bearer ' + Store.get('accessToken').token
            }
        }).then(r => {
            if (r.status === 200) {
                resolve(r.data.carModel);
            } else {
                reject(r);
            }
        });
    }));
};

export const getImage = (id) => {
    return new Promise(((resolve, reject) => {

        if (!Store.get('accessToken').token) {
            reject('Missing access token');
        }
        if (!id) {
            reject('missing transport ID in getTransport');
        }

        Axios.get(process.env.REACT_APP_API_BASE + '/images/'+id, {
            headers: {
                'Authorization': 'Bearer ' + Store.get('accessToken').token
            }
        }).then(r => {
            if (r.status === 200) {
                resolve(r.data.image);
            } else {
                reject(r);
            }
        });
    }));
};


export const downloadFile = (url) => {
    const xmlHttp = new XMLHttpRequest();
    xmlHttp.onreadystatechange = () => {
        if (xmlHttp.readyState === 4 && xmlHttp.status === 200) {
            const blobUrl = window.URL.createObjectURL(xmlHttp.response);
            const e = document.createElement('a');
            e.href = blobUrl;
            e.download = blobUrl.substr(blobUrl.lastIndexOf('/') + 1);
            document.body.appendChild(e);
            e.click();
            document.body.removeChild(e);
        }
    };
    xmlHttp.responseType = 'blob';
    xmlHttp.open('GET', url, true);
    xmlHttp.send(null);
};


export const deleteImage = (id, type = 'images') => {
    return new Promise(((resolve, reject) => {

        if (!Store.get('accessToken').token) {
            reject('Missing access token');
        }
        if (!id) {
            reject('missing image ID in deleteImage()');
        }

        Axios.delete(process.env.REACT_APP_API_BASE + '/'+type+'/'+id, {
            headers: {
                'Authorization': 'Bearer ' + Store.get('accessToken').token
            }
        }).then(r => {
            console.log(r);
            if (r.status === 200) {
                resolve(r.data.image);
            } else {
                reject(r);
            }
        });
    }));
};

// export const deleteOrderImage = (id) => {
//     return new Promise(((resolve, reject) => {
//
//         if (!Store.get('accessToken').token) {
//             reject('Missing access token');
//         }
//         if (!id) {
//             reject('missing image ID in deleteImage()');
//         }
//
//         Axios.delete(process.env.REACT_APP_API_BASE + '/orderimages/'+id, {
//             headers: {
//                 'Authorization': 'Bearer ' + Store.get('accessToken').token
//             }
//         }).then(r => {
//             console.log(r);
//             if (r.status === 200) {
//                 resolve(r.data.image);
//             } else {
//                 reject(r);
//             }
//         });
//     }));
// };



export const updateImageObjects = async (ids) => {
    if (ids && ids > 0) {
        ids.map(image => {
            return getImage(image).then(resp => {
                this.setState({
                    imageObjects: [...this.state.imageObjects, resp]
                })
            })
        });
    }
};

export const displayDate = (time, currentFormat = null) => {
    if (!time) return false;

    return moment(time, currentFormat).format('DD. MM. YYYY');
};

export const displayDateTime = (time, currentFormat = null) => {
    if (!time) return false;

    return moment(time, currentFormat).format('DD. MM. YYYY HH:mm');
};

export const removeItemOnce = (arr, index) => {
    if (index > -1) {
        arr.splice(index, 1);
    }
    return arr;
};

export const getOrder = (orderId) => {

    return new Promise((resolve, reject) => {

        if (!Store.get('accessToken').token) {
            reject('Missing access token');
        }

        Axios.get(process.env.REACT_APP_API_BASE + '/orders/'+orderId, {
            headers: {
                'Authorization': 'Bearer ' + Store.get('accessToken').token
            }
        }).then(r => {
            if (r.status === 200) {
                if (r.data.order) {
                    resolve(r.data.order);
                }
            } else {
                reject(r);
            }
        });
    });
};

export const getOrderItems = (orderId, disablePagination=false) => {

    return new Promise(((resolve, reject) => {

        if (!Store.get('accessToken').token) {
            reject('Missing access token');
        }

        let config = {};
        config.headers = {
            'Authorization': 'Bearer ' + Store.get('accessToken').token,
        };

        if (disablePagination) {
            config.params = {
                'per_page': -1
            };
        }

        Axios.get(process.env.REACT_APP_API_BASE + '/orders/'+orderId, config)
            .then(r => {
                if (r.status === 200) {
                    if (r.data.order.items) {
                        resolve(r.data.order.items);
                    }
                } else {
                    reject(r);
                }
            });
    }));
};

export const fetchPortsFromArr = async (arr, getterFunction) => {
    let items = [];

    if (arr && arr.length > 0) {
        for (let t of arr) {

            const r = await getterFunction(t);
            console.log('AAAAAAAAAAAAAA');
            console.log(r);
            

            items.push({
                'id': r.id,
                'value': r.code
            });
        }
    }

    return items;
};

export const fetchItemsFromArr = async (arr, getterFunction) => {
    let items = [];

    if (arr && arr.length > 0) {
        for (let t of arr) {
            const r = await getterFunction(t);
            items.push(r);
        }
    }

    return items;
};


export const generateRandomId = (prefix) => {
    return prefix + '-' + Math.floor(Math.random() * 9999);
};

export const validateDuplicateVin = (vins, orderId) => {
    console.log(vins);
    if (!vins) {
        throw new Error('Missing vin');
    }
    if (!orderId) {
        throw new Error('Missing order id');
    }

    return new Promise((resolve, reject) => {
        getOrderItems(orderId, true)
            .then(r => {
                let orderVins = r?.map(i => {
                    return i.vin;
                });

                const result = vins.reduce((res, item) => {
                    res[orderVins.find(orderVin => orderVin === item.vin) ? 'invalid' : 'valid'].push(item);
                    return res;
                }, { invalid: [], valid: [] });


                resolve(result);
            })
            .catch(err => {
                reject(err);
            });
    });
};


export const getDamage = (id) => {
    return new Promise((resolve, reject) => {

        if (!Store.get('accessToken').token) {
            reject('Missing access token');
        }

        Axios.get(process.env.REACT_APP_API_BASE + '/damages/'+id, {
            headers: {
                'Authorization': 'Bearer ' + Store.get('accessToken').token
            }
        }).then(r => {
            if (r.status === 200) {
                if (r.data.damage) {
                    resolve(r.data.damage);
                }
            } else {
                reject(r);
            }
        });
    });
};
