import React from "react";
import Axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const SuccessModal = withReactContent(Swal);


class PasswordResetRequest extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            email: ""
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleSubmit(e) {
        e.preventDefault();
        Axios.post(process.env.REACT_APP_API_BASE + '/password/requestreset', this.state)
            .then((response) => {
                if(response.status === 200 || response.status === 404) {
                    return Swal.fire({
                        icon: "success",
                        title: "Password reset link was sent to your email"
                    }).then(() => {
                        return this.props.history.push("/");
                    })
                }
            })
            .catch(err => {
                return Swal.fire({
                    icon: "success",
                    title: "Password reset link was sent to your email"
                })
            })
    }

    handleChange(e) {
        this.setState({
            email: e.target.value
        })
    }

    render() {
        return (
            <div className="home-bg d-flex align-items-center">

                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-4 mx-md-auto">
                            <div className="card">
                                <div className="card-body">
                                    <div>
                                        <h1 className="logo">Ares Password reset</h1>
                                    </div>
                                    <div className="mt-5">
                                        <form action="" onSubmit={this.handleSubmit}>
                                            <div className="form-group">
                                                <label className={"form-label"} htmlFor="email">Email</label>
                                                <input type="email" className="form-control" name={"email"} id={"email"} value={this.state.email} onChange={this.handleChange} required/>
                                            </div>

                                            <div className="d-flex align-items-center justify-content-between">
                                                <div>
                                                    <button type={"submit"} className={"btn btn-primary"}>Reset password</button>
                                                </div>

                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

PasswordResetRequest.propTypes = {};

export default PasswordResetRequest;
