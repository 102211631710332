import React from "react";
import Store from "store";
import Axios from "axios";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const CodeModal = withReactContent(Swal);
const SuccessModal = withReactContent(Swal);

class MakeCodes extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            token: Store.get('accessToken').token
        };

        this.doEdit = this.doEdit.bind(this);
        this.doDelete = this.doDelete.bind(this);
        this.doAdd = this.doAdd.bind(this);
        this.fetchCodes = this.fetchCodes.bind(this);
    }

    componentDidMount() {
        this.fetchCodes();
    }

    fetchCodes() {
        if (this.props.makeId) {

            let config = {};
            config.headers = {
                'Authorization': 'Bearer ' + this.state.token
            };

            config.params = {
                'make_id' : this.props.makeId
            }

            Axios.get(process.env.REACT_APP_API_BASE + '/makecodes', config).then(response => {
                console.log(response);
                if (response.status === 200) {
                    this.setState({
                        items: response.data.makeCodes
                    })
                }
            }).catch(error => {
                console.log('error', error);
                if (error.status === 401) {
                    console.log('attempting reauth');
                }
            });
        }
    }

    doEdit(id, previousCode) {
        if (!id) return;

        CodeModal.fire({
            title: 'Editing: ' + previousCode,
            input: 'text',
            inputAttributes: {
                'required': 'true'
            },
            inputValidator: (value) => {
                if (!value) {
                    return 'You need to enter a code'
                }
            },
            inputPlaceholder: 'ex.: XCV',
            inputValue: previousCode,
            showCancelButton: true,
        }).then((value) => {
            console.log('this was selected', value);

            if (value.isConfirmed && value.value) {
                Axios.put(process.env.REACT_APP_API_BASE + '/makecodes/'+id, {
                    'code': value.value
                }, {
                    headers: {
                        'Authorization': 'Bearer ' + this.state.token
                    }
                } )
                    .then(() => {

                        this.fetchCodes();

                        SuccessModal.fire({
                            title: 'Successfully Updated Make Code'
                        });
                    });
            }
        });
    }

    doDelete(id) {
        if (!id) return;

        if (!id) return;

        CodeModal.fire({
            title: 'Are you sure you want to delete this code?',
            showCancelButton: true,
        }).then((value) => {
            console.log('this was selected', value);

            if (value.isConfirmed) {
                Axios.delete(process.env.REACT_APP_API_BASE + '/makecodes/'+id,  {
                    headers: {
                        'Authorization': 'Bearer ' + this.state.token
                    }
                } )
                    .then(() => {

                        this.fetchCodes();

                        SuccessModal.fire({
                            title: 'Successfully Deleted Make Code'
                        });
                    });
            }
        });
    }

    doAdd() {
        CodeModal.fire({
            title: 'Add Make Code',
            input: 'text',
            inputAttributes: {
                'required': 'true'
            },
            inputValidator: (value) => {
                if (!value) {
                    return 'You need to enter a code'
                }
            },
            inputPlaceholder: 'ex.: XCV',
            showCancelButton: true,
        }).then((value) => {
            console.log('this was selected', value);

            if (value.isConfirmed && value.value) {
                Axios.post(process.env.REACT_APP_API_BASE + '/makecodes', {
                    'make_id': this.props.makeId,
                    'code': value.value
                }, {
                    headers: {
                        'Authorization': 'Bearer ' + this.state.token
                    }
                } )
                    .then(() => {

                        this.fetchCodes();

                        SuccessModal.fire({
                            title: 'Successfully added Make Code'
                        });
                    });
            }
        });
    }

    render() {
        if (this.state.items) {
            return (
                <div>
                    <section>
                        <div>
                            <h2>Make Identifier Codes</h2>
                            <p>Lorem ipsum dolor sit amet. Some placeholder instructions.</p>
                        </div>
                        <table className={"table table-striped"}>
                            <thead>
                            <tr>
                                <th>Code</th>
                                <th className={"text-end"}>Actions</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                this.state.items.map((item, index) => {
                                    return (
                                        <tr key={"make-code-"+index}>
                                            <td>{item.code}</td>
                                            <td className={"text-end"}>
                                                <button onClick={() => {
                                                    this.doEdit(item.id, item.code);
                                                }} className={"btn-min"}>Edit</button>
                                                <button onClick={() => {
                                                    this.doDelete(item.id)
                                                }} className={"btn-min ms-3"}>Remove</button>
                                            </td>
                                        </tr>
                                    );
                                })
                            }
                            </tbody>
                        </table>
                        <div className="mt-3 text-end">
                            <button onClick={() => {
                                this.doAdd();
                            }} className={"btn btn-primary"}>Add new</button>
                        </div>
                    </section>
                </div>
            )
        } else {
            return null;
        }
    }
}

MakeCodes.propTypes = {};

export default MakeCodes;
