import React from "react";

class ConditionalContent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        if (this.props.check) {
            return (
                <div className={this.props.className}>
                    {this.props.children}
                </div>
            );
        } else {
            return null;
        }
    }
}

ConditionalContent.propTypes = {};

export default ConditionalContent;
