import React from "react";
import ModelCodes from "./ModelCodes/ModelCodes";
import Axios from "axios";
import Store from "store";

import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
const SuccessModal = withReactContent(Swal);

class CarModel extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            token: Store.get('accessToken').token,
            instances: []
        };

        this.refreshCodes = this.refreshCodes.bind(this);
        this.removeCode = this.removeCode.bind(this);
        this.generateRandomRepeaterId = this.generateRandomRepeaterId.bind(this);
        this.addRepeaterInstance = this.addRepeaterInstance.bind(this);
        this.removeRepeaterInstance = this.removeRepeaterInstance.bind(this);
        this.doRemoveCode = this.doRemoveCode.bind(this);
    }

    componentDidMount() {
        if (this.props.item.id) {
            this.refreshCodes();
        }
    }

    generateRandomRepeaterId() {
        if (this.props.item) {
            return this.props.item.id + '-' + Math.floor(Math.random() * 9999);
        }
    }

    addRepeaterInstance() {
        let tempId = this.generateRandomRepeaterId();
        let tempArr = this.state.instances;

        if (!tempArr.includes(tempId)) {
            tempArr.push(tempId);
            this.setState({
                instances: tempArr
            });
        } else {
            //If random id is duplicated by some minuscule chance
            this.addRepeaterInstance();
        }
    }

    removeCode(id) {


        this.doRemoveCode(id).then(() => {
            console.log('should refresh');
            this.refreshCodes();
        })
    }

    doRemoveCode(id) {
        return new Promise((resolve, reject) => {

            Axios.delete(process.env.REACT_APP_API_BASE + '/modelcodes/'+id, {
                headers: {
                    'Authorization': 'Bearer ' + this.state.token
                }
            } )
                .then(response => {
                    if (response.status === 200) {
                        SuccessModal.fire({
                            title: 'Successfully removed code',
                        });
                        resolve('success');
                    }
                })
                .catch(error => {
                    console.log(error);
                    this.setState({
                        'formErrors': error.response.data.message
                    }, () => {
                        reject(error.response.data.message);
                    });
                })
        });
    }


    //Removes temp damage from state array tempDamages
    removeRepeaterInstance(tempId) {
        if(!tempId) return;
        let instances;
        instances = this.state.instances;

        let newInstances = instances.filter( e => {
            return e !== tempId;
        });

        this.setState({
            instances: newInstances
        });
    }

    refreshCodes() {
        return new Promise((resolve, reject) => {
            let config = {};

            config.headers = {
                'Authorization': 'Bearer ' + this.state.token
            };

            config.params = {
                'model_id': this.props.item.id
            };

            Axios.get(process.env.REACT_APP_API_BASE + '/modelcodes', config).then(response => {
                if (response.status === 200) {
                    this.setState({
                        codes: response.data.modelCodes
                    }, () => {
                        resolve(response);
                    });
                } else {
                    reject('smt went wrong');
                }
            }).catch(err => {
                console.log(err);
                if (err.response.status === 401) {
                    console.log('attempting reauth');
                }
            });
        })
    }


    render() {
        if (this.props.item) {
            return (
                <div className={"card no-shadow"}>
                    <div className="card-body">
                        <div>
                            <h6 className={"mb-0"}>{this.props.item.name}</h6>
                        </div>
                        <div className="cat-items layout-grid-1">
                            <ModelCodes items={this.state.codes} refreshCodes={this.refreshCodes} removeCode={this.removeCode}  modelId={this.props.item.id} />
                            <ModelCodes items={this.state.instances} refreshCodes={this.refreshCodes} removeInstance={this.removeRepeaterInstance} modelId={this.props.item.id} makeId={this.props.makeId}/>
                        </div>
                        <div className={"mt-3"}>
                            <button onClick={() => {this.addRepeaterInstance()}} className={"btn-min text-secondary"}>Add Code</button>
                        </div>
                    </div>
                </div>
            )
        }
    }
}

CarModel.propTypes = {};

export default CarModel;
