import React from "react";
import Axios from "axios";
import Store from "store";
import { saveAs } from 'file-saver';
import { WithContext as ReactTags } from 'react-tag-input';

class CreateFinalReport extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            token: Store.get('accessToken').token,
            id: this.props.match.params.entryId,
            gate: false,
            order_notes: false,
            transport_type: false,
            cargo_type: false,
            inspection_type: false,
            phase: false,
            car_model: false,
            make: false,
            port_of_origin: false,
            transshipment_port: false,
            port_of_discharging: false,
            inspection_place: false,
            destination: false,
            transport: false,
            transport_company: false,
            bl: false,
            info: false,
            voyage: false,
            item_id: false,
            ds_part: false,
            ds_type: false,
            ds_severity: false,
            ds_cause: false,
            ds_repair_mode: false,
            ds_class: false,
            ds_dln: false,
            privacyPolicies: [],
            privacyPolicy: '',
            custom_comment: '',
            recipients: []
        };
        this.radioSelected = this.radioSelected.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        Axios.get(process.env.REACT_APP_API_BASE + '/privacypolicies', {
            headers: {
                'Authorization': 'Bearer ' + this.state.token
            }
        }).then(res => {
            this.setState({
                privacyPolicies: res.data.privacy_policies
            })
            console.log(res.data.privacy_policies);
        })
    }

    radioSelected(e) {
        let selection = e.target.value;
        if(selection === 'true') {
            this.setState({
                [e.target.name]: true
            })
        } else {
            this.setState({
                [e.target.name]: false
            })
        }
    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleSubmit(e) {
        e.preventDefault();
        console.log(this.state);

        let sendData = {
            gate: this.state.gate,
            order_notes: this.state.order_notes,
            transport_type: this.state.transport_type,
            cargo_type: this.state.cargo_type,
            inspection_type: this.state.inspection_type,
            phase: this.state.phase,
            car_model: this.state.car_model,
            make: this.state.make,
            port_of_origin: this.state.port_of_origin,
            transshipment_port: this.state.transshipment_port,
            port_of_discharging: this.state.port_of_discharging,
            inspection_place: this.state.inspection_place,
            destination: this.state.destination,
            transport: this.state.transport,
            transport_company: this.state.transport_company,
            bl: this.state.bl,
            info: this.state.info,
            voyage: this.state.voyage,
            item_id: this.state.item_id,
            ds_part: this.state.ds_part,
            ds_type: this.state.ds_type,
            ds_severity: this.state.ds_severity,
            ds_cause: this.state.ds_cause,
            ds_repair_mode: this.state.ds_repair_mode,
            ds_class: this.state.ds_class,
            ds_dln: this.state.ds_dln,
            privacyPolicy: this.state.privacyPolicy,
            comment: this.state.comment
        }

        let config = {
            responseType: "blob"
        };
        config.headers = {
            'Authorization': 'Bearer ' + Store.get('accessToken').token
        };

        Axios.post(process.env.REACT_APP_API_BASE + '/orders/' + this.state.id + '/reports/final', sendData, /*{
            headers: {
                'Authorization': 'Bearer ' + this.state.token
            }
        }*/config)
            .then(res => res.data)
            /*.then(res => {
                console.log(res.data);
            })*/
            .then(blob => saveAs(blob, 'preliminary-report'))

        /*Axios.post(process.env.REACT_APP_API_BASE + '/orders/' + this.state.id + '/reports/preliminary', sendData, {
            headers: {
                'Authorization': 'Bearer ' + this.state.token
            }
        }).then(res => {
            console.log(res);
        })*/
    }

    render() {
        return (
            <div className="container pb-10" id="main">
                <div className="row mb-5">
                    <div className="col-12 col-md-8">
                        <h1 className={"mt-1"}>Generate final report</h1>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 col-md-8">
                        <h2>Select things to include in report</h2>

                        <form action="" className={"mb-5"} onSubmit={this.handleSubmit}>

                            <div className={'layout-grid-3'}>
                                <div>
                                    <h6 className="mt-5">Gate</h6>

                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="true" className="form-check-input" checked={this.state.gate === true} type="radio" name="gate"
                                               id="flexRadioDefault1">
                                        </input>Yes
                                    </div>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="false" className="form-check-input" checked={this.state.gate === false} type="radio" name="gate"
                                               id="flexRadioDefault2">
                                        </input>No
                                    </div>
                                </div>

                                <div>
                                    <h6 className="mt-5">Order notes</h6>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="true" className="form-check-input" checked={this.state.order_notes === true} type="radio" name="order_notes"
                                               id="flexRadioDefault1">
                                        </input>Yes
                                    </div>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="false" className="form-check-input" checked={this.state.order_notes === false} type="radio" name="order_notes"
                                               id="flexRadioDefault2">
                                        </input>No
                                    </div>
                                </div>

                                <div>
                                    <h6 className="mt-5">Transport type</h6>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="true" className="form-check-input" checked={this.state.transport_type === true} type="radio" name="transport_type"
                                               id="flexRadioDefault1">
                                        </input>Yes
                                    </div>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="false" className="form-check-input" checked={this.state.transport_type === false} type="radio" name="transport_type"
                                               id="flexRadioDefault2">
                                        </input>No
                                    </div>
                                </div>

                                <div>
                                    <h6 className="mt-5">Cargo type</h6>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="true" className="form-check-input" checked={this.state.cargo_type === true} type="radio" name="cargo_type"
                                               id="flexRadioDefault1">
                                        </input>Yes
                                    </div>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="false" className="form-check-input" checked={this.state.cargo_type === false} type="radio" name="cargo_type"
                                               id="flexRadioDefault2">
                                        </input>No
                                    </div>
                                </div>

                                <div>
                                    <h6 className="mt-5">Phase</h6>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="true" className="form-check-input" checked={this.state.phase === true} type="radio" name="phase"
                                               id="flexRadioDefault1">
                                        </input>Yes
                                    </div>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="false" className="form-check-input" checked={this.state.phase === false} type="radio" name="phase"
                                               id="flexRadioDefault2">
                                        </input>No
                                    </div>
                                </div>

                                <div>
                                    <h6 className="mt-5">Car model</h6>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="true" className="form-check-input" checked={this.state.car_model === true} type="radio" name="car_model"
                                               id="flexRadioDefault1">
                                        </input>Yes
                                    </div>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="false" className="form-check-input" checked={this.state.car_model === false} type="radio" name="car_model"
                                               id="flexRadioDefault2">
                                        </input>No
                                    </div>
                                </div>

                                <div>
                                    <h6 className="mt-5">Make</h6>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="true" className="form-check-input" checked={this.state.make === true} type="radio" name="make"
                                               id="flexRadioDefault1">
                                        </input>Yes
                                    </div>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="false" className="form-check-input" checked={this.state.make === false} type="radio" name="make"
                                               id="flexRadioDefault2">
                                        </input>No
                                    </div>
                                </div>

                                <div>
                                    <h6 className="mt-5">Port of origin</h6>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="true" className="form-check-input" checked={this.state.port_of_origin === true} type="radio" name="port_of_origin"
                                               id="flexRadioDefault1">
                                        </input>Yes
                                    </div>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="false" className="form-check-input" checked={this.state.port_of_origin === false} type="radio" name="port_of_origin"
                                               id="flexRadioDefault2">
                                        </input>No
                                    </div>
                                </div>

                                <div>
                                    <h6 className="mt-5">Transshipment port</h6>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="true" className="form-check-input" checked={this.state.transshipment_port === true} type="radio" name="transshipment_port"
                                               id="flexRadioDefault1">
                                        </input>Yes
                                    </div>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="false" className="form-check-input" checked={this.state.transshipment_port === false} type="radio" name="transshipment_port"
                                               id="flexRadioDefault2">
                                        </input>No
                                    </div>
                                </div>

                                <div>
                                    <h6 className="mt-5">Port of discharging</h6>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="true" className="form-check-input" checked={this.state.port_of_discharging === true} type="radio" name="port_of_discharging"
                                               id="flexRadioDefault1">
                                        </input>Yes
                                    </div>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="false" className="form-check-input" checked={this.state.port_of_discharging === false} type="radio" name="port_of_discharging"
                                               id="flexRadioDefault2">
                                        </input>No
                                    </div>
                                </div>

                                <div>
                                    <h6 className="mt-5">Inspection place</h6>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="true" className="form-check-input" checked={this.state.inspection_place === true} type="radio" name="inspection_place"
                                               id="flexRadioDefault1">
                                        </input>Yes
                                    </div>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="false" className="form-check-input" checked={this.state.inspection_place === false} type="radio" name="inspection_place"
                                               id="flexRadioDefault2">
                                        </input>No
                                    </div>
                                </div>

                                <div>
                                    <h6 className="mt-5">Destination</h6>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="true" className="form-check-input" checked={this.state.destination === true} type="radio" name="destination"
                                               id="flexRadioDefault1">
                                        </input>Yes
                                    </div>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="false" className="form-check-input" type="radio" checked={this.state.destination === false} name="destination"
                                               id="flexRadioDefault2">
                                        </input>No
                                    </div>
                                </div>

                                <div>
                                    <h6 className="mt-5">Transport</h6>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="true" className="form-check-input" checked={this.state.transport === true} type="radio" name="transport"
                                               id="flexRadioDefault1">
                                        </input>Yes
                                    </div>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="false" className="form-check-input" type="radio" checked={this.state.transport === false} name="transport"
                                               id="flexRadioDefault2">
                                        </input>No
                                    </div>
                                </div>

                                <div>
                                    <h6 className="mt-5">Transport company</h6>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="true" className="form-check-input" checked={this.state.transport_company === true} type="radio" name="transport_company"
                                               id="flexRadioDefault1">
                                        </input>Yes
                                    </div>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="false" className="form-check-input" type="radio" checked={this.state.transport_company === false} name="transport_company"
                                               id="flexRadioDefault2">
                                        </input>No
                                    </div>
                                </div>

                                <div>
                                    <h6 className="mt-5">Bl</h6>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="true" className="form-check-input" checked={this.state.bl === true} type="radio" name="bl"
                                               id="flexRadioDefault1">
                                        </input>Yes
                                    </div>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="false" className="form-check-input" type="radio" checked={this.state.bl === false} name="bl"
                                               id="flexRadioDefault2">
                                        </input>No
                                    </div>
                                </div>

                                <div>
                                    <h6 className="mt-5">Info</h6>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="true" className="form-check-input" checked={this.state.info === true} type="radio" name="info"
                                               id="flexRadioDefault1">
                                        </input>Yes
                                    </div>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="false" className="form-check-input" type="radio" checked={this.state.info === false} name="info"
                                               id="flexRadioDefault2">
                                        </input>No
                                    </div>
                                </div>

                                <div>
                                    <h6 className="mt-5">Voyage</h6>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="true" className="form-check-input" checked={this.state.voyage === true} type="radio" name="voyage"
                                               id="flexRadioDefault1">
                                        </input>Yes
                                    </div>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="false" className="form-check-input" type="radio" checked={this.state.voyage === false} name="voyage"
                                               id="flexRadioDefault2">
                                        </input>No
                                    </div>
                                </div>

                                <div>
                                    <h6 className="mt-5">Item id</h6>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="true" className="form-check-input" checked={this.state.item_id === true} type="radio" name="item_id"
                                               id="flexRadioDefault1">
                                        </input>Yes
                                    </div>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="false" className="form-check-input" type="radio" checked={this.state.item_id === false} name="item_id"
                                               id="flexRadioDefault2">
                                        </input>No
                                    </div>
                                </div>

                                <div>
                                    <h6 className="mt-5">Ds part</h6>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="true" className="form-check-input" checked={this.state.ds_part === true} type="radio" name="ds_part"
                                               id="flexRadioDefault1">
                                        </input>Yes
                                    </div>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="false" className="form-check-input" type="radio" checked={this.state.ds_part === false} name="ds_part"
                                               id="flexRadioDefault2">
                                        </input>No
                                    </div>
                                </div>

                                <div>
                                    <h6 className="mt-5">Ds type</h6>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="true" className="form-check-input" checked={this.state.ds_type === true} type="radio" name="ds_type"
                                               id="flexRadioDefault1">
                                        </input>Yes
                                    </div>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="false" className="form-check-input" type="radio" checked={this.state.ds_type === false} name="ds_type"
                                               id="flexRadioDefault2">
                                        </input>No
                                    </div>
                                </div>

                                <div>
                                    <h6 className="mt-5">Ds severity</h6>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="true" className="form-check-input" checked={this.state.ds_severity === true} type="radio" name="ds_severity"
                                               id="flexRadioDefault1">
                                        </input>Yes
                                    </div>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="false" className="form-check-input" type="radio" checked={this.state.ds_severity === false} name="ds_severity"
                                               id="flexRadioDefault2">
                                        </input>No
                                    </div>
                                </div>

                                <div>
                                    <h6 className="mt-5">Ds cause</h6>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="true" className="form-check-input" checked={this.state.ds_cause === true} type="radio" name="ds_cause"
                                               id="flexRadioDefault1">
                                        </input>Yes
                                    </div>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="false" className="form-check-input" type="radio" checked={this.state.ds_cause === false} name="ds_cause"
                                               id="flexRadioDefault2">
                                        </input>No
                                    </div>
                                </div>

                                <div>
                                    <h6 className="mt-5">Ds repair mode</h6>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="true" className="form-check-input" checked={this.state.ds_repair_mode === true} type="radio" name="ds_repair_mode"
                                               id="flexRadioDefault1">
                                        </input>Yes
                                    </div>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="false" className="form-check-input" type="radio" checked={this.state.ds_repair_mode === false} name="ds_repair_mode"
                                               id="flexRadioDefault2">
                                        </input>No
                                    </div>
                                </div>

                                <div>
                                    <h6 className="mt-5">Ds class</h6>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="true" className="form-check-input" checked={this.state.ds_class === true} type="radio" name="ds_class"
                                               id="flexRadioDefault1">
                                        </input>Yes
                                    </div>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="false" className="form-check-input" type="radio" checked={this.state.ds_class === false} name="ds_class"
                                               id="flexRadioDefault2">
                                        </input>No
                                    </div>
                                </div>

                                <div>
                                    <h6 className="mt-5">Ds dln</h6>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="true" className="form-check-input" checked={this.state.ds_dln === true} type="radio" name="ds_dln"
                                               id="flexRadioDefault1">
                                        </input>Yes
                                    </div>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="false" className="form-check-input" type="radio" checked={this.state.ds_dln === false} name="ds_dln"
                                               id="flexRadioDefault2">
                                        </input>No
                                    </div>
                                </div>
                            </div>

                            <div className="form-group mt-5">
                                <h6>Privacy policy</h6>
                                <select className={"form-select"} name={"privacyPolicy"} id={"privacyPolicy"} onChange={this.handleChange}>
                                    <option value="">Select</option>
                                    {this.state.privacyPolicies.map(policy => {
                                        return <option value={policy.id}>{policy.text}</option>
                                    })}
                                </select>
                            </div>

                            <div className="form-group mt-5">
                                <h6>Custom comment</h6>
                                <textarea className={'form-control'} rows="4" name="custom_comment" width="100" value={this.state.custom_comment} onChange={this.handleChange}></textarea>
                            </div>

                            <button type={'submit'} className={'btn btn-primary'}>Send</button>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

CreateFinalReport.propTypes = {};

export default CreateFinalReport;
