import React from "react";
import {Link} from "react-router-dom";
import Style from "./DamageSet.module.scss";

class DamageSet extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        return (
            <Link className={Style.item + ' card no-shadow'} to={"/dashboard/damage-sets/edit/"+this.props.entry.id}>
                <div className="card-body bg-white py-3">
                    <span className="d-block mb-1">ID: {this.props.entry.id}</span>
                    <span className={"d-block strong"}>{this.props.entry.name}</span>
                </div>
            </Link>
        );
    }
}

DamageSet.propTypes = {};

export default DamageSet;
