import React from "react";
import {Link, withRouter} from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

import Axios from "axios";
import Store from "store";

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import DsInputShortcut from "../../Orders/SingleOrder/CargoItem/DamagesWrapper/Damage/DsInput/DsInputShortcut";
import BaseSelect from 'react-select';
import {getDamageSets, getDamageSetsById, getDamageSetShortcutById} from "../../../../utils/utils";
import ConditionalContent from "../../../../ConditionalContent";
import { array } from "prop-types";

const SuccessModal = withReactContent(Swal);

class EditDamageSetShortcut extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            name: '',
            shortcutId: this.props.match.params.entryId,
            token: Store.get('accessToken').token,
            dsPart: null,
            dsType: null,
            dsSeverity: null,
            dsCause: null,
            dsRepairMode: null,
            dsClass: null,
            dsDln: null,
            damageSetsArr: [],
            selectedDamageSetId: null,
            damageSetOptions: [],
            selectedDamageSetObject: null,
            selectedShortcut: null
        };

        
        this.deleteShortcut = this.deleteShortcut.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.fetchItems = this.fetchItems.bind(this);
        this.handleDamageSetChange = this.handleDamageSetChange.bind(this);
        this.updateDs = this.updateDs.bind(this);
    }

    async componentDidMount() {
        let displayName = '';
        let respDamageSetId = -1;

        await getDamageSetShortcutById(this.props.match.params.entryId).then(resp => {
            displayName = resp.name;
            respDamageSetId = resp.damage_set_id;
            this.setState({
                selectedShortcut: resp
            })
        });

        this.setState({
            name: displayName,
            selectedDamageSetId: respDamageSetId,
        });

        await getDamageSetsById(respDamageSetId).then(resp => {
            this.setState({
                selectedDamageSetId: respDamageSetId,
                damageSetOptions: resp.damageSetOptions
            })
        })

        // Get DamageSets
        let tempDamageSets = [];
        let tempSelectedDamageSet = null;
        getDamageSets()
        .then(resp => {
            for (const item of resp) {
                
                tempDamageSets.push({
                    'value': item.id,
                    'label': item.name
                });
            }

            for (const item of tempDamageSets) {
                if(item.value === this.state.selectedDamageSetId) {
                    this.setState({
                        selectedDamageSetObject: item
                    })
                }
            }
        })
        .then(() => {
            this.setState({
                damageSetsArr: tempDamageSets,
            });
        });    


        if (this.state.token) {
            this.fetchItems();
        }
        
        this.setState({
            selectedDamageSetObject: tempSelectedDamageSet
        })

        if(this.state.damageSetOptions !== null) {
            console.log("DAMAGE SET OPTIONS")
            if(this.state.selectedShortcut !== null) {
                console.log("DAMAGE SET SELECTED SHORTCUT")
                this.setState({
                    dsPart: this.state.selectedShortcut.ds_part !== 0 ? this.state.damageSetOptions.find(el => el.id === this.state.selectedShortcut.ds_part) : null,
                    dsType: this.state.selectedShortcut.ds_type !== 0 ? this.state.damageSetOptions.find(el => el.id === this.state.selectedShortcut.ds_type) : null,
                    dsSeverity: this.state.selectedShortcut.ds_severity !== 0 ? this.state.damageSetOptions.find(el => el.id === this.state.selectedShortcut.ds_severity) : null,
                    dsRepairMode: this.state.selectedShortcut.ds_repair_mode !== 0 ? this.state.damageSetOptions.find(el => el.id === this.state.selectedShortcut.ds_repair_mode) : null,
                    dsCause: this.state.selectedShortcut.ds_cause !== 0 ? this.state.damageSetOptions.find(el => el.id === this.state.selectedShortcut.ds_cause) : null,
                    dsClass: this.state.selectedShortcut.ds_class !== 0 ? this.state.damageSetOptions.find(el => el.id === this.state.selectedShortcut.ds_class) : null, 
                    dsDln: this.state.selectedShortcut.ds_dln !== 0 ? this.state.damageSetOptions.find(el => el.id === this.state.selectedShortcut.ds_dln) : null
                })
            }
        }
    }

    fetchItems() {
        /*Axios.get(process.env.REACT_APP_API_BASE + '/shortcuts', {
            headers: {
                'Authorization': 'Bearer ' + this.state.token
            },
        } ).then(response => {
            console.log(response);
            if (response.status === 200) {
                this.setState({
                    items: response.data.shortcuts
                })
            }
        }).catch(error => {
            console.log('error', error);
            if (error.status === 401) {
                console.log('attempting reauth');
            }
        });*/
    }


    handleSubmit(e) {
        e.preventDefault();

        console.log('NEKAJ');
        console.log(this.statedsCause);
        console.log('NEKAJ');

        let formData = {
            damage_set_id: this.state.selectedDamageSetId,
            name: this.state.name,
            ds_part: this.state.dsPart !== null ? this.state.dsPart.id : '',
            ds_type: this.state.dsType !== null ? this.state.dsType.id : '',
            ds_severity: this.state.dsSeverity !== null ? this.state.dsSeverity.id : '',
            ds_cause: this.state.dsCause !== null ? this.state.dsCause.id : '',
            ds_repair_mode: this.state.dsRepairMode !== null ? this.state.dsRepairMode.id : '',
            ds_class: this.state.dsClass !== null ? this.state.dsClass.id : '',
            ds_dln: this.state.dsDln !== null ? this.state.dsDln.id : '',
        };

        Axios.put(process.env.REACT_APP_API_BASE + '/shortcuts/' + this.state.shortcutId, formData, {
            headers: {
                'Authorization': 'Bearer ' + this.state.token
            }
        } )
            .then(response => {
                if (response.status === 200) {
                    SuccessModal.fire({
                        title: 'Successfully updated Damage Set',
                    }).then(() => {
                        this.props.history.push('/dashboard/damage-sets-shortcuts');
                    });
                }
            })
            .catch(error => {
                this.setState({
                    'formErrors': error.response.data.message
                })
            })
    }

    updateDs(name, value) {
        this.setState({
            [name]: value
        })
    }

    handleChange(e) {

        //Sets state with the appropriate key, to the matching input's value
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handleDamageSetChange(obj, e) {
        getDamageSetsById(obj.value)
        .then(resp => {
            this.setState({
                selectedDamageSetObject: obj,
                selectedDamageSetId: obj.value,
                damageSetOptions: resp.damageSetOptions
            })
        })
    }

    deleteShortcut(e) {
        e.preventDefault();
        console.log("DELETED")

        Axios.delete(process.env.REACT_APP_API_BASE + '/shortcuts/' + this.state.shortcutId, {
            headers: {
                'Authorization': 'Bearer ' + Store.get('accessToken').token
            }
        }).then(r => {
            SuccessModal.fire({
                'icon': 'success',
                'title': 'Successfully deleted shortcut'
            });

            this.props.history.push('/dashboard/damage-sets-shortcuts');
        });

    }

    render() {

        return (
            <div className="container" id="main">
                <div className="row mb-5">
                    <div className="col-12 col-md-8">
                        <Link to={"/dashboard/damage-sets-shortcuts/"} className={"btn-min"}>
                            <FontAwesomeIcon icon={faChevronLeft} />
                            <span className="ml-1">View all damage set shortcuts</span>
                        </Link>
                        <h1 className={"mt-1"}>Edit Damage Set Shortcut</h1>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-6 col-lg-6">
                        <label className="mb-2" htmlFor="name">Shortcut name</label>
                        <input type="text" className={"form-control"} name={"name"} id={"name"} value={this.state.name} onChange={this.handleChange}/>
                    </div>
                    <div className="col-6 col-lg-6">
                        <label className="mb-2">Damage set</label>
                        <div className="">
                        <BaseSelect
                                            closeMenuOnSelect={true}
                                            options={this.state.damageSetsArr}
                                            name="damageSetId"
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            value={this.state.selectedDamageSetObject}
                                            onChange={this.handleDamageSetChange}
                                        />
                        </div>
                    </div>
                    <label className="mt-2 mb-2">Damage set options</label>
                    <ConditionalContent check={this.state.selectedDamageSetObject !== null}>
                        <div className="layout-grid-7">

                            <div className="ds-item">
                                <DsInputShortcut existingValue={this.state.dsPart} type={"part"} label={"Part"} name={"dsPart"} updateParent={this.updateDs} damageSetOptions={this.state.damageSetOptions} />
                            </div>
                            <div className="ds-item">
                                <DsInputShortcut existingValue={this.state.dsType} type={"type"} label={"Type"} name={"dsType"} updateParent={this.updateDs} damageSetOptions={this.state.damageSetOptions}/>
                            </div>
                            <div className="ds-item">
                                <DsInputShortcut existingValue={this.state.dsSeverity} type={"severity"} label={"Severity"} name={"dsSeverity"} updateParent={this.updateDs} damageSetOptions={this.state.damageSetOptions}/>
                            </div>
                            <div className="ds-item">
                                <DsInputShortcut existingValue={this.state.dsCause} type={"cause"} label={"Cause"} name={"dsCause"} updateParent={this.updateDs} damageSetOptions={this.state.damageSetOptions}/>
                            </div>
                            <div className="ds-item">
                                <DsInputShortcut existingValue={this.state.dsRepairMode} type={"repair_mode"} label={"Repair Mode"} name={"dsRepairMode"} updateParent={this.updateDs} damageSetOptions={this.state.damageSetOptions}/>
                            </div>
                            <div className="ds-item">
                                <DsInputShortcut existingValue={this.state.dsClass} type={"class"} label={"Class"} name={"dsClass"} updateParent={this.updateDs} damageSetOptions={this.state.damageSetOptions}/>
                            </div>
                            <div className="ds-item">
                                <DsInputShortcut existingValue={this.state.dsDln} type={"dln"} label={"DLN"} name={"dsDln"} updateParent={this.updateDs} damageSetOptions={this.state.damageSetOptions}/>
                            </div>
                        </div>
                    </ConditionalContent>
                </div>     
                
                               

                <div className={"mt-4"}>
                    <button type={"submit"} onClick={this.handleSubmit} className={"btn btn-primary"}>Update shortcut</button>
                    <button onClick={(e) => this.deleteShortcut(e)}className={"btn btn-danger ms-2"}>Delete shortcut</button>
                </div>                    

            </div>
        );
    }
}

EditDamageSetShortcut.propTypes = {};

export default withRouter(EditDamageSetShortcut);
