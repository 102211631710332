import React from "react";
import {Link, withRouter} from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';


import Axios from "axios";
import Store from "store";




class AddNewInspector extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            'email': '',
            'name': '',
            'enabled': true,
            'password1':'',
            'password2':'',
            'role': 'Admin',
            'formErrors': "",
            token: Store.get('accessToken').token,
            file: []
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleFileInput = this.handleFileInput.bind(this);
    }


    handleFileInput(e) {
        this.setState({
            file: e.target.files[0]
        })
    }

    handleSubmit(e) {
        e.preventDefault();
        if (!this.state.email || !this.state.name || !this.state.password1 || !this.state.password2) {
            this.setState({
                'formErrors': 'Missing fields'
            });

            return null;
        }
        else if (this.state.password1 !== this.state.password2) {
            this.setState({
                'formErrors': 'Passwords don\'t match'
            });

            return null;
        }
        else {
            this.setState({
                'formErrors': ''
            });
        }

        var formatData = new FormData();
        formatData.append('email', this.state.email);
        formatData.append('enabled', true);
        formatData.append('name', this.state.name);
        formatData.append('password', this.state.password1);
        formatData.append('signature', this.state.file);
        

        Axios.post(process.env.REACT_APP_API_BASE + '/inspectors', formatData, {
            headers: {
                'Authorization': 'Bearer ' + this.state.token
            }
        } )
            .then(response => {

                if (response.status === 201) {
                    this.props.history.push('/dashboard/users');
                }
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    'formErrors': error.response.data.message
                })
            })
    }


    handleChange(e) {
        //Sets state with the appropriate key, to the matching input's value
        this.setState({
            [e.target.name]: e.target.value
        });
    }


    render() {
        return (
            <div className="container pb-10" id="main">
                <div className="row mb-5">
                    <div className="col-12 col-md-8">
                        <Link to={"/dashboard/users/"} className={"btn-min"}>
                            <FontAwesomeIcon icon={faChevronLeft} />
                            <span className="ml-1">View all inspectors</span>
                        </Link>
                        <h1 className={"mt-1"}>Add a new inspector</h1>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 col-md-8">
                        <form action="" className={"mb-5"} onSubmit={this.handleSubmit}>
                            <div className="form-group">
                                <label htmlFor="email">Email*</label>
                                <input type="email" placeholder={"ex.: janedoe@ares.com"} className={"form-control"} name={"email"} id={"email"} value={this.state.email} onChange={this.handleChange} required />
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group">
                                        <label htmlFor="name">Name*</label>
                                        <input type="text" placeholder={"John"} className={"form-control"} name={"name"} id={"name"} value={this.state.name} onChange={this.handleChange} required />
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="password1">Password*</label>
                                        <input type="password" className={"form-control"} name={"password1"} id={"password1"} value={this.state.password1} onChange={this.handleChange} required />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="password2">Password Confirmation*</label>
                                        <input type="password" className={"form-control"} name={"password2"} id={"password2"} value={this.state.password2} onChange={this.handleChange} required />
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-12">
                                    <div className="file-uploader">
                                        <label htmlFor="formFileMultiple" className="form-label">Signature</label>
                                        <input className="form-control" type="file" id="formFileMultiple" onChange={this.handleFileInput} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="errors">
                                        <h6 className={"mb-0"}>{this.state.formErrors}</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-12">
                                    <button type={"submit"} className={"btn btn-primary"}>Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

}

AddNewInspector.propTypes = {};

export default withRouter(AddNewInspector);
