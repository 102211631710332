import React from "react";
import {Link} from "react-router-dom";
import Makes from "./Makes/Makes";

class VinDecoder extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};

    }

    render() {
        return (
            <div className="container pb-10" id="main">
                <div className="row mb-5">
                    <div className="col-12 d-flex justify-content-between align-items-center">
                        <h1 className={"mt-1 mb-0"}>Vin Decoder</h1>
                        <Link to={"/dashboard/vin-decoder/makes/add-new"} className={"btn btn-primary"}>Add new</Link>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <Makes />
                    </div>
                </div>
            </div>
        );
    }
}

VinDecoder.propTypes = {};

export default VinDecoder;
