import React from "react";
import Select from "react-select";
import {deleteOrder, fetchItemsFromArr, getCargoItem, getInspector, isInspected} from "../../utils/utils";
import Swal from "sweetalert2";
import Store from "store";
import Axios from "axios";
import moment from "moment";
import withReactContent from "sweetalert2-react-content";
import DatePicker from "react-datepicker";

// const MarkInspected = withReactContent(Swal);
const PVReportSwal = withReactContent(Swal);

class PVReportController extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            sending_party: {
                "company": "Sending podjetje",
                "transport": "Sending lorry",
                "name": true,
                "signature": false
            },
            receiving_party: {
                "company": "Receiving podjetje",
                "transport": "Receiving lorry",
                "name": true,
                "signature": false
            },
            place_of_issue: "SIKOP",
            time: "16:00",
            from: {
                "factory": true,
                "road": true,
                "rail": true,
                "sea": true,
                "barge": true,
                "compound": true,
                "pdi": true,
                "dealer": true 
            },
            to: {
                "factory": true,
                "road": true,
                "rail": true,
                "sea": true,
                "barge": true,
                "compound": true,
                "pdi": true,
                "dealer": true 
            },
            location: "text",
            selectedDate: '',

        };

        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.calibroReport = this.calibroReport.bind(this);
        this.calibroReportModalContent = this.calibroReportModalContent.bind(this);
        this.setDate = this.setDate.bind(this);
        // this.addTransport = this.addTransport.bind(this);
        // this.addTransportModalContent = this.addTransportModalContent.bind(this);
        // this.createTransport = this.createTransport.bind(this);
    }

    handleSelectChange(obj, e) {

        this.setState({
            [e.name]: obj
        })
    }

    calibroReport() {
        return PVReportSwal.fire({
            title: "Create PV Report",
            html: this.calibroReportModalContent(),
            confirmButtonText: "Create report",
            showLoaderOnConfirm: true,
            preConfirm: async (inputValue) => {
                /*if(inputValue) {
                    let promises = [];
                    promises.push(() => this.createTransport(orderId));
                    return Promise.all(promises.map(func => func()));
                }*/
            }
        })
        .then(() => {
            //this.props.fetchCargoData();
        });
    }

    calibroReportModalContent() {
        return (
            <div className={"text-start"}>
                    <div class="row mb-2">
                        <h4>Sending party</h4>
                        <div className="col-12 col-md-6">
                            <label htmlFor="sending_company" className={"mb-1"}>Company</label>
                            <input type="text" name="pvrSendingCompany" id={"transportPlate1"} placeholder="First plate" className="vinSearch form-control w-100" itemID="plate1" value={this.state.pvrSendingCompany} onChange={this.handleChange} required></input>
                        </div>
                        <div className="col-12 col-md-6">
                            <label htmlFor="sending_company" className={"mb-1"}>Transport</label>
                            <input type="text" name="pvrSendingTransport" id={"transportPlate1"} placeholder="First plate" className="vinSearch form-control w-100" itemID="plate1" value={this.state.pvrSendingTransport} onChange={this.handleChange} required></input>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <h6>Name</h6>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="pvrSendingName" id="flexRadioDefault1" onChange={this.handleChange}/>Yes
                                </div>
                                <div className="form-check">
                                    <input  className="form-check-input" type="radio" name="pvrSendingName" id="flexRadioDefault2" onChange={this.handleChange}/>No
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <h6>Signature</h6>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="signature" id="flexRadioDefault1"/>Yes
                                </div>
                                <div className="form-check">
                                    <input  className="form-check-input" type="radio" name="signature" id="flexRadioDefault2"/>No
                                </div>
                            </div>
                        </div>         
                    </div>
                    <div class="row mb-2">
                        <h4>Receiving party</h4>
                        <div className="col-12 col-md-6">
                            <label htmlFor="sending_company" className={"mb-1"}>Company</label>
                            <input type="text" name="company" id={"transportPlate1"} placeholder="First plate" className="vinSearch form-control w-100" itemID="plate1" required></input>
                        </div>
                        <div className="col-12 col-md-6">
                            <label htmlFor="sending_company" className={"mb-1"}>Transport</label>
                            <input type="text" name="company" id={"transportPlate1"} placeholder="First plate" className="vinSearch form-control w-100" itemID="plate1" required></input>
                        </div>
                    </div>
                    <div class="row">
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <h6>Name</h6>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="receivingParty" id="flexRadioDefault1"/>Yes
                                </div>
                                <div className="form-check">
                                    <input  className="form-check-input" type="radio" name="receivingParty" id="flexRadioDefault2"/>No
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <h6>Signature</h6>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="signature" id="flexRadioDefault1"/>Yes
                                </div>
                                <div className="form-check">
                                    <input  className="form-check-input" type="radio" name="signature" id="flexRadioDefault2"/>No
                                </div>
                            </div>
                        </div>         
                    </div>
                    <div class="row mt-2">
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label htmlFor="arrival-date">Date</label>
                                <DatePicker className={'form-control'} dateFormat="dd.MM.yyyy"
                                selected={this.state.selectedDate && moment(this.state.selectedDate)}
                                utcOffset={0}
                                onChange={(date) => this.setDate(date)}/>
                            </div>
                        </div>
                    </div>
                </div>   
        );
    }

    setDate(date) {
        console.log(moment(date).format("DD.MM.YYYY HH:mm"))

        this.state.selectedDate = "djdjd";
        this.setState({
            selectedDate:  date,
            location: "hghfh"
        });

        console.log(this.state.date);
    }

    /*addTransport(orderId, transportType) {
        return AddTransport.fire({
            title: "Add transport",
            html: this.addTransportModalContent(transportType),
            showLoaderOnConfirm: true,
            preConfirm: async (inputValue) => {
                if(inputValue) {
                    let promises = [];
                    promises.push(() => this.createTransport(orderId));
                    return Promise.all(promises.map(func => func()));
                }
            }
        })
        .then(() => {
            this.props.fetchCargoData();
        });
    }*/

    /*addTransportModalContent(transportType) {
        return (
            <div className={"text-start"}>
                    <div className="row">
                        <div className="col-12 col-md-6">
                                <input type="text" name="transportPlate1" onChange={this.handleChange} id={"transportPlate1"} placeholder="First plate" className="vinSearch form-control w-100" itemID="plate1" required></input>
                            </div>
                            <div className="col-12 col-md-6">
                                <input type="text" name="transportPlate2" onChange={this.handleChange} placeholder="Second plate" id={"transportPlate2"} className="vinSearch form-control w-100" itemID="plate2" disabled={transportType == 'train'}></input>
                            </div>
                    </div>
                    
                </div>   
        );
    }*/

    /*createTransport(orderId) {
        if (!Store.get('accessToken').token) {
            throw new Error('Missing auth token');
        }

        if(this.state.transportPlate1 == '') {
            return Swal.showValidationMessage('First plate is required!');

        }

        return new Promise((resolve, reject) => {
            const data = {
                field_1: this.state.transportPlate1,
                field_2: this.state.transportPlate2,
                order_id: orderId
            };
            Axios.post(process.env.REACT_APP_API_BASE + '/transportmode', data, {
                headers: {
                    'Authorization': 'Bearer ' + Store.get('accessToken').token
                }
            }).then(r => {
                if (r.status === 201 || r.status === 200) {
                    return resolve();
                } else {
                    return reject('Something went wrong with the api call');
                }

            })
        });
    }

    inspectionModal(inspectors) {
        return (
            <div className={"text-start"}>
                <div className="form-group mb-3 text-start">
                    <label htmlFor="inspectionWeather" className={"mb-1"}>Weather</label>
                    <select name="inspectionWeather" required id={"inspectionWeather"} className={"form-select"} onChange={this.handleChange}>
                        <option value="" disabled selected>Select weather</option>
                        <option value="cloudy">Cloudy</option>
                        <option value="sunny">Sunny</option>
                        <option value="rain">Rain</option>
                        <option value="fog">Fog</option>
                        <option value="snow">Snow</option>
                        <option value="hail">Hail</option>
                    </select>
                </div>
                <div className="form-group text-start">
                    <label htmlFor="inspectionTemperature" className={"mb-1"}>Temperature (optional)</label>
                    <input type="number" name={"inspectionTemperature"} id={"inspectionTemperature"} className={"form-control"} onChange={this.handleChange}/>
                </div>

                <div className={"d-flex justify-content-start flex-column"}>
                    <label htmlFor="inspectorsArr" className="mb-1 text-start">Inspector</label>
                    <Select
                        closeMenuOnSelect={true}
                        options={inspectors}
                        onChange={this.handleSelectChange}
                        name={"inspectionInspector"}
                    />
                </div>
            </div>
        );
    }*/

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    render() {
        return null;
    }
}

PVReportController.propTypes = {};

export default PVReportController;
