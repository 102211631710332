import React from "react";
import {withRouter} from "react-router-dom";
import {getCargoItemByVin, getDamage} from "../../../utils/utils";
import moment from "moment";
import Event from "./Event/Event";

class History extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            vin: this.props.match.params.searchQuery,
            events: []
        };

        this.fetchItems = this.fetchItems.bind(this);
    }

    getDamages(damagesArr) {
        let promises = [];

        for (let item of damagesArr) {
            promises.push(() => getDamage(item));
        }

        return Promise.all(promises.map(func => func()));
    }

    fetchItems() {
        getCargoItemByVin(this.state.vin)
            .then(async (r) => {
                console.log(r);
                let eventsArr = [];

                for (let item of r) {

                    eventsArr.push({
                        date: item.createdOn,
                        user: item.createdBy,
                        type: 'created',
                        orderId: item.order_id,
                        id: item.id
                    })

                    if (item.inspection) {
                        eventsArr.push({
                            date: item.inspection.created_at,
                            user: item.inspection.inspected_by,
                            type: 'inspected',
                            orderId: item.order_id
                        })
                    }

                    if (item.damages) {
                        let damageData = await this.getDamages(item.damages);

                        if (damageData) {
                            for (let damage of damageData) {
                                console.log(damage.createdBy);
                                eventsArr.push({
                                    date: damage.inspectionDate,
                                    id: damage.id,
                                    type: 'damaged',
                                    orderId: item.order_id,
                                    user: damage.createdBy
                                });
                            }
                        }
                    }
                }

                const sortedArray  = eventsArr.sort((a,b) => new moment(a.date).format('YYYYMMDD') - new moment(b.date).format('YYYYMMDD'))

                this.setState({
                    events: sortedArray
                })
            });
    }

    componentDidMount() {
        this.fetchItems();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.state.vin !== this.props.match.params.searchQuery) {
            this.setState({
                vin: this.props.match.params.searchQuery
            }, () => {
                this.fetchItems();
            });
        }
    }

    render() {
        return (
            <div id="main">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h1>Cargo Item: {this.state.vin}</h1>
                            <div className="layout-grid-1 history-grid mt-5">
                                {this.state.events?.map(i => {
                                    return <Event data={i} vin={this.state.vin} />
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

History.propTypes = {};

export default withRouter(History);
