import React from "react";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import {withRouter} from "react-router-dom";
const AdvancedSearchModal = withReactContent(Swal);

class AdvancedSearch extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};

        this.openModal = this.openModal.bind(this);
    }

    openModal(e) {

        e?.preventDefault();

        AdvancedSearchModal.fire({
            title: 'Enter VINs',
            input: 'textarea',
            text: 'One per line!',
            confirmButtonText: 'Search'
        }).then(resp => {
            console.log(resp);
            if (resp.value) {
                let items = resp.value.split("\n");
                this.props.history.push({pathname:'/dashboard/ares-eye', state: {items}})
            }
        });

    }


    render() {
        return (
            <div className={"advancedSearchTrigger"}>
                <button className={"btn-min text-secondary"} onClick={this.openModal}>Ares Eye</button>
            </div>
        );
    }
}

AdvancedSearch.propTypes = {};

export default withRouter(AdvancedSearch);
