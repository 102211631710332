import React from "react";
import Store from "store";
import Axios from "axios";
import CargoItem from "../Orders/SingleOrder/CargoItem/CargoItem";
import {Card} from "react-bootstrap";
import HelperControls from "./HelperControls/HelperControls";
import {moveCargoItem} from "../../../utils/utils";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import Select from "react-select";
import ReactPaginate from "react-paginate";
import ConditionalContent from "../../../ConditionalContent";
import ReactPlaceholder from "react-placeholder";

const BulkActionsModal = withReactContent(Swal);

class Unallocated extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            items: [],
            token: Store.get('accessToken').token,
            selectedCargo: [],
            totalPages: 0,
            currentPage: 0,
            itemsLoaded: false,
            selectAllChecked: false,
            perPage: 15,
            vinFilterValue: ''
        };

        this.fetchItems = this.fetchItems.bind(this);
        this.selectCargo = this.selectCargo.bind(this);
        this.unselectCargo = this.unselectCargo.bind(this);
        this.selectAllCargo = this.selectAllCargo.bind(this);
        this.unselectAllCargo = this.unselectAllCargo.bind(this);
        this.bulkActionControl = this.bulkActionControl.bind(this);
        this.moveItems = this.moveItems.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.orderItems = this.orderItems.bind(this);
        this.fetchAllOrders = this.fetchAllOrders.bind(this);
        this.selectOrderModal = this.selectOrderModal.bind(this);
        this.handlePageClick = this.handlePageClick.bind(this);
        this.noItems = this.noItems.bind(this);
        this.vinChange = this.vinChange.bind(this);

    }

    selectOrderModal(items) {
        return (
            <div className={"select-order-modal-wrapper"}>
                <Select
                    closeMenuOnSelect={true}
                    options={items}
                    onChange={this.handleSelectChange}
                    name={"selectedOrder"}
                />
            </div>
        );
    }

    fetchAllOrders() {
        return new Promise((resolve, reject) => {
            let config = {};

            config.headers = {
                'Authorization': 'Bearer ' + this.state.token
            };
            Axios.get(process.env.REACT_APP_API_BASE + '/orders', config).then(response => {
                if (response.status === 200) {
                    let ordersArr = [];

                    for (let order of response.data.orders) {
                        ordersArr.push({
                            'value': order.id,
                            'label': order.idNumber
                        });
                    }

                    resolve(ordersArr);
                } else {
                    reject('Something went wrong');
                }
            });
        });
    }

    noItems(val) {
        console.log(val)
        this.setState({
            perPage: val,
            itemsLoaded: false
        }, () => {
            this.fetchItems();
        })

        
    }


    orderItems(val) {
        let order = null;
        let orderby = null;

        switch (val) {
            case "vin_asc":
                order = "asc";
                orderby = "vin";
                break;
            case "vin_desc":
                order = "desc";
                orderby = "vin";
                break;
            case "date_asc":
                order = "asc";
                orderby = "created_at";
                break;
            case "date_desc":
                order = "desc";
                orderby = "created_at";
                break;
            case "":
                order = null;
                orderby = null;
        }

        this.setState({
            order,
            orderby
        }, () => {
            this.fetchItems();
        });
    }

    moveItems(orderId) {
        let promises = [];

        for (let item of this.state.selectedCargo) {
            promises.push(() => moveCargoItem(item, orderId, null));
        }

        return Promise.all(promises.map(func => func()));
    }

    fetchItems(data, page) {
        let config = {};

        this.unselectAllCargo();

        config.headers = {
            'Authorization': 'Bearer ' + Store.get('accessToken').token,
        };

        config.params = {
            'order_id': false,
            'per_page': this.state.perPage,
        };

        if (this.state.vinFilterValue) {
            config.params['vin'] = this.state.vinFilterValue
        }

        //Sorting
        if (this.state.order) {
            config.params.order = this.state.order;
        }
        if (this.state.orderby) {
            config.params.orderby = this.state.orderby;
        }

        if (page) {
            config.params.page = page;
        }


        Axios.get(process.env.REACT_APP_API_BASE + '/items', config)
        .then(r => {
            console.log(r);
            this.setState({
                items: r.data.items,
                currentPage: ( this.state.perPage != -1 ? r.data.paginationData['current_page'] : 0) ,
                totalPages: (this.state.perPage != -1 ? r.data.paginationData['total_pages'] : 0),
                itemsLoaded: true
            })
        })
        .catch(err => {
            console.log(err);
        })
    }

    componentDidMount() {
        this.fetchItems();
    }


    selectCargo(item) {

        let itemId = parseInt(item);

        if (!this.state.selectedCargo.includes(itemId)) {
            this.setState({
                selectedCargo: [...this.state.selectedCargo, itemId]
            });
        }
    }

    vinChange(e) {

        let vin = e.target.value;

        if (vin.length <= 2) {
            this.setState({
                vinFilterValue: ''
            }, () => {
                this.fetchItems();
            })
        } else {
            this.setState({
                vinFilterValue: vin
            }, () => {
                this.fetchItems();
            })
        }
    }

    handlePageClick(event) {
        this.fetchItems(null, event.selected + 1);
    }

    unselectCargo(item) {

        let itemId = parseInt(item);

        if (this.state.selectedCargo.includes(itemId)) {
            this.setState({
                selectedCargo: this.state.selectedCargo.filter(item => item !== itemId)
            });
        }
    }

    selectAllCargo() {
        this.setState({
            selectAllChecked: true,
            selectedCargo: this.state.items?.map(item => item.id)
        })
    }

    unselectAllCargo() {
        this.setState({
            selectAllChecked: false,
            selectedCargo: []
        })
    }

    handleSelectChange(obj, e) {

        this.setState({
            [e.name]: obj
        })
    }

    bulkActionControl(action) {
        if (this.state.selectedCargo && this.state.selectedCargo.length > 0) {
            if (action === 'delete') {
                BulkActionsModal.fire({
                    title: 'Are you sure you want to delete?',
                    showCancelButton: true,
                }).then(r => {
                    if (r.isConfirmed) {
                        this.deleteItems()
                            .then(r => {
                                this.unselectAllCargo();
                                BulkActionsModal.fire({
                                    title: 'Successfully deleted cargo items',
                                    icon: 'success'
                                }).then(() => {
                                    this.updateOrder();
                                });
                            }).catch(err => {
                            BulkActionsModal.fire({
                                title: 'Something went wrong',
                                icon: 'error'
                            }).then(() => {
                                this.updateOrder();
                            });
                        });
                    }
                });
            } else if(action === 'move') {
                this.fetchAllOrders()
                    .then(r => {
                        return BulkActionsModal.fire({
                            title: 'Select order',
                            // input: 'select',
                            html: this.selectOrderModal(r),
                            showCancelButton: true,
                            // inputOptions: orders,
                            // inputPlaceholder: 'Select order',
                        })
                    })
                    .then(r => {
                        if (r.isConfirmed && this.state.selectedOrder) {
                            return this.moveItems(parseInt(this.state.selectedOrder.value));
                        } else {
                            this.setState({
                                selectedOrder: null
                            })
                            return false;
                        }
                    })
                    .then(r => {
                        if (r) {
                            this.fetchItems();
                            this.setState({
                                selectedOrder: null
                            })
                            return BulkActionsModal.fire({
                                title: 'Successfully moved cargo items',
                            });
                        }
                    })
                    .catch(err => {
                        console.log(err);
                    });
            }
        }
    }

    render() {
        return (
            <div className="" id="main">
                <div className="container mb-5">
                    <h1>Unallocated</h1>
                    <div className="row mt-3">
                        <div className="col-12">
                            <section>
                                <Card className="no-shadow">
                                    <Card.Body>
                                        <HelperControls
                                            orderItems={this.orderItems}
                                            selectAllCargo={this.selectAllCargo}
                                            unselectAllCargo={this.unselectAllCargo}
                                            bulkActionControl={this.bulkActionControl}
                                            selectAllChecked={this.state.selectAllChecked}
                                            noItems={this.noItems}
                                        />
                                    </Card.Body>
                                </Card>
                            </section>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-12">
                            <section>
                                <Card className="no-shadow">
                                    <Card.Body>
                                        <strong className={"d-block mb-3"}>Search by VIN</strong>
                                        <input type="text" placeholder="Enter vin" className="vinSearch form-control w-25" itemID="vinSearch" onChange={this.vinChange}></input>
                                    </Card.Body>
                                </Card>
                            </section>
                        </div>
                    </div>
                    <div className="row mt-3">
                    <ReactPlaceholder showLoadingAnimation rows={7} ready={this.state.itemsLoaded}>
                        <div className="col-12">
                            <div className="layout-grid-1 accordion">
                                {this.state.items?.map(item => <CargoItem
                                    selectedCargo={this.state.selectedCargo}
                                    selectCargo={this.selectCargo}
                                    unselectCargo={this.unselectCargo}
                                    key={'cargo-item-'+item.id}
                                    damageSetData={this.state.damageSetData}
                                    refreshItems={this.updateOrder}
                                    itemid={item.id}
                                    isOrder={false}
                                    orderid={this.state.id}
                                />)}
                            </div>
                        </div>
                        </ReactPlaceholder>
                    </div>
                    <ConditionalContent check={this.state.totalPages > 1}>
                    <div>
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <ReactPaginate
                                        breakLabel="..."
                                        nextLabel="next >"
                                        onPageChange={this.handlePageClick}
                                        pageRangeDisplayed={2}
                                        pageCount={this.state.totalPages}
                                        previousLabel="< previous"
                                        renderOnZeroPageCount={false}
                                        forcePage={this.state.currentPage - 1}
                                        containerClassName={'pagination-wrapper'}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </ConditionalContent>
                </div>
            </div>
        );
    }
}

Unallocated.propTypes = {};

export default Unallocated;
