import React from "react";
import {
    Link,
    withRouter
} from "react-router-dom";
import Store from "store";
import Axios from "axios";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft} from "@fortawesome/free-solid-svg-icons";

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
const SuccessModal = withReactContent(Swal);


class EditTransportCompany extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            id: this.props.match.params.entryId,
            token: Store.get('accessToken').token,
            name: null,
        };

        if (this.state.token && this.state.id) {
            Axios.get(process.env.REACT_APP_API_BASE + '/transportCompanies/'+this.state.id, {
                headers: {
                    'Authorization': 'Bearer ' + this.state.token
                },
            } ).then(response => {
                console.log(response);
                if (response.status === 200) {
                    this.setState({
                        name: response.data.name,
                    });
                }
            }).catch(error => {
                console.log('error', error);
            });
        }


        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(e) {
        e.preventDefault();


        let formData = {
            name: this.state.name,
        };


        console.log(formData);

        Axios.put(process.env.REACT_APP_API_BASE + '/transportCompanies/'+this.state.id, formData, {
            headers: {
                'Authorization': 'Bearer ' + this.state.token
            }
        } )
            .then(response => {
                console.log(response);
                if (response.status === 200) {
                    SuccessModal.fire({
                        title: 'Successfully updated transport company',
                    }).then(() => {
                        this.props.history.push('/dashboard/transport-companies');
                    });
                }
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    'formErrors': error.response.data.message
                })
            });
    }

    handleChange(e) {

        //Sets state with the appropriate key, to the matching input's value
        this.setState({
            [e.target.name]: e.target.value
        });
    }


    render() {

        return (
            <div className="container" id="main">
                <div className="row mb-5">
                    <div className="col-12 col-md-8">
                        <Link to={"/dashboard/transport-companies/"} className={"btn-min"}>
                            <FontAwesomeIcon icon={faChevronLeft} />
                            <span className="ms-1">View all Transport Companies</span>
                        </Link>
                        <h1 className={"mt-1"}>Editing Transport Company: {this.state.name ? this.state.name : null}</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-lg-7">
                        <div className=" no-shadow no-border">
                            <div className="row">
                                <div className="col-12">
                                    <form action="" className={""} onSubmit={this.handleSubmit}>

                                        <section className={"mb-5"}>
                                            <div className="row mb-3">
                                                <div className="col-12">
                                                    <label htmlFor="companyName">Transport company name</label>
                                                    <input type="text" className={"form-control"} name={"name"} id={"name"} onChange={this.handleChange} value={this.state.name} />
                                                </div>
                                            </div>
                                        </section>


                                        <div className="row mt-3">
                                            <div className="col-12">
                                                <button type={"submit"} className={"btn btn-primary"}>Submit</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

EditTransportCompany.propTypes = {};

export default withRouter(EditTransportCompany);
