import React from "react";
import Axios from "axios";
import Store from "store";


import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import CarModel from "./CarModel/CarModel";
const CodeModal = withReactContent(Swal);
const SuccessModal = withReactContent(Swal);

class CarModels extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            items: null,
            token: Store.get('accessToken').token
        };

        this.fetchModels = this.fetchModels.bind(this);
        this.renderItems = this.renderItems.bind(this);
        this.doAdd = this.doAdd.bind(this);
    }

    componentDidMount() {
        this.fetchModels();
    }

    fetchModels() {
        if (this.props.makeId) {

            let config = {};
            config.headers = {
                'Authorization': 'Bearer ' + this.state.token
            };

            config.params = {
                'make_id' : this.props.makeId
            }

            Axios.get(process.env.REACT_APP_API_BASE + '/carmodels', config).then(response => {
                console.log(response);
                if (response.status === 200) {
                    this.setState({
                        items: response.data.carModels
                    })
                }
            }).catch(error => {
                console.log('error', error);
                if (error.status === 401) {
                    console.log('attempting reauth');
                }
            });
        }
    }

    renderItems() {
        if (this.state.items) {
            return this.state.items.map((item, index) => {
                return <CarModel key={'model-'+index} item={item} makeId={this.props.makeId}/>;
            })
        }
    }

    doAdd() {
        CodeModal.fire({
            title: 'Add Car Model',
            input: 'text',
            inputAttributes: {
                'required': 'true'
            },
            inputValidator: (value) => {
                if (!value) {
                    return 'You need to enter a model name'
                }
            },
            inputPlaceholder: 'ex.: 3 Series',
            showCancelButton: true,
        }).then((value) => {
            console.log('this was selected', value);

            if (value.isConfirmed && value.value) {
                Axios.post(process.env.REACT_APP_API_BASE + '/carmodels', {
                    'make_id': this.props.makeId,
                    'name': value.value
                }, {
                    headers: {
                        'Authorization': 'Bearer ' + this.state.token
                    }
                } )
                    .then(() => {

                        this.fetchModels();

                        SuccessModal.fire({
                            title: 'Successfully added Car Model'
                        });
                    });
            }
        });
    }


    render() {
        return (
            <div>
                <div>
                    <h2>Models</h2>
                    <p>Lorem ipsum dolor sit amet. Some placeholder instructions.</p>
                </div>

                <div className={"layout-grid-1"}>
                    {this.renderItems()}
                </div>

                <div className="mt-3 text-end">
                    <button onClick={() => {
                        this.doAdd();
                    }} className={"btn btn-primary"}>Add new</button>
                </div>
            </div>
        );
    }
}

CarModels.propTypes = {};

export default CarModels;
