import React from "react";
import {Link} from "react-router-dom";


import Axios from "axios";
import Store from "store";
import Destination from "../Destinations/Destination/Destination";
import Mapping from "./Mapping/Mapping";


class Mappings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            token: Store.get('accessToken').token,
            mappings: []
        };
    }


    componentDidMount() {
        Axios.get(process.env.REACT_APP_API_BASE + '/mappings', {
            headers: {
                'Authorization': 'Bearer ' + this.state.token
            }
        })
            .then(res => {
                console.log(res);
                this.setState({
                    mappings: res.data
                })
            })
    }

    renderItems() {
        if (this.state.mappings) {
            return this.state.mappings.map((mapping, index) => {
                return (
                    <Mapping key={"k-" + index} entry={mapping} />
                );
            });
        }
    }

    render() {

        return (
            <div className="container" id="main">
                <div className="row mb-5">
                    <div className="col-12 d-flex justify-content-between align-items-center">
                        <h1 className={"mb-0"}>Mappings</h1>
                        
                        
                    </div>

                    <div className="col-12 d-flex justify-content-end align-items-center">
                        <Link to={"/dashboard/mapping/add-new"} className={"btn btn-primary me-2"}>Add new for Transport Type</Link>
                        <Link to={"/dashboard/mapping/clients/add-new"} className={"btn btn-primary"}>Add new for Client</Link>
                    </div>
                    
                    
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className=" no-shadow no-border">
                            <div className="layout-grid-4 small-gap">
                                {this.renderItems()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

Mappings.propTypes = {};

export default Mappings;
