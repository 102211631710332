import React from "react";
import Axios from "axios";
import Swal from "sweetalert2";


class PasswordReset extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            token: this.props.match.params.entryId,
            password: ""
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);


    }

    handleSubmit(e) {
        e.preventDefault();
        Axios.post(process.env.REACT_APP_API_BASE + '/password/reset', this.state)
            .then((response) => {
                if(response.status === 200) {
                    return Swal.fire({
                        icon: "success",
                        title: "Password reset link was sent to your email"
                    }).then(() => {
                        return this.props.history.push("/");
                    })
                }
            })
    }

    handleChange(e) {
        this.setState({
            password: e.target.value
        })
    }

    render() {
        return (
            <div className="home-bg d-flex align-items-center">

                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-4 mx-md-auto">
                            <div className="card">
                                <div className="card-body">
                                    <div>
                                        <h1 className="logo">Password reset</h1>
                                    </div>
                                    <div className="mt-5">
                                        <form action="" onSubmit={this.handleSubmit}>
                                            <div className="form-group">
                                                <label className={"form-label"} htmlFor="password">New password</label>
                                                <input type="password" className="form-control" name={"password"} id={"password"} value={this.state.password} onChange={this.handleChange} required/>
                                            </div>
                                            <div className="form-group">
                                                <label className={"form-label"} htmlFor="password">Repeat password</label>
                                                <input type="password" className="form-control" name={"password"} id={"password"} value={this.state.password} onChange={this.handleChange} required/>
                                            </div>

                                            <div className="d-flex align-items-center justify-content-between">
                                                <div>
                                                    <button type={"submit"} className={"btn btn-primary"}>Reset password</button>
                                                </div>

                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

PasswordReset.propTypes = {};

export default PasswordReset;
