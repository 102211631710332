import React from "react";
import {withRouter} from "react-router-dom";
import Store from "store";
import Axios from "axios";
import OrderResult from "./OrderResult/OrderResult";
import {Card} from "react-bootstrap";
import StockControls from "./StockControls/StockControls";
import {deleteCargoItem, getOrderItems, moveCargoItem} from "../../../utils/utils";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

const BulkActionsModal = withReactContent(Swal);

class Stock extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            items: [],
            orders: [],
            selectedCargo: [],
            selectAllChecked: false
        };

        this.fetchItems = this.fetchItems.bind(this);
        this.selectCargo = this.selectCargo.bind(this);
        this.unselectCargo = this.unselectCargo.bind(this);
        this.selectAllCargo = this.selectAllCargo.bind(this);
        this.unselectAllCargo = this.unselectAllCargo.bind(this);
        this.bulkActionControl = this.bulkActionControl.bind(this);
        this.moveItems = this.moveItems.bind(this);
    }

    componentDidMount() {
        this.fetchItems();
    }

    async getOrders(gateType) {

        return new Promise((resolve, reject) => {
            let config = {};

            config.headers = {
                'Authorization': 'Bearer ' + Store.get('accessToken').token
            };

            config.params = {};
            config.params['gate'] = gateType;

            Axios.get(process.env.REACT_APP_API_BASE + '/orders', config).then(response => {
                if (response.status === 200) {
                    resolve(response.data.orders);
                } else {
                    reject('Smt went wrong with fetching orders');
                }
            }).catch(err => {
                console.log(err);
            });

        });

    }

    getItems() {
        return new Promise(async (resolve, reject) => {
            let items = [];

            for (let orderId of this.state.orders) {
                const r = await this.getOrderItems(orderId.id);

                if (r && r.length > 0) {
                    for (let item of r) {
                        items.push(item);
                    }
                }
            }

            resolve(items);
        });
    }

    getOrderItems(orderId) {
        return new Promise((resolve, reject) => {
            let config = {};

            config.headers = {
                'Authorization': 'Bearer ' + Store.get('accessToken').token
            };

            config.params = {};
            config.params['order_id'] = orderId;

            Axios.get(process.env.REACT_APP_API_BASE + '/items', config).then(response => {
                if (response.status === 200) {
                    resolve(response.data.items);
                } else {
                    reject('Smt went wrong with fetching orders');
                }
            }).catch(err => {
                console.log(err);
            });

        });
    }

    selectCargo(item) {

        let itemId = parseInt(item);

        if (!this.state.selectedCargo.includes(itemId)) {
            this.setState({
                selectedCargo: [...this.state.selectedCargo, itemId]
            });
        }
    }

    unselectCargo(item) {

        let itemId = parseInt(item);

        if (this.state.selectedCargo.includes(itemId)) {
            this.setState({
                selectedCargo: this.state.selectedCargo.filter(item => item !== itemId)
            });
        }
    }

    selectAllCargo() {
        this.setState({
            selectAllChecked: true,
            selectedCargo: this.state.items?.map(item => item.id)
        })
    }

    unselectAllCargo() {
        this.setState({
            selectAllChecked: false,
            selectedCargo: []
        })
    }

    bulkActionControl(action) {
        if (this.state.selectedCargo && this.state.selectedCargo.length > 0) {
            if (action === 'delete') {
                BulkActionsModal.fire({
                    title: 'Are you sure you want to delete?',
                    showCancelButton: true,
                }).then(r => {
                    if (r.isConfirmed) {
                        this.deleteItems()
                            .then(r => {
                                this.unselectAllCargo();
                                BulkActionsModal.fire({
                                    title: 'Successfully deleted cargo items',
                                    icon: 'success'
                                }).then(() => {
                                    this.fetchItems();
                                });
                            }).catch(err => {
                            BulkActionsModal.fire({
                                title: 'Something went wrong',
                                icon: 'error'
                            }).then(() => {
                                this.fetchItems();
                            });
                        });
                    }
                });
            } else if(action === 'move') {
                const orders = this.fetchAllOrders();

                BulkActionsModal.fire({
                    title: 'Select order',
                    input: 'select',
                    showCancelButton: true,
                    inputOptions: orders,
                    inputPlaceholder: 'Select order',
                })
                    .then(r => {
                        if (r.isConfirmed && r.value) {
                            return this.moveItems(parseInt(r.value));
                        } else {
                            return false;
                        }
                    })
                    .then(r => {
                        if (r) {
                            this.fetchItems();
                            return BulkActionsModal.fire({
                                title: 'Successfully moved cargo items',
                            });
                        }
                    })
                    .catch(err => {
                        console.log(err);
                    });

            }
        }
    }

    moveItems(orderId) {
        let promises = [];

        for (let item of this.state.selectedCargo) {
            promises.push(() => moveCargoItem(item, orderId));
        }

        return Promise.all(promises.map(func => func()));
    }

    fetchAllOrders() {
        return new Promise((resolve, reject) => {
            let config = {};

            config.headers = {
                'Authorization': 'Bearer ' + Store.get('accessToken').token
            };
            Axios.get(process.env.REACT_APP_API_BASE + '/orders', config).then(response => {
                if (response.status === 200) {
                    let ordersArr = {};

                    for (let order of response.data.orders) {
                        ordersArr[order.id] = order.idNumber
                    }

                    resolve(ordersArr);
                } else {
                    reject('Something went wrong');
                }
            });
        });
    }

    //Trigger delete on all simultaniously
    deleteItems() {
        let promises = [];

        for (let item of this.state.selectedCargo) {
            promises.push(() => deleteCargoItem(item));
        }

        return Promise.all(promises.map(func => func()));
    }

    async fetchItems() {
        this.unselectAllCargo();
        const gateTypes = ['departure', 'arrival'];
        let orders = [];

        for (let gate of gateTypes) {
            const r = await this.getOrders(gate);

            if (r && r.length > 0) {
                for (let order of r) {
                    order.items = await getOrderItems(order.id);

                    orders.push(order);
                }
            }
        }

        this.setState({
            orders
        }, () => {
            this.getItems().then(items => {
                this.setState({
                    items
                })
            })
        });
    }


    render() {
        return (
            <div className="" id="main">
                <div className="container mb-5">
                    <div className="row">
                        <div className="col-12">
                            <h1>Stock</h1>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <section>
                                <Card className="no-shadow">
                                    <Card.Body>
                                        <StockControls
                                            selectAllCargo={this.selectAllCargo}
                                            unselectAllCargo={this.unselectAllCargo}
                                            bulkActionControl={this.bulkActionControl}
                                            selectAllChecked={this.state.selectAllChecked}
                                        />
                                    </Card.Body>
                                </Card>
                            </section>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-12 layout-grid-1 grouped-orders-wrapper">
                            {this.state.orders?.filter(order => order.items && order.items.length > 0).map(order => {
                                return <OrderResult
                                    orderObject={order}
                                    id={order.id}
                                    items={order.items}
                                    selectedCargo={this.state.selectedCargo}
                                    selectCargo={this.selectCargo}
                                    unselectCargo={this.unselectCargo}
                                />
                            })}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

Stock.propTypes = {};

export default withRouter(Stock);
