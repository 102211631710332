import React from "react";
import Axios from "axios";
import {fetchItemsFromArr, getInspector} from "../../../../utils/utils";
import CargoItem from "../../Orders/SingleOrder/CargoItem/CargoItem";
import Store from "store";

class OrderResult extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};

        this.fetchOrderData = this.fetchOrderData.bind(this);
    }

    componentDidMount() {
        if (this.props.orderObject) {
            this.setState({
                data: this.props.orderObject,
                orderIdNumber: this.props.orderObject.idNumber
            });
        } else {
            this.fetchOrderData();
        }
    }

    fetchOrderData() {
        let config = {};

        config.headers = {
            'Authorization': 'Bearer ' + Store.get('accessToken').token
        };

        Axios.get(process.env.REACT_APP_API_BASE + '/orders/'+this.props.id, config).then(response => {

            if (response.status === 200) {

                this.setState({
                    data: response.data.order,
                    orderIdNumber: response.data.order.idNumber
                });
            } else {
                throw new Error('Smt went wrong with fetching orders');
            }
        })
            .catch(err => {
                console.log(err);
            });

    }

    render() {
        return (
            <div className={"order-group"}>
                <h2>Order: {this.state.orderIdNumber}</h2>
                <div className="layout-grid-1">
                    {this.props.items?.map(item => {
                        return <CargoItem
                            key={'cargo-item-'+item.id}
                            itemid={item.id}
                            orderid={this.props.id}
                            selectedCargo={this.props.selectedCargo}
                            selectCargo={this.props.selectCargo}
                            unselectCargo={this.props.unselectCargo}
                            hideBulk={this.props.hideBulk}
                        />
                    })}
                </div>
            </div>
        );
    }
}

OrderResult.propTypes = {};

export default OrderResult;
