import React from "react";
import Store from "store";
import Axios from "axios";
import {
    getCargoItem,
    getPorts,
    getTransport,
    getTransportCompanies,
    getTransports
} from "../../../../../utils/utils";
import {Link} from "react-router-dom";
import Select from "react-select";

import countries from "../../../../../fixedData/countries.json";

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import {vinValidation} from "../../../../../utils/VinValidation";
import {FileUploader} from "../../../../../FileUploader/FileUploader";
const SuccessModal = withReactContent(Swal);
const ErrModal = withReactContent(Swal);

class EditCargoItem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            token: Store.get('accessToken').token,
            orderId: this.props.match.params.entryId,
            id: this.props.match.params.itemId,
            vin: '',
            weather: '',
            portOfOriginArr: null,
            portOfOrigin: null,
            transshipmentPort: null,
            portOfDischarging: null,
            destination: null,
            transportArr: null,
            transport: null,
            bl: null,
            info: null,
            other: null,
            voyage: null,
            transportCompany: null,
            transportCompaniesArr: []
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.countriesSelect = this.countriesSelect.bind(this);
        this.setSelectedFile = this.setSelectedFile.bind(this);
    }


    handleSubmit(e) {
        e.preventDefault();

        if (vinValidation[this.state.cargoType] && !vinValidation[this.state.cargoType](this.state.vin)) {
            return ErrModal.fire({
                icon: "error",
                title: "VIN validation failed"
            });
        }

        let data = {
            'vin': this.state.vin,
            'portOfOrigin': this.state.portOfOrigin ? this.state.portOfOrigin.value : null,
            'transshipmentPort': this.state.transshipmentPort ? this.state.transshipmentPort.value : null,
            'portOfDischarging': this.state.portOfDischarging ? this.state.portOfDischarging.value : null,
            'destination': this.state.destination,
            'transport': this.state.transport ? this.state.transport.value : null,
            'bl': this.state.bl,
            'info': this.state.info,
            'other': this.state.other,
            'voyage': this.state.voyage,
            'transportCompany': this.state.transportCompany ? this.state.transportCompany.value : null,
        };

        Axios.put(process.env.REACT_APP_API_BASE + '/items/'+this.state.id, data, {
            headers: {
                'Authorization': 'Bearer ' + this.state.token
            }
        } )
            .then(response => {
                console.log(response);
                if (response.status === 200) {
                    SuccessModal.fire({
                        title: 'Successfully updated cargo item',
                    }).then(() => {
                        this.props.history.push('/dashboard/orders/order/' + this.state.orderId);
                    });
                }
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    'formErrors': error.response.data.message
                })
            })
    }

    handleChange(e) {
        if(e.target.name === 'destination') {
            console.log('what the hell!!!???');
        }
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handleSelectChange(obj, e) {

        this.setState({
            [e.name]: obj
        })
    }

    setSelectedFile(name, file) {
        this.setState({
            [name]: file
        })
    }

    componentDidMount() {

        document.addEventListener('keydown', this.handleKeyPress);

        getCargoItem(this.state.id).then(resp => {
            console.log(resp);
            this.setState({
                bl: resp.bl,
                vin: resp.vin,
                voyage: resp.voyage,
                info: resp.info,
                other: resp.other,
                destination: resp.destination,
                portOfOrigin: {
                    value: resp.portOfOrigin ? resp.portOfOrigin.id : null,
                    label: resp.portOfOrigin ? resp.portOfOrigin.name : null
                },
                transshipmentPort: {
                    value: resp.transshipmentPort ? resp.transshipmentPort.id : null,
                    label: resp.transshipmentPort ? resp.transshipmentPort.name : null
                },
                portOfDischarging: {
                    value: resp.portOfDischarging ? resp.portOfDischarging.id : null,
                    label: resp.portOfDischarging ? resp.portOfDischarging.name : null
                },
                transportCompany: {
                    value: resp.transportCompany ? resp.transportCompany.id : null,
                    label: resp.transportCompany ? resp.transportCompany.name : null
                },
            });

            if (resp.transport) {
                getTransport(resp.transport)
                    .then(r => {
                        if (r) {
                            this.setState({
                                transport:{
                                    value: r.id,
                                    label: r.identificationName + ' (' + r.transportDetailsId + ')'
                                }
                            })
                        }
                    })
            }
        });

        let tempPorts = [];
        getPorts().then(resp => {
            for (const item of resp) {
                tempPorts.push({
                    'value': item.id,
                    'label': item.name + ' (' + item.code + ')'
                });
            }
        })
        .then(() => {
            this.setState({
                'portOfOriginArr': tempPorts
            });
        });

        let tempTransports = [];
        getTransports().then(resp => {
            for (const item of resp) {
                tempTransports.push({
                    'value': item.id,
                    'label': item.identificationName + ' (' + item.transportDetailsId + ')'
                });
            }
        }).then(() => {
            this.setState({
                'transportsArr': tempTransports
            });
        });

        let tempTransportCompanies = [];
        getTransportCompanies().then(resp => {
            for (const item of resp) {
                tempTransportCompanies.push({
                    'value': item.id,
                    'label': item.name
                });
            }
        }).then(() => {
            this.setState({
                'transportCompaniesArr': tempTransportCompanies
            });
        });

    }


    countriesSelect() {
        if (countries) {
            return (
                <select className={"form-select"} name={"destination"} id={"destination"} value={this.state.destination} onChange={this.handleChange}>
                    <option>Select Destination</option>
                    {countries.map((item, index) => {
                        return (
                            <option value={item.code}>{item.name}</option>
                        );
                    })}
                </select>
            )
        }
    }



    render() {
        return (
            <div className="container pb-10" id="main">
                <div className="row mb-5">
                    <div className="col-12 col-md-8">
                        <Link to={"/dashboard/orders/order/"+this.state.orderId} className={"btn-min"}>
                            <span className="ml-1">Back to order</span>
                        </Link>
                        <h1 className={"mt-1"}>Editing Cargo Item #{this.state.id}</h1>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 col-md-8">
                        <form action="" className={"mb-5"} onSubmit={this.handleSubmit}>

                            <div className="row mb-3">
                                <div className="col-12">
                                    <div className="form-group">
                                        <label htmlFor="vin">VIN*</label>
                                        <input
                                            type={"text"}
                                            className={"form-control"}
                                            onChange={this.handleChange}
                                            value={this.state.vin}
                                            name={"vin"}
                                            required
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row mb-3">
                                <div className="col-12 col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="portOfOrigin">Port of Origin</label>
                                        <Select
                                            closeMenuOnSelect={true}
                                            options={this.state.portOfOriginArr}
                                            onChange={this.handleSelectChange}
                                            name={"portOfOrigin"}
                                            value={this.state.portOfOrigin}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="transshipmentPort">Transshipment Port</label>
                                        <Select
                                            closeMenuOnSelect={true}
                                            options={this.state.portOfOriginArr}
                                            onChange={this.handleSelectChange}
                                            name={"transshipmentPort"}
                                            value={this.state.transshipmentPort}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="portOfDischarging">Port of Discharging</label>
                                        <Select
                                            closeMenuOnSelect={true}
                                            options={this.state.portOfOriginArr}
                                            onChange={this.handleSelectChange}
                                            name={"portOfDischarging"}
                                            value={this.state.portOfDischarging}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row mb-3">
                                <div className="col-12 col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="destination">Destination</label>
                                        {this.countriesSelect()}
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="destination">BL</label>
                                        <input
                                            type={"text"}
                                            placeholder={"Enter BL"}
                                            className={"form-control"}
                                            onChange={this.handleChange}
                                            value={this.state.bl}
                                            name={"bl"}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="voyage">Voyage</label>
                                        <input
                                            type={"text"}
                                            placeholder={"Enter Voyage"}
                                            className={"form-control"}
                                            onChange={this.handleChange}
                                            value={this.state.voyage}
                                            name={"voyage"}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="transport">Transport</label>
                                        <Select
                                            closeMenuOnSelect={true}
                                            options={this.state.transportsArr}
                                            onChange={this.handleSelectChange}
                                            name={"transport"}
                                            value={this.state.transport}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="transport">Transport Company</label>
                                        <Select
                                            closeMenuOnSelect={true}
                                            options={this.state.transportCompaniesArr}
                                            onChange={this.handleSelectChange}
                                            name={"transportCompany"}
                                            value={this.state.transportCompany}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row mb-3">
                                <div className="form-group">
                                    <label htmlFor="info">Additional Info</label>
                                    <textarea name="info" id="info" className={"form-control"} value={this.state.info} onChange={this.handleChange} />
                                </div>
                            </div>

                            <div className="row mb-3">
                                <div className="form-group">
                                    <label htmlFor="other">Other</label>
                                    <textarea name="other" id="other" className={"form-control"} value={this.state.other} onChange={this.handleChange} />
                                </div>
                            </div>

                            {/*<div className="row mt-3">
                                <div className="col-12">
                                    <FileUploader
                                        onFileSelect={(file) => this.setSelectedFile('generalImages', file)}
                                        fieldLabel={"Upload general images"}
                                        onFileSelectError={({ error }) => alert(error)}
                                    />
                                </div>
                            </div>

                            <div className="row mt-3">
                                <div className="col-12">
                                    <FileUploader
                                        onFileSelect={(file) => this.setSelectedFile('documents', file)}
                                        fieldLabel={"Upload documents"}
                                        onFileSelectError={({ error }) => alert(error)}
                                    />
                                </div>
                            </div>*/}

                            <div className="row mt-3">
                                <div className="col-12">
                                    <button type={"submit"} className={"btn btn-primary"}>Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

EditCargoItem.propTypes = {};

export default EditCargoItem;
