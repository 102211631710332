import React from "react";
import {displayDateTime, getUser} from "../../../../../utils/utils";
import {Link} from "react-router-dom";
import Store from "store";
import axios from "axios";

class Inspected extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            token: Store.get('accessToken').token,
            user: this.props.data.user,
            userName: null,
            orderId: ''
        };

        this.fetchUser = this.fetchUser.bind(this);
    }


    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.state.user !== this.props.data.user) {
            this.setState({
                user: this.props.data.user
            }, () => {
                this.fetchUser();
            });
        }
    }

    fetchUser() {
        if (this.state.user) {
            getUser(this.state.user, 'name')
                .then(r => {
                    this.setState({userName: r})
                })
        }
    }

    componentDidMount() {
        axios.get(process.env.REACT_APP_API_BASE + '/vinLastOrder', {
            params: {
                'vin': this.props.vin
            },
            headers: {
                'Authorization': 'Bearer ' + this.state.token
            }
        })
        .then(res => {
            console.log(res);
            this.setState({
                orderId: res.data.id
            })
        })
        this.fetchUser();
    }

    render() {
        return (
            <div className={"card no-shadow"}>
                <div className="card-header">
                    <strong>{displayDateTime(this.props.data.date)}</strong> - Cargo Item inspected by {this.state.userName || '/'} in order <Link className={"btn-min"} to={"/dashboard/orders/order/"+this.state.orderId}>#{this.state.orderId}</Link>
                </div>
            </div>
        );
    }
}

Inspected.propTypes = {};

export default Inspected;
