import React from "react";
import {displayDateTime, getDamage, getUser} from "../../../../../utils/utils";

import Style from "./Damaged.module.scss";
import {Link} from "react-router-dom";
import DamageDetails from "./DamageDetails/DamageDetails";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faChevronUp} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import Store from "store";

class Damaged extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            token: Store.get('accessToken').token,  
            loaded: false,
            open: false,
            data: null,
            user: this.props.data.user,
            userName: null,
            orderId: ''
        };

        this.showDetails = this.showDetails.bind(this);
        this.fetchUser = this.fetchUser.bind(this);
    }



    componentDidMount() {
        axios.get(process.env.REACT_APP_API_BASE + '/vinLastOrder', {
            params: {
                'vin': this.props.vin
            },
            headers: {
                'Authorization': 'Bearer ' + this.state.token
            }
        })
        .then(res => {
            console.log(res);
            this.setState({
                orderId: res.data.id
            })
        })
        this.fetchUser();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.state.user !== this.props.data.user) {
            this.setState({
                user: this.props.data.user
            }, () => {
                this.fetchUser();
            });
        }
    }

    fetchUser() {
        if (this.state.user) {
            getUser(this.state.user, 'name')
                .then(r => {
                    this.setState({userName: r})
                })
        }
    }

    showDetails(e) {
        e?.preventDefault();

        if (!this.state.loaded && this.props.data.id) {
            getDamage(this.props.data.id)
                .then(data => {
                    this.setState({
                        data,
                        loaded: true,
                        open: !this.state.open
                    });
                });
        }
        else {
            this.setState({
                open: !this.state.open
            })
        }
    }

    render() {
        return (
            <div className={[Style.damage, 'card no-shadow'].join(' ')}>
                <div className="card-header d-flex justify-content-between align-items-center">
                    <div>
                        <strong>{displayDateTime(this.props.data.date)}</strong> - Damage #{this.props.data.id} added by {this.state.userName || '/'} in order <Link className={"btn-min"} to={"/dashboard/orders/order/"+this.state.orderId}>#{this.state.orderId}</Link>
                    </div>
                    <button className="btn-min ms-2 p-1" onClick={this.showDetails}>
                        <FontAwesomeIcon icon={this.state.open ? faChevronUp : faChevronDown} />
                    </button>
                </div>
                <div className={['card-body', Style.damageData, this.state.open ? Style.open : null].join(' ')}>
                    <DamageDetails data={this.state.data}/>
                </div>
            </div>
        );
    }
}

Damaged.propTypes = {};

export default Damaged;
