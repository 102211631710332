import React from "react";
import {
    Link,
    withRouter
} from "react-router-dom";
import Store from "store";
import Axios from "axios";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft} from "@fortawesome/free-solid-svg-icons";

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
const SuccessModal = withReactContent(Swal);


class EditTransport extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            id: this.props.match.params.entryId,
            token: Store.get('accessToken').token,
            identificationName: null,
            owner: null,
            transportCompany: null,
            transportDetailsCharterer: null,
            transportDetailsDescription: null,
            transportDetailsId: null,
            transportDetailsRegistrationNumber: null,
            transportMode: null,
            transportType: null,
            transportCompanies: null
        };

        if (this.state.token && this.state.id) {
            Axios.get(process.env.REACT_APP_API_BASE + '/transports/'+this.state.id, {
                headers: {
                    'Authorization': 'Bearer ' + this.state.token
                },
            } ).then(response => {
                console.log(response);
                if (response.status === 200) {
                    this.setState({
                        identificationName: response.data.transport.identificationName,
                        owner: response.data.transport.owner,
                        transportCompany: response.data.transport.transportCompany.id,
                        transportDetailsCharterer: response.data.transport.transportDetailsCharterer,
                        transportDetailsDescription: response.data.transport.transportDetailsDescription,
                        transportDetailsId: response.data.transport.transportDetailsId,
                        transportDetailsRegistrationNumber: response.data.transport.transportDetailsRegistrationNumber,
                        transportMode: response.data.transport.transportMode,
                        transportType: response.data.transport.transportType
                    });
                }
            }).catch(error => {
                console.log('error', error);
            });


            Axios.get(process.env.REACT_APP_API_BASE + '/transportCompanies', {
                headers: {
                    'Authorization': 'Bearer ' + this.state.token
                },
            } ).then(response => {
                console.log(response);
                if (response.status === 200) {
                    this.setState({
                        transportCompanies: response.data
                    });
                }
            }).catch(error => {
                console.log('error', error);
            });
        }


        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.renderTransportCompanySelect = this.renderTransportCompanySelect.bind(this);
    }

    handleSubmit(e) {
        e.preventDefault();

        let formData = {
            identificationName: this.state.identificationName,
            transportCompany: this.state.transportCompany ? parseInt(this.state.transportCompany) : null,
            transportMode: this.state.transportMode,
            transportType: this.state.transportType
        };



        console.log(formData);

        // return;

        Axios.put(process.env.REACT_APP_API_BASE + '/transports/'+this.state.id, formData, {
            headers: {
                'Authorization': 'Bearer ' + this.state.token
            }
        } )
            .then(response => {
                console.log(response);
                if (response.status === 200) {
                    SuccessModal.fire({
                        title: 'Successfully updated transport',
                    }).then(() => {
                        this.props.history.push('/dashboard/transports');
                    });
                }
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    'formErrors': error.response.data.message
                })
            })
    }



    handleChange(e) {

        //Sets state with the appropriate key, to the matching input's value
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    renderTransportCompanySelect() {

        if (this.state.transportCompanies && this.state.transportCompanies.length > 0) {
            console.log(this.state.transportCompanies);
            console.log(this.state.transportCompany);
            return (
                <select className={"form-select"} name={"transportCompany"} id={"transportCompany"} value={this.state.transportCompany} onChange={this.handleChange}>
                    {this.state.transportCompanies.map((item, index) => {
                        return (
                            <option value={item.id}>{item.name}</option>
                        );
                    })}
                </select>
            );
        }
    }



    render() {

        return (
            <div className="container" id="main">
                <div className="row mb-5">
                    <div className="col-12 col-md-8">
                        <Link to={"/dashboard/transports/"} className={"btn-min"}>
                            <FontAwesomeIcon icon={faChevronLeft} />
                            <span className="ms-1">View all Transports</span>
                        </Link>
                        <h1 className={"mt-1"}>Editing Transport: {this.state.identificationName ? this.state.identificationName : null}</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-lg-7">
                        <div className=" no-shadow no-border">
                            <div className="row">
                                <div className="col-12">
                                    <form action="" className={"mb-5"} onSubmit={this.handleSubmit}>

                                        <section className={"mb-5"}>
                                            <div className="row mb-3">
                                                <div className="col-12 col-md-6">
                                                    <label htmlFor="identificationName">Identification Name</label>
                                                    <input type="text" className={"form-control"} name={"identificationName"} id={"identificationName"} onChange={this.handleChange} value={this.state.identificationName} />
                                                </div>
                                                <div className="col-12 col-md-6">
                                                    <label htmlFor="transportCompany">Transport Company</label>
                                                    {this.renderTransportCompanySelect()}
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col-12 col-md-6">
                                                    <label htmlFor="Transport mode">Transport mode</label>
                                                    <select className={"form-select"} name={"transportMode"} id={"transportMode"} value={this.state.transportMode} required onChange={this.handleChange}>
                                                        <option value="" selected disabled>Select transport mode</option>
                                                        <option value="Aircraft">Aircraft</option>
                                                        <option value="Barge">Barge</option>
                                                        <option value="Container">Container</option>
                                                        <option value="Shunting">Shunting</option>
                                                        <option value="Train">Train</option>
                                                        <option value="Truck">Truck</option>
                                                        <option value="Vessel">Vessel</option>
                                                    </select>
                                                </div>
                                                <div className="col-12 col-md-6">
                                                    <label htmlFor="transportType">Transport type</label>
                                                    <select className={"form-select"} name={"transportType"} id={"transportType"} value={this.state.transportType} required onChange={this.handleChange}>
                                                        <option value="" selected disabled>Select transport type</option>
                                                        <option value="General cargo">General cargo</option>
                                                        <option value="Ocean Ship">Ocean Ship</option>
                                                        <option value="Ocean Vessel">Ocean Vessel</option>
                                                        <option value="RO-RO">RO-RO</option>
                                                        <option value="Truck">Truck</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </section>


                                        <div className="row">
                                            <div className="col-12">
                                                <div className="errors">
                                                    <h6 className={"mb-0"}>{this.state.formErrors}</h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-12">
                                                <button type={"submit"} className={"btn btn-primary"}>Submit</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

EditTransport.propTypes = {};

export default withRouter(EditTransport);
