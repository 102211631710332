import React from 'react'

export const FileUploader = ({onFileSelect, fieldLabel = 'Upload images'}) => {

    const handleFileInput = (e) => {
        // handle validations
        onFileSelect(e.target.files)
    };

    return (
        <div className="file-uploader">
            <label htmlFor="formFileMultiple" className="form-label">{fieldLabel}</label>
            <input className="form-control" type="file" id="formFileMultiple" multiple onChange={handleFileInput} />
        </div>

    )
};


