import React from "react";
import Damaged from "./Damaged/Damaged";
import Inspected from "./Inspected/Inspected";
import Created from "./Created/Created";

const components = {
    damaged: Damaged,
    inspected: Inspected,
    created: Created
};

class Event extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};

    }

    render() {

        if (this.props.data.type) {
            const TypeComponent = components[this.props.data.type];

            return <TypeComponent {...this.props} />;
        } else {
            return null;
        }

    }
}

export default Event;
