import React from "react";
import {fetchItemsFromArr, getCargoItemByVin} from "../../../utils/utils";
import ConditionalContent from "../../../ConditionalContent";
import Orders from "./Orders/Orders";
import {Link} from "react-router-dom";

class AresEye extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            searchQuery: this.props.history.location.state.items,
            items: [],
            orders: [],
            itemConstructObj: {}
        };

        this.fetchItems = this.fetchItems.bind(this);
    }

    componentDidMount() {
        this.fetchItems();
    }


    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.state.searchQuery !== this.props.history.location.state.items) {
            this.setState({
                searchQuery: this.props.history.location.state.items
            }, () => {
                this.fetchItems();
            });
        }
    }

    fetchItems() {

        fetchItemsFromArr(this.state.searchQuery, getCargoItemByVin)
            .then(r => {
                let items = [],
                    orders = [],
                    itemConstructObj = {};

                for (let itemArr of r) {
                    for (let item of itemArr) {
                        if (!itemConstructObj[item.vin]) {
                            itemConstructObj[item.vin] = [];
                        }
                        itemConstructObj[item.vin].push(item.order_id);
                        items.push(item);
                        orders.push(item.order_id);
                    }
                }

                for (let item in itemConstructObj) {
                    itemConstructObj[item] = [...new Set(itemConstructObj[item])];
                }


                this.setState({
                    items,
                    orders,
                    itemConstructObj
                });

            })

    }



    render() {
        return (
            <div className="" id="main">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <ConditionalContent check={this.state.itemConstructObj} className={"layout-grid-1 grouped-orders-wrapper"}>
                                {
                                    Object.keys(this.state.itemConstructObj).map((item, i) => {
                                        console.log(item);
                                        return (
                                            <section className="cargo-item-group" key={i}>
                                                <div className="d-flex align-items-center mb-3">
                                                    <h4 className={"mb-0"}>{item}</h4>
                                                    <Link to={'/dashboard/history/'+item} className="btn-min text-secondary ms-3">View Cargo Item History</Link>
                                                </div>
                                                <Orders orders={this.state.itemConstructObj[item]} />
                                            </section>
                                        )
                                    })
                                }
                            </ConditionalContent>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

AresEye.propTypes = {};

export default AresEye;
