import React from "react";
import Admin from "../../Admins/Admin/Admin";
import Accountant from "../../Accountants/Accountant/Accountant";
import Inspector from "../../Inspectors/Inspector/Inspector";
import Manager from "../../Managers/Manager/Manager";

class Users extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    renderItems() {
        return this.props.items.map(item => {
            switch(item.role) {
                case 'admin':
                    return <Admin entry={item} />
                    break;
                case 'accounting':
                    return <Accountant entry={item} />
                    break;
                case 'inspector':
                    return <Inspector entry={item} />
                    break;
                case 'manager':
                    return <Manager entry={item} />
                    break;
            }
        })
    }

    render() {

        return (
            <div className={"layout-grid-3"}>
                {this.renderItems()}
            </div>
        );
    }
}

Users.propTypes = {};

export default Users;
