import React from "react";
import {Link} from "react-router-dom";
import Style from "./Client.module.scss";
import moment from "moment";

class Client extends React.Component {

    constructor(props) {
        super(props);

        this.state = {};
        this.displayDate = this.displayDate.bind(this);
    }

    displayDate(time) {
        if (!time) return false;

        return moment(time).format('MM DD YYYY');
    }

    render() {
        return (
            <Link className={Style.item + ' card no-shadow'} to={"/dashboard/clients/edit/"+this.props.entry.id}>
                <div className="card-header border-0">
                    <div className="meta-wrapper d-flex flex-column">
                        <span className=" d-block mb-1">ID: {this.props.entry.id}</span>
                        <span className=" d-block mb-1">Email: {this.props.entry.email}</span>
                        <span className="strong d-block mb-1">Company Name: {this.props.entry.companyName}</span>
                        <span className=" d-block mb-1">Country: {this.props.entry.country}</span>
                        <span className=" d-block mb-1">Email: {this.props.entry.email}</span>
                        <span className=" d-block">Phone: {this.props.entry.contactPhone || '/'}</span>
                    </div>
                </div>
            </Link>
        );
    }
}

Client.propTypes = {};

export default Client;
