import React from "react";
import Damage from "./Damage/Damage";

class DamagesWrapper extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        if (this.props.tempDamages) {
            return this.props.tempDamages.map(item => {
                return (
                    <Damage key={item} tempId={item} {...this.props} />
                );
            });
        } else {
            return null;
        }
    }
}

DamagesWrapper.propTypes = {};

export default DamagesWrapper;
