import React from "react";
import {Link} from "react-router-dom";


import Axios from "axios";
import Store from "store";
import Swal from 'sweetalert2';


class CreateMewMapping extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            token: Store.get('accessToken').token,
            companies: [],
            company_id: null,
            make: false,
            model: false,
            vin: false,
            part: false,
            partFormat: "",
            type: false,
            typeFormat: "",
            severity: false,
            severityFormat: "",
            cause: false,
            causeFormat: "",
            repair_mode: false,
            repairModeFormat: "",
            class: false,
            classFormat: "",
            dln: false,
            dlnFormat: "",
            comment: false,
            first_seen: false,
            inspection_place: false,
            microlocation: false,
            country: false,
            port_of_loading: false,
            transshipment_port: false,
            port_of_discharging: false,
            inspected_by: false,
            transport_company: false,
            transport_id: false,
            transport_type: false,
            order: false,
            privacyPolicies: [],
            privacyPolicy: '',
            custom_comment: '',
            recipients: [],
        };

        this.radioSelected = this.radioSelected.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }


    componentDidMount() {
        /*if (this.state.token) {
            this.fetchItems();
        }*/
        Axios.get(process.env.REACT_APP_API_BASE + '/transportCompanies', {
            headers: {
                'Authorization': 'Bearer ' + this.state.token
            }
        }).then(res => {
            this.setState({
                companies: res.data
            })
        });
    }

    radioSelected(e) {
        let selection = e.target.value;
        if(selection === 'true') {
            this.setState({
                [e.target.name]: true
            })
        } else {
            this.setState({
                [e.target.name]: false
            })
        }
    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })

        console.log(e.target.value)
    }

    handleSubmit(e) {
        e.preventDefault();
        Axios.post(process.env.REACT_APP_API_BASE + '/mappings', this.state, {
            headers: {
                'Authorization': 'Bearer ' + this.state.token
            }
        })
            .then(res => {
                if(res.status === 200) {
                    Swal.fire({
                        title: 'Successfully added files',
                        icon: "success"
                    });
                    this.props.history.push('/dashboard/mapping');
                }
            })
            .catch(err => {
                if(err.response.status === 422) {
                    Swal.fire({
                        title: 'Mapping for company already exists',
                        icon: "error"
                    });
                }
            })
    }

    render() {

        return (
            <div className="container" id="main">
                <div className="row mb-5">
                    <div className="col-12 d-flex justify-content-between align-items-center">
                        <h1 className={"mb-0"}>Mapping</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-8">
                        <h2>Select company for mapping</h2>
                        <div className="form-group mt-2">
                            <select className={"form-select"} name={"company_id"} id={"company_id"} onChange={this.handleChange}>
                                <option value="">Select</option>
                                {this.state.companies.map(company => {
                                    return <option value={company.id}>{company.name}</option>
                                })}
                            </select>
                        </div>

                        <h2>Select data to include in report</h2>
                        <form action="" className={"mb-5"} onSubmit={this.handleSubmit}>

                        <div className={'layout-grid-3'}>
                                <div>
                                    <h6 className="mt-3">Make</h6>

                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="true" className="form-check-input" checked={this.state.make === true} type="radio" name="make"
                                               id="flexRadioDefault1">
                                        </input>Yes
                                    </div>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="false" className="form-check-input" checked={this.state.make === false} type="radio" name="make"
                                               id="flexRadioDefault2">
                                        </input>No
                                    </div>
                                </div>

                                <div>
                                    <h6 className="mt-3">Model</h6>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="true" className="form-check-input" checked={this.state.model === true} type="radio" name="model"
                                               id="flexRadioDefault1">
                                        </input>Yes
                                    </div>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="false" className="form-check-input" checked={this.state.model === false} type="radio" name="model"
                                               id="flexRadioDefault2">
                                        </input>No
                                    </div>
                                </div>

                                <div>
                                    <h6 className="mt-3">VIN</h6>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="true" className="form-check-input" checked={this.state.vin === true} type="radio" name="vin"
                                               id="flexRadioDefault1">
                                        </input>Yes
                                    </div>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="false" className="form-check-input" checked={this.state.vin === false} type="radio" name="vin"
                                               id="flexRadioDefault2">
                                        </input>No
                                    </div>
                                    
                                </div>

                                <div>
                                    <h6 className="mt-5">Part</h6>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="true" className="form-check-input" checked={this.state.part === true} type="radio" name="part"
                                               id="flexRadioDefault1">
                                        </input>Yes
                                    </div>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="false" className="form-check-input" checked={this.state.part === false} type="radio" name="part"
                                               id="flexRadioDefault2">
                                        </input>No
                                    </div>

                                    <h6 className="mt-3">Formatting</h6>
                                    <select name="part_format" required id={"partFormat"} className={"form-select"} onChange={this.handleChange}>
                                        <option value="" disabled selected>Select Format</option>
                                        <option value="code">Code</option>
                                        <option value="text">Text</option>
                                        <option value="codetext">Code:Text</option>
                                    </select>
                                </div>

                                <div>
                                    <h6 className="mt-5">Type</h6>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="true" className="form-check-input" checked={this.state.type === true} type="radio" name="type"
                                               id="flexRadioDefault1">
                                        </input>Yes
                                    </div>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="false" className="form-check-input" checked={this.state.type === false} type="radio" name="type"
                                               id="flexRadioDefault2">
                                        </input>No
                                    </div>

                                    <h6 className="mt-3">Formatting</h6>
                                    <select name="type_format" required id={"typeFormat"} className={"form-select"} onChange={this.handleChange}>
                                        <option value="" disabled selected>Select Format</option>
                                        <option value="code">Code</option>
                                        <option value="text">Text</option>
                                        <option value="codetext">Code:Text</option>
                                    </select>

                                </div>

                                <div>
                                    <h6 className="mt-5">Severity</h6>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="true" className="form-check-input" checked={this.state.severity === true} type="radio" name="severity"
                                               id="flexRadioDefault1">
                                        </input>Yes
                                    </div>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="false" className="form-check-input" checked={this.state.severity === false} type="radio" name="severity"
                                               id="flexRadioDefault2">
                                        </input>No
                                    </div>

                                    <h6 className="mt-3">Formatting</h6>
                                    <select name="severity_format" required id={"severityFormat"} className={"form-select"} onChange={this.handleChange}>
                                        <option value="" disabled selected>Select Format</option>
                                        <option value="code">Code</option>
                                        <option value="text">Text</option>
                                        <option value="codetext">Code:Text</option>
                                    </select>
                                </div>

                                <div>
                                    <h6 className="mt-5">Cause</h6>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="true" className="form-check-input" checked={this.state.cause === true} type="radio" name="cause"
                                               id="flexRadioDefault1">
                                        </input>Yes
                                    </div>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="false" className="form-check-input" checked={this.state.cause === false} type="radio" name="cause"
                                               id="flexRadioDefault2">
                                        </input>No
                                    </div>

                                    <h6 className="mt-3">Formatting</h6>
                                    <select name="cause_format" required id={"causeFormat"} className={"form-select"} onChange={this.handleChange}>
                                        <option value="" disabled selected>Select Format</option>
                                        <option value="code">Code</option>
                                        <option value="text">Text</option>
                                        <option value="codetext">Code:Text</option>
                                    </select>
                                </div>

                                <div>
                                    <h6 className="mt-5">Repair mode</h6>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="true" className="form-check-input" checked={this.state.repair_mode === true} type="radio" name="repair_mode"
                                               id="flexRadioDefault1">
                                        </input>Yes
                                    </div>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="false" className="form-check-input" checked={this.state.repair_mode === false} type="radio" name="repair_mode"
                                               id="flexRadioDefault2">
                                        </input>No
                                    </div>

                                    <h6 className="mt-3">Formatting</h6>
                                    <select name="repair_mode_format" required id={"repairFormat"} className={"form-select"} onChange={this.handleChange}>
                                        <option value="" disabled selected>Select Format</option>
                                        <option value="code">Code</option>
                                        <option value="text">Text</option>
                                        <option value="codetext">Code:Text</option>
                                    </select>
                                </div>

                                <div>
                                    <h6 className="mt-5">Class</h6>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="true" className="form-check-input" checked={this.state.class === true} type="radio" name="class"
                                               id="flexRadioDefault1">
                                        </input>Yes
                                    </div>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="false" className="form-check-input" checked={this.state.class === false} type="radio" name="class"
                                               id="flexRadioDefault2">
                                        </input>No
                                    </div>


                                    <h6 className="mt-3">Formatting</h6>
                                    <select name="class_format" required id={"classFormat"} className={"form-select"} onChange={this.handleChange}>
                                        <option value="" disabled selected>Select Format</option>
                                        <option value="code">Code</option>
                                        <option value="text">Text</option>
                                        <option value="codetext">Code:Text</option>
                                    </select>
                                </div>

                                <div>
                                    <h6 className="mt-5">DLN</h6>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="true" className="form-check-input" checked={this.state.dln === true} type="radio" name="dln"
                                               id="flexRadioDefault1">
                                        </input>Yes
                                    </div>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="false" className="form-check-input" checked={this.state.dln === false} type="radio" name="dln"
                                               id="flexRadioDefault2">
                                        </input>No
                                    </div>

                                    <h6 className="mt-3">Formatting</h6>
                                    <select name="dln_format" required id={"dlnFormat"} className={"form-select"} onChange={this.handleChange}>
                                        <option value="" disabled selected>Select Format</option>
                                        <option value="code">Code</option>
                                        <option value="text">Text</option>
                                        <option value="codetext">Code:Text</option>
                                    </select>
                                </div>

                                <div>
                                    <h6 className="mt-5">Comment</h6>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="true" className="form-check-input" checked={this.state.comment === true} type="radio" name="comment"
                                               id="flexRadioDefault1">
                                        </input>Yes
                                    </div>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="false" className="form-check-input" checked={this.state.comment === false} type="radio" name="comment"
                                               id="flexRadioDefault2">
                                        </input>No
                                    </div>
                                    

                                </div>

                                <div>
                                    <h6 className="mt-5">First seen</h6>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="true" className="form-check-input" checked={this.state.first_seen === true} type="radio" name="first_seen"
                                               id="flexRadioDefault1">
                                        </input>Yes
                                    </div>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="false" className="form-check-input" checked={this.state.first_seen === false} type="radio" name="first_seen"
                                               id="flexRadioDefault2">
                                        </input>No
                                    </div>
                                </div>

                                <div>
                                    <h6 className="mt-5">Inspection place</h6>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="true" className="form-check-input" checked={this.state.inspection_place === true} type="radio" name="inspection_place"
                                               id="flexRadioDefault1">
                                        </input>Yes
                                    </div>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="false" className="form-check-input" type="radio" checked={this.state.inspection_place === false} name="inspection_place"
                                               id="flexRadioDefault2">
                                        </input>No
                                    </div>
                                </div>

                                <div>
                                    <h6 className="mt-5">Microlocation</h6>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="true" className="form-check-input" checked={this.state.microlocation === true} type="radio" name="microlocation"
                                               id="flexRadioDefault1">
                                        </input>Yes
                                    </div>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="false" className="form-check-input" type="radio" checked={this.state.microlocation === false} name="microlocation"
                                               id="flexRadioDefault2">
                                        </input>No
                                    </div>
                                </div>

                                <div>
                                    <h6 className="mt-5">Country</h6>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="true" className="form-check-input" checked={this.state.country === true} type="radio" name="country"
                                               id="flexRadioDefault1">
                                        </input>Yes
                                    </div>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="false" className="form-check-input" type="radio" checked={this.state.country === false} name="country"
                                               id="flexRadioDefault2">
                                        </input>No
                                    </div>
                                </div>

                                <div>
                                    <h6 className="mt-5">Port of loading</h6>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="true" className="form-check-input" checked={this.state.port_of_loading === true} type="radio" name="port_of_loading"
                                               id="flexRadioDefault1">
                                        </input>Yes
                                    </div>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="false" className="form-check-input" type="radio" checked={this.state.port_of_loading === false} name="port_of_loading"
                                               id="flexRadioDefault2">
                                        </input>No
                                    </div>
                                </div>

                                <div>
                                    <h6 className="mt-5">Transshipment port</h6>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="true" className="form-check-input" checked={this.state.transshipment_port === true} type="radio" name="transshipment_port"
                                               id="flexRadioDefault1">
                                        </input>Yes
                                    </div>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="false" className="form-check-input" type="radio" checked={this.state.transshipment_port === false} name="transshipment_port"
                                               id="flexRadioDefault2">
                                        </input>No
                                    </div>
                                </div>

                                <div>
                                    <h6 className="mt-5">Port of discharging</h6>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="true" className="form-check-input" checked={this.state.port_of_discharging === true} type="radio" name="port_of_discharging"
                                               id="flexRadioDefault1">
                                        </input>Yes
                                    </div>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="false" className="form-check-input" type="radio" checked={this.state.port_of_discharging === false} name="port_of_discharging"
                                               id="flexRadioDefault2">
                                        </input>No
                                    </div>
                                </div>

                                <div>
                                    <h6 className="mt-5">Inspected by</h6>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="true" className="form-check-input" checked={this.state.inspected_by === true} type="radio" name="inspected_by"
                                               id="flexRadioDefault1">
                                        </input>Yes
                                    </div>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="false" className="form-check-input" type="radio" checked={this.state.inspected_by === false} name="inspected_by"
                                               id="flexRadioDefault2">
                                        </input>No
                                    </div>
                                </div>

                                <div>
                                    <h6 className="mt-5">Transport company</h6>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="true" className="form-check-input" checked={this.state.transport_company === true} type="radio" name="transport_company"
                                               id="flexRadioDefault1">
                                        </input>Yes
                                    </div>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="false" className="form-check-input" type="radio" checked={this.state.transport_company === false} name="transport_company"
                                               id="flexRadioDefault2">
                                        </input>No
                                    </div>
                                </div>

                                <div>
                                    <h6 className="mt-5">Transport ID</h6>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="true" className="form-check-input" checked={this.state.transport_id === true} type="radio" name="transport_id"
                                               id="flexRadioDefault1">
                                        </input>Yes
                                    </div>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="false" className="form-check-input" type="radio" checked={this.state.transport_id === false} name="transport_id"
                                               id="flexRadioDefault2">
                                        </input>No
                                    </div>
                                </div>

                                <div>
                                    <h6 className="mt-5">Transport type</h6>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="true" className="form-check-input" checked={this.state.transport_type === true} type="radio" name="transport_type"
                                               id="flexRadioDefault1">
                                        </input>Yes
                                    </div>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="false" className="form-check-input" type="radio" checked={this.state.transport_type === false} name="transport_type"
                                               id="flexRadioDefault2">
                                        </input>No
                                    </div>
                                </div>

                                <div>
                                    <h6 className="mt-5">Order ID</h6>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="true" className="form-check-input" checked={this.state.order === true} type="radio" name="order"
                                               id="flexRadioDefault1">
                                        </input>Yes
                                    </div>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="false" className="form-check-input" type="radio" checked={this.state.order === false} name="order"
                                               id="flexRadioDefault2">
                                        </input>No
                                    </div>
                                </div>
                                
                            </div>

                            <button type={'submit'} className={'btn btn-primary mt-4'}>Send</button>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

}

CreateMewMapping.propTypes = {};

export default CreateMewMapping;
