import React from 'react';
import {withRouter} from "react-router-dom";
import Store from "store";
import Axios from "axios";
import {getCurrentUserData} from "../../../utils/utils";

class Login extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            email: "",
            password: "",
            registrationErrors: ""
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);

    }

    handleSubmit(e) {
        e.preventDefault();

        const params = new URLSearchParams();
        params.append('email', this.state.email);
        params.append('password', this.state.password);

        Axios.post(process.env.REACT_APP_API_BASE + '/login', params,
            {
                withCredentials: true//SUPER FREAKIN IMPORTANT WITH AUTH IF YOU WANT TO BE ABLE TO SET COOKIE. NEVER FORGET!
            }).then(response => {
                console.log(response);
                if (response.status === 200) {
                    let now = Math.round(Date.now() / 1000);
                    var expiration = now + response.data['expires_in']; //3 hours

                    Store.set('accessToken', {
                        token: response.data['access_token'],
                        refreshToken: response.data['refresh_token'],
                        expiration : expiration,
                        role: response.data.role
                    });

                    // return this.props.history.push("/dashboard");
                    getCurrentUserData().then(() => {
                        return this.props.history.push("/dashboard");
                    });
                } else {
                    this.setState({
                        registrationErrors: "Something went wrong"
                    });
                }
        }).catch(error => {
            console.log('An error has occurred', error);
            this.setState({
                registrationErrors: "Something went wrong"
            });
        });
    }

    handleChange(e) {
        //Sets state with the appropriate key, to the matching input's value
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    render() {
        return (
            <div>
                <form action="" onSubmit={this.handleSubmit}>
                    <div className="form-group">
                        <label className={"form-label"} htmlFor="email">Email</label>
                        <input type="email" className="form-control" name={"email"} id={"email"} value={this.state.email} onChange={this.handleChange} required/>
                    </div>
                    <div className="form-group">
                        <label className={"form-label"} htmlFor="password">Password</label>
                        <input type="password" className="form-control" name={"password"} id={"password"} value={this.state.password} onChange={this.handleChange} required/>
                    </div>

                    <div className="d-flex align-items-center justify-content-between">
                        <div>
                            <button type={"submit"} className={"btn btn-primary"}>Login</button>
                        </div>

                    </div>
                </form>
                <div className="errors">
                    <h6 className={"mb-0"}>{this.state.registrationErrors}</h6>
                </div>
                <div className="d-flex justify-content-end mt-3">
                    <button className="btn" onClick={() => {return this.props.history.push("/passwordresetrequest");}}>Reset password</button>
                </div>
            </div>
        );
    }
}

Login.propTypes = {};

export default withRouter(Login);
