import React from "react";
import Style from "../CargoItem.module.scss";
import Style2 from "./GalleryModal.module.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faImage} from "@fortawesome/free-solid-svg-icons";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

import Axios from "axios";
import Store from "store";
import GalleryGrid from "../../../../../../GalleryGrid/GalleryGrid";

const GallerySwal = withReactContent(Swal);
const UploadImages = withReactContent(Swal);

class GalleryModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            images: []
        };

        this.openGalleryModal = this.openGalleryModal.bind(this);
        this.selectOrderModal = this.selectOrderModal.bind(this);
        this.openUploadImageModal = this.openUploadImageModal.bind(this);
        this.uploadImages = this.uploadImages.bind(this);
        this.fetchImages = this.fetchImages.bind(this);
        this.refreshEverything = this.refreshEverything.bind(this);
    }

    fetchImages() {
        let config = {};

        config.headers = {
            'Authorization': 'Bearer ' + Store.get('accessToken').token
        };


        return new Promise((resolve, reject) => {
            Axios.get(process.env.REACT_APP_API_BASE + '/items/'+this.props.itemid, config)
                .then(r => {
                   if (r.status === 200) {
                       this.setState({
                           images: r.data.item.images
                       }, () => {
                           resolve(r.data.item.images);
                       });
                   } else {
                       reject('smt went wrong while fetching images');
                   }
                });
        });
    }

    selectOrderModal() {
        return (
            <div className={"select-order-modal-wrapper"}>
                <GalleryGrid
                    type={"cargoItem"}
                    images={this.state.images}
                    allowDelete={true}
                    refreshImages={this.refreshEverything}
                />

                <div className="footer d-flex justify-content-center mt-5">
                    <button className={"btn-min me-3"} onClick={() => Swal.close()}>Close</button>
                    <button className={"btn btn-primary"} onClick={this.openUploadImageModal}>Upload images</button>
                </div>
            </div>
        );
    }

    uploadImages(images) {

        return new Promise((resolve, reject) => {
            if (!images) throw new Error('Missing images');

            let formData = new FormData();

            for (let i = 0; i < images.length; i++) {
                formData.append('image[]', images[i]);
            }
            formData.append('item_id', this.props.itemid);

            Axios.post(process.env.REACT_APP_API_BASE + '/itemimage', formData, {
                headers: {
                    'Authorization': 'Bearer ' + Store.get('accessToken').token,
                }
            } )
                .then(response => {

                    if (response.status === 200) {
                        resolve();
                    } else {
                        reject('Smt went wrong');
                    }
                })
                .catch(error => {
                    console.log(error);
                })
        });
    }

    refreshEverything() {
        return this.fetchImages()
            .then(() => {
                return this.props.updateCount();
            })
            .then(() => {
                UploadImages.fire({
                    title: 'Cargo Item Images',
                    html: this.selectOrderModal(),
                    showConfirmButton: false,
                })
            })
            .catch((err) => {
                console.log('smt went wrong', err)
            })
    }

    openUploadImageModal() {
        GallerySwal.fire({
            title: 'Select image',
            input: 'file',
            showLoaderOnConfirm: true,
            showCancelButton: true,
            inputAttributes: {
                'accept': 'image/*',
                'multiple': 'multiple',
            },
            preConfirm: (inputValue) => {
                if (inputValue) {
                    return this.uploadImages(inputValue)
                        .then(() => {
                            return this.refreshEverything();
                        })
                        .then(() => {
                            return GallerySwal.fire({
                                title: 'Successfully added images',
                                icon: "success"
                            });
                        })
                        .then(() => {
                            UploadImages.fire({
                                title: 'Cargo Item Images',
                                html: this.selectOrderModal(),
                                showConfirmButton: false,
                            })
                        })
                        .catch(err => {
                            console.log(err);
                            GallerySwal.fire({
                                title: 'Something went wrong',
                                icon: "error"
                            });
                        });
                }
            }
        });
    }


    openGalleryModal(e) {
        e?.preventDefault();

        this.fetchImages()
            .then(() => {
                UploadImages.fire({
                    title: 'Cargo Item Images',
                    html: this.selectOrderModal(),
                    showConfirmButton: false,
                });
            })


    }

    render() {
        return (
            <button className={Style.galleryBtn} onClick={this.openGalleryModal}>
                <FontAwesomeIcon icon={faImage} />
                <span className={Style2.count}>{this.props.count}</span>
            </button>
        );
    }
}

GalleryModal.propTypes = {};

export default GalleryModal;
