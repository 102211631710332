import React from "react";
import {Link} from "react-router-dom";
import Style from "./TransportCompany.module.scss";

class TransportCompany extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};

        console.log(this.props);
    }

    render() {
        return (
            <Link className={Style.item + ' card no-shadow'} to={"/dashboard/transport-companies/edit/"+this.props.entry.id}>
                <div className="card-body bg-white">
                    <div className="meta-wrapper d-flex flex-column">
                        <span className="d-block mb-1">ID: {this.props.entry.id}</span>
                        <span className="strong d-block">{this.props.entry.name}</span>
                    </div>
                </div>
            </Link>
        );
    }
}

TransportCompany.propTypes = {};

export default TransportCompany;
