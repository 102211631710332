import React from "react";
import Store from "store";
import Axios from "axios";
import {Link} from "react-router-dom";
import {Accordion, Card} from "react-bootstrap";
import Orders from "../Orders";
import OrderFilters from "./OrderFilters/OrderFilters";
import moment from "moment";
import HelperControls from "./HelperControls/HelperControls";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {deleteOrder} from "../../../../utils/utils";


const BulkActionsModal = withReactContent(Swal);


class AllOrders extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            id: this.props.match.params.entryId,
            token: Store.get('accessToken').token,
            data: null,
            orderCount: null,
            items: [],
            selectedOrders: [],
            selectAllChecked: false,
            sort:'date_desc'
        };

        this.updateCounter = this.updateCounter.bind(this);
        this.fetchItems = this.fetchItems.bind(this);
        this.selectOrder = this.selectOrder.bind(this);
        this.unselectOrder = this.unselectOrder.bind(this);
        this.selectAllOrders = this.selectAllOrders.bind(this);
        this.unselectAllOrders = this.unselectAllOrders.bind(this);
        this.bulkActionControl = this.bulkActionControl.bind(this);
        this.orderItems = this.orderItems.bind(this);
    }

    componentDidMount() {
        this.fetchItems();
    }

    updateCounter(count) {
        this.setState({
            orderCount: count
        })
    }


    fetchItems(data) {
        let config = {};

        this.unselectAllOrders();

        config.headers = {
            'Authorization': 'Bearer ' + this.state.token
        };

        if (this.state.sort) {
            config.headers.sort = this.state.sort;
        }

        config.params = {};

        if (data) {
            const {
                client_id,
                inspector,
                phase,
                transport_type,
                inspection_type,
                transport,
                gate,
                fromDate,
                toDate,
                transport_company,
                voyage,
                destination,
                port_of_discharging,
                port_of_origin,
                make,
                model
            } = data;

            if (client_id) {
                config.params['client_id'] = client_id?.map(i => i.value);
            }
            if (inspector) {
                config.params['inspector'] = inspector?.map(i => i.value);
            }
            if (phase) {
                config.params['phase'] = phase?.map(i => i.value);
            }
            if (transport_type) {
                config.params['transport_type'] = transport_type?.map(i => i.value);
            }
            if (inspection_type) {
                config.params['inspection_type'] = inspection_type?.map(i => i.value);
            }
            if (transport) {
                config.params['transport'] = transport?.map(i => i.value);
            }
            if (gate) {
                config.params['gate'] = gate?.map(i => i.value);
            }
            if (fromDate) {
                config.params['inspection_from'] = moment(fromDate).format('DD.MM.YYYY');
            }
            if (toDate) {
                config.params['inspection_to'] = moment(toDate).format('DD.MM.YYYY');
            }
            if (transport_company) {
                config.params['transport_company'] = transport_company?.map(i => i.value);
            }

            if (voyage) {
                config.params['voyage'] = voyage;
            }
            if (destination) {
                config.params['destination'] = destination?.map(i => i.value);
            }
            if (port_of_discharging) {
                config.params['port_of_discharging'] = port_of_discharging?.map(i => i.value);
            }
            if (port_of_origin) {
                config.params['port_of_origin'] = port_of_origin?.map(i => i.value);
            }
            if (make) {
                config.params['make'] = make?.map(i => i.value);
            }
            if (model) {
                config.params['model'] = model?.map(i => i.value);
            }
        }

        Axios.get(process.env.REACT_APP_API_BASE + '/orders/wds', config).then(response => {
            if (response.status === 200) {

                this.setState({
                    items: response.data.orders,
                    orderCount: response.data.orders.length
                });
            } else {
                throw new Error('Smt went wrong with fetching orders');
            }
        }).catch(err => {
            console.log(err);
            // if (err.response.status === 401) {
            //     console.log('attempting reauth');
            //     reAuth();
            // }
        });
    }

    selectAllOrders() {
        this.setState({
            selectAllChecked: true,
            selectedOrders: this.state.items?.map(item => item.id)
        })
    }

    unselectAllOrders() {
        this.setState({
            selectAllChecked: false,
            selectedOrders: []
        })
    }

    //Trigger delete on all simultaniously
    deleteItems() {
        let promises = [];

        for (let item of this.state.selectedOrders) {
            promises.push(() => deleteOrder(item));
        }

        return Promise.all(promises.map(func => func()));
    }

    bulkActionControl(action) {
        if (this.state.selectedOrders && this.state.selectedOrders.length > 0) {
            if (action === 'delete') {
                BulkActionsModal.fire({
                    title: 'Are you sure you want to delete?',
                    showCancelButton: true,
                }).then(r => {
                    if (r.isConfirmed) {
                        this.deleteItems()
                            .then(r => {
                                this.unselectAllOrders();
                                BulkActionsModal.fire({
                                    title: 'Successfully deleted orders',
                                    icon: 'success'
                                }).then(() => {
                                    this.fetchItems();
                                });
                            }).catch(err => {
                            BulkActionsModal.fire({
                                title: 'Something went wrong',
                                icon: 'error'
                            }).then(() => {
                                this.fetchItems();
                            });
                        });
                    }
                });
            }
        }
    }

    selectOrder(item) {
        let itemId = parseInt(item);

        if (!this.state.selectedOrders.includes(itemId)) {
            this.setState({
                selectedOrders: [...this.state.selectedOrders, itemId]
            });
        }
    }

    unselectOrder(item) {
        let itemId = parseInt(item);

        if (this.state.selectedOrders.includes(itemId)) {
            this.setState({
                selectedOrders: this.state.selectedOrders.filter(item => item !== itemId)
            });
        }
    }

    orderItems(val) {
        this.setState({
            sort: val
        }, () => {
            this.fetchItems();
        });
    }

    render() {
        return (
            <div className="container" id="main">
                <div className="row mb-5">
                    <div className="col-12 col-md-6">
                        <h1 className={"mt-1"}>Orders & Inspections Overview</h1>
                    </div>

                    <div className="col-12 col-md-6 d-flex justify-content-end align-items-center">
                        <Link to={"/dashboard/stock"} className="btn btn-secondary me-2">View Stock</Link>
                        <Link to={"/dashboard/unallocated"} className="btn btn-secondary me-2">View unallocated items</Link>
                        <Link to={"/dashboard/orders/add-new"} className="btn btn-primary">Start new order</Link>
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col-12">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className={"d-flex align-items-center"}>
                                <h2 className={"mb-0"}>Open orders</h2>
                                <span className="circle-count ms-3">{this.state.orderCount}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-12">
                        <section>
                            <Accordion>
                                <Card className={"no-shadow"}>
                                    <Accordion.Toggle as={Card.Header} eventKey="0">
                                        Filter Orders
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="0">
                                        <Card.Body>
                                            <OrderFilters updateItems={this.fetchItems}/>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                        </section>
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-12">
                        <section>
                            <Card className="no-shadow">
                                <Card.Body>
                                    <HelperControls
                                        selectAllOrders={this.selectAllOrders}
                                        unselectAllOrders={this.unselectAllOrders}
                                        bulkActionControl={this.bulkActionControl}
                                        selectAllChecked={this.state.selectAllChecked}
                                        orderItems={this.orderItems}
                                    />
                                </Card.Body>
                            </Card>
                        </section>
                    </div>
                </div>


                <div className="row mt-4">
                    <div className="col-12">
                        <Orders
                            selectOrder={this.selectOrder}
                            unselectOrder={this.unselectOrder}
                            updateCounter={this.updateCounter}
                            selectedOrders={this.state.selectedOrders}
                            items={this.state.items}
                            bulk={true}
                        />
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-12">
                        <section>
                            <Card className="no-shadow">
                                <Card.Body>
                                    <HelperControls
                                        selectAllOrders={this.selectAllOrders}
                                        unselectAllOrders={this.unselectAllOrders}
                                        bulkActionControl={this.bulkActionControl}
                                        selectAllChecked={this.state.selectAllChecked}
                                        orderItems={this.orderItems}
                                    />
                                </Card.Body>
                            </Card>
                        </section>
                    </div>
                </div>
            </div>
        );
    }
}

AllOrders.propTypes = {};

export default AllOrders;
