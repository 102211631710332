import React from "react";
import {Link} from "react-router-dom";
import {getAdmins, getClients, getDamageSets, getInspectors, getManagers, getTransports} from "../../../../utils/utils";
import BaseSelect from 'react-select';
import Axios from "axios";
import Store from "store";
import inspectionTypes from "../../../../fixedData/inspectionTypes";
import {FileUploader} from "../../../../FileUploader/FileUploader";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import FixRequiredSelect from "../../../../utils/FixRequiredSelect";
const SuccessModal = withReactContent(Swal);

// const Select = props => (
//     <FixRequiredSelect
//         {...props}
//         SelectComponent={BaseSelect}
//         options={props.options || options}
//     />
// );

class ClientAddNewOrder extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            'owner': null, //client
            token: Store.get('accessToken').token,
            'inspectors': [],
            'admin': null,
            'client': null,
            'notes': '',
            'adminsArr': [],
            'inspectorsArr': [],
            'clientsArr': [],
            'transportsArr': [],
            damageSetsArr: [],
            damageSetId: null,
            transportType: '',
            gate: '',
            inspectionType: '',
            transports: [],
            'cargo_type': '',
            'jointSurvey': false,
            'receivingParty': false
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleMultiSelectChange = this.handleMultiSelectChange.bind(this);
        this.setSelectedFile = this.setSelectedFile.bind(this);
        this.jointSurveyHandleChange = this.jointSurveyHandleChange.bind(this);
        this.receivingPartyHandleChange = this.receivingPartyHandleChange.bind(this);


    }

    handleSubmit(e) {
        e.preventDefault();

        SuccessModal.showLoading();

        Axios.post(process.env.REACT_APP_API_BASE + '/orders', {
            'admin_id': this.state.admin,
            'client_id': this.state.client,
            'inspectors': this.state.inspectors,
            'items': [],
            'orderNotes': this.state.notes,
            'damageSetId': this.state.damageSetId,
            'transportType': this.state.transportType,
            'gate': this.state.gate,
            'inspectionType':this.state.inspectionType,
            'transportIds': this.state.transports,
            'cargo_type': this.state.cargo_type,
            'jointSurvey': this.state.jointSurvey,
            'receivingParty': this.state.receivingParty
        }, {
            headers: {
                'Authorization': 'Bearer ' + this.state.token
            }
        } )
            .then(response => {

                // return;
                if (response.status === 201) {
                    this.setState({
                        id: response.data.order.id
                    }, () => {
                        if (this.state.images) {
                            this.addImages(this.state.images).then(r => {
                                SuccessModal.fire({
                                    title: 'Successfully added new order',
                                }).then(() => {
                                    this.props.history.push('/dashboard/orders/order/'+this.state.id);
                                });
                            });
                        } else {
                            SuccessModal.fire({
                                title: 'Successfully added new order',
                            }).then(() => {
                                this.props.history.push('/dashboard/orders/order/'+this.state.id);
                            });
                        }
                    });

                }
            })
            .catch(error => {
                console.log(error);
                SuccessModal.fire({
                    title: 'Something went wrong',
                    icon: 'error'
                }).then(() => {
                    this.props.history.push('/dashboard/orders');
                });
                this.setState({
                    'formErrors': error.response.data.message
                })
            })
    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        });

        if (e.target.name === 'gate') {
            this.setState({
                inspectionType: ''
            });
        }
    }

    handleSelectChange(obj, e) {

        this.setState({
            [e.name]: obj.value
        })
    }

    handleMultiSelectChange(obj, e) {

        let itemsIdArr = [];

        for (let item of obj) {
            itemsIdArr.push(item.value);
        }

        this.setState({
            [e.name]: itemsIdArr
        })
    }

    jointSurveyHandleChange(e) {
        if(e.target.value === 'true') {
            this.setState({
                jointSurvey: true
            })
        } else {
            this.setState({
                jointSurvey: false
            })
        }
    }

    receivingPartyHandleChange(e) {
        if(e.target.value === 'true') {
            this.setState({
                receivingParty: true
            })
        } else {
            this.setState({
                receivingParty: false
            })
        }
    }


    componentDidMount() {
        let tempAdmins = [];

        getManagers().then(resp => {
            console.log(resp);

            for (const item of resp) {
                tempAdmins.push({
                    'value': item.id,
                    'label': item.name + ' (' + item.email + ')'
                });
            }

            this.setState({
                'adminsArr': tempAdmins
            });
        });


        var tempInspectors = [];
        getInspectors().then(resp => {

            for (const item of resp) {
                tempInspectors.push({
                    'value': item.id,
                    'label': item.name + ' (' + item.email + ')'
                });
            }
        }).then(() => {
            this.setState({
                'inspectorsArr': tempInspectors
            });
        });


        var tempClients = [];
        getClients().then(resp => {

            for (const item of resp) {
                tempClients.push({
                    'value': item.id,
                    'label': item.name + ' (' + item.email + ')'
                });
            }
        }).then(() => {
            this.setState({
                'clientsArr': tempClients
            });
        });

        let tempDamageSets = [];
        getDamageSets()
            .then(resp => {
                for (const item of resp) {
                    tempDamageSets.push({
                        'value': item.id,
                        'label': item.name
                    });
                }
            })
            .then(() => {
                this.setState({
                    damageSetsArr: tempDamageSets
                });
            });

        let tempTransports = [];
        getTransports()
            .then(resp => {
                for (const item of resp) {
                    tempTransports.push({
                        'value': item.id,
                        'label': item.identificationName + ' (' + item.transportDetailsId + ')'
                    });
                }
            })
            .then(() => {
            this.setState({
                'transportsArr': tempTransports
            });
        });
    }


    inspectionTypeSelect() {
        if (inspectionTypes) {
            if (this.state.gate) {
                return (
                    <select className={"form-select"} name={"inspectionType"} id={"inspectionType"} value={this.state.inspectionType} onChange={this.handleChange}>
                        <option value="">Select Inspection Type</option>
                        {inspectionTypes[this.state.gate].map((item, index) => {
                            return (
                                <option value={item}>{item}</option>
                            );
                        })}
                    </select>
                )
            } else {
                return (
                    <input type="text" placeholder={"Select gate first"} disabled className={"form-control"}/>
                )
            }
        }
    }

    addImages(images) {

        return new Promise((resolve, reject) => {
            if (!images) throw new Error('Missing images');

            let formData = new FormData();

            for (let i = 0; i < images.length; i++) {
                formData.append('image[]', images[i]);
            }
            formData.append('order_id', this.state.id);

            Axios.post(process.env.REACT_APP_API_BASE + '/orderimages', formData, {
                headers: {
                    'Authorization': 'Bearer ' + this.state.token
                }
            } )
                .then(response => {
                    if (response.status === 200) {
                        resolve();
                    }
                })
                .catch(error => {
                    console.log(error);
                    this.setState({
                        'formErrors': error.response.data.message
                    }, () => {
                        reject(error.response.data.message);
                    });
                })
        });
    }


    setSelectedFile(file) {
        this.setState({
            images: file
        })
    }

    render() {
        return (
            <div className="container pb-10" id="main">
                <div className="row mb-5">
                    <div className="col-12 col-md-8">
                        <Link to={"/dashboard/orders/"} className={"btn-min"}>
                            <span className="ml-1">View all orders</span>
                        </Link>
                        <h1 className={"mt-1"}>Add New Order</h1>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 col-md-8">
                        <form action="" className={"mb-5"} onSubmit={this.handleSubmit}>

                            <div className="row mb-3">
                                <div className="col-12">
                                    <div className="form-group">
                                        <label htmlFor="transportType">Transport Type</label>
                                        <select className={"form-select"} name={"transportType"} id={"transportType"} value={this.state.transportType} onChange={this.handleChange}>
                                            <option disabled value="">Select Transport Type</option>
                                            <option value="train">Train</option>
                                            <option value="truck">Truck</option>
                                            <option value="vessel">Vessel</option>
                                            <option value="barge">Barge</option>
                                            <option value="compound">Compound</option>
                                            <option value="factory">Factory</option>
                                            <option value="container">Container</option>
                                            <option value="shuttle">Shuttle</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group">
                                        <label htmlFor="name">Damage Set</label>
                                        <BaseSelect
                                            closeMenuOnSelect={true}
                                            options={this.state.damageSetsArr}
                                            name="damageSetId"
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            onChange={this.handleSelectChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="gate">Gate</label>
                                        <select className={"form-select"} name={"gate"} id={"gate"} value={this.state.gate} onChange={this.handleChange}>
                                            <option disabled value="">Select gate</option>
                                            <option value="arrival">Arrival</option>
                                            <option value="departure">Departure</option>
                                            <option value="internal">Internal</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="inspectionType">Inspection Type</label>
                                        {this.inspectionTypeSelect()}
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="transports">Transports</label>
                                        <BaseSelect
                                            closeMenuOnSelect={true}
                                            isMulti
                                            options={this.state.transportsArr}
                                            onChange={this.handleMultiSelectChange}
                                            name={"transports"}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="cargo_type">Cargo Type*</label>
                                        <select required className={"form-select"} name={"cargo_type"} id={"cargo_type"} value={this.state.cargo_type} onChange={this.handleChange}>
                                            <option value="">Select Cargo Type</option>
                                            <option value="vehicles">Vehicles</option>
                                            <option value="coils">Coils</option>
                                            <option value="container">Container</option>
                                            <option value="ingo">Ingo</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-12">
                                    <div className="form-group">
                                        <label htmlFor="notes">Notes</label>
                                        <textarea className={"form-control"} onChange={this.handleChange} name="notes" id="notes" cols="30" rows="5"></textarea>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-12">
                                    <FileUploader
                                        onFileSelect={(file) => this.setSelectedFile(file)}
                                        onFileSelectError={({ error }) => alert(error)}
                                    />
                                </div>
                            </div>



                            <div className="row mt-3">
                                <div className="col-12">
                                    <button type={"submit"} className={"btn btn-primary"}>Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

ClientAddNewOrder.propTypes = {};

export default ClientAddNewOrder;
