import React from "react";
import {Link} from "react-router-dom";

class Make extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        return (
            <Link className={"text-decoration-none"} to={"/dashboard/vin-decoder/makes/make/"+this.props.item.id}>
                <div className={"card no-shadow"}>
                    <div className="card-body">
                        <span className="d-block mb-1">ID: {this.props.item.id}</span>
                        <span className={"d-block strong"}>{this.props.item.name}</span>
                    </div>
                </div>
            </Link>
        );
    }
}

Make.propTypes = {};

export default Make;
