import React from "react";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {createCargoItem, getOrderItems} from "../../../../../utils/utils";
import Store from "store";
import Axios from "axios";
import {Link, Router, withRouter} from "react-router-dom";
const cloneOrderModal = withReactContent(Swal);

class CloneOrder extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};

        this.cloneOrder = this.cloneOrder.bind(this);
        this.createOrder = this.createOrder.bind(this);
        this.generateSuccessMsg = this.generateSuccessMsg.bind(this);
        this.handleRedirect = this.handleRedirect.bind(this);
    }

    createOrder() {
        return new Promise((resolve, reject) => {

            if (!Store.get('accessToken').token) {
                reject('Missing access token');
            }

            let config = {};
            config.headers = {
                'Authorization': 'Bearer ' + Store.get('accessToken').token,
            };

            let data = {
                'admin_id': this.props.data.admin?.id || null,
                'client_id': this.props.data.client?.id || null,
                'inspectors': this.props.data.inspectors || null,
                'items': [],
                'orderNotes': this.props.data.order_notes || null,
                'damageSetId': this.props.data.damageSet?.id || null,
                'transportType': this.props.data.transportType || null,
                'gate': this.props.data.gate || null,
                'inspectionType':this.props.data.inspectionType || null,
                'cargo_type': this.props.data.cargo_type || null,
            };

            if (this.props.data.transports && this.props.data.transports.length > 0) {
                data.transportIds = this.props.data.transports.map(i => i.id);
            }




            Axios.post(process.env.REACT_APP_API_BASE + '/orders', data, config)
                .then(r => {
                    if (r.status === 201) {
                        this.setState({
                            id: r.data.order.id
                        }, () => {
                            resolve(r.data.order.id);
                        });
                    } else {
                        reject(r);
                    }
                });
        });
    }

    cloneOrder(e) {
        e?.preventDefault();
        if (!this.props.orderId) return;

        cloneOrderModal.fire({
            title: 'Are you sure you want to clone this order and all of it\'s items?',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            preConfirm: (inputValue) => {
                console.log(inputValue);
                if (inputValue) {
                    console.log(this.props.orderItems);

                    return this.createOrder()
                        .then(() => {
                            return getOrderItems(this.props.orderId, true);
                        })
                        .then(() => {
                            return getOrderItems(this.props.orderId, true);
                        })
                        .then((r) => {
                            let promises = [];

                            for (let item of r) {
                                item.order_id = this.state.id;
                                promises.push(() => createCargoItem(item));
                            }

                            return Promise.all(promises.map(func => func()));
                        })
                        .then(r => {
                            console.log('these should be the items', r);
                        });
                }
            }
        })
            .then(() => {
                cloneOrderModal.fire({
                    icon: 'success',
                    title: 'Successfully cloned order',
                    html: this.generateSuccessMsg()
                });
            })
            // .then(r => {
            //     if (r.isConfirmed) {
            //         return deleteOrder(this.state.id);
            //     } else {
            //         throw new Error();
            //     }
            // })
            // .then(r => {
            //     if (r !== false) {
            //         cloneOrderModal.fire({
            //             title: 'Successfully deleted order',
            //             icon: 'success'
            //         }).then(() => {
            //             this.props.history.push('/dashboard/orders/');
            //         });
            //     }
            // });
    }

    generateSuccessMsg() {
        return (
            <>
                <button onClick={this.handleRedirect} className={"btn-min"}>View new order</button>
            </>
        )
    }

    handleRedirect() {
        cloneOrderModal.close();
        this.props.history.push("/dashboard/orders/order/"+this.state.id);
    }

    render() {
        return (
            <div>
                <button className={"btn btn-primary me-2"} onClick={this.cloneOrder}>Clone order</button>
            </div>
        );
    }
}

CloneOrder.propTypes = {};

export default withRouter(CloneOrder);
