import React from "react";


class HelperControls extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectAll: false,
            bulkAction: '',
            sorting: ''
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSortChange = this.handleSortChange.bind(this);
        this.cargoSelectChange = this.cargoSelectChange.bind(this);
        this.handleBulkSubmit = this.handleBulkSubmit.bind(this);
        this.handleBulkChange = this.handleBulkChange.bind(this);
    }

    handleSubmit(e) {
        e.preventDefault();
    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleSortChange(e) {
        this.props.orderItems(e.target.value);
    }

    cargoSelectChange(e) {

        if (e.target.checked) {
            this.props.selectAllOrders();
        } else {
            this.props.unselectAllOrders();
        }
    }

    handleBulkSubmit(e) {
        e?.preventDefault();
        if (this.state.bulkAction) {
            this.props.bulkActionControl(this.state.bulkAction);
        }
    }

    handleBulkChange(e) {
        this.setState({
            bulkAction: e.target.value
        })
    }

    render() {
        return (
            <div>
                <div className="row align-items-center">
                    <div className="col-12 d-flex align-items-center">
                        <div className={"d-flex align-items-center"}>
                            <div className="form-check form-check-inline">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id={'cargo-select'}
                                    name="cargo-select"
                                    checked={this.props.selectAllChecked}
                                    onChange={this.cargoSelectChange}
                                />
                                <label className="form-check-label" htmlFor={"cargo-select"}>
                                    Select all
                                </label>
                            </div>
                        </div>
                        <form onSubmit={this.handleBulkSubmit}>
                            <div className={"d-flex align-items-center mx-4"}>
                                <select className={"form-select me-2"} name="sortBy" onChange={this.handleBulkChange}>
                                    <option value="">Bulk Actions</option>
                                    <option value="delete">Delete Items</option>
                                </select>
                                <button type={"submit"} className={"btn btn-primary"}>Apply</button>
                            </div>
                        </form>
                        <div className={"d-flex align-items-center mx-4"}>
                            <strong className={"me-3 nowrap"}>Sort data by</strong>
                            <select className={"form-select me-2"} name="sortBy" onChange={this.handleSortChange}>
                                <option value="date_desc">Default Sorting</option>
                                <option value="date_asc">Date ASC</option>
                                <option value="date_desc">Date DESC</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

HelperControls.propTypes = {};

export default HelperControls;
