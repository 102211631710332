import React from "react";
import {Link, withRouter} from "react-router-dom";
import {displayDateTime} from "../../../../../utils/utils";
import axios from "axios";
import Store from "store";

class Created extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            token: Store.get('accessToken').token,
            orderId: ''
        }
    }

    componentDidMount() {
        axios.get(process.env.REACT_APP_API_BASE + '/vinLastOrder', {
            params: {
                'vin': this.props.vin
            },
            headers: {
                'Authorization': 'Bearer ' + this.state.token
            }
        })
        .then(res => {
            console.log(res);
            this.setState({
                orderId: res.data.id
            })
        })
    }

    render() {
        return (
            <div className={"card no-shadow"}>
                <div className="card-header">
                    <strong>{displayDateTime(this.props.data.date)}</strong>
                    <span> - </span>
                    <span className="me-3">Cargo item created and added to order <Link className={"btn-min"} to={"/dashboard/orders/order/"+this.state.orderId}>#{this.state.orderId}</Link></span>
                </div>
            </div>
        );
    }
}

Created.propTypes = {};

export default withRouter(Created);
