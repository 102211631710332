import React from "react";
import {
    Link,
    withRouter
} from "react-router-dom";
import Store from "store";
import Axios from "axios";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft} from "@fortawesome/free-solid-svg-icons";
import {getCurrentUser, removeItemOnce} from "../../../../utils/utils";

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
const SuccessModal = withReactContent(Swal);


class EditClient extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            id: this.props.match.params.entryId,
            token: Store.get('accessToken').token,
            companyName: null,
            name: null,
            label: null,
            streetAddress: null,
            streetNumber: null,
            city: null,
            zip: null,
            country: null,
            vat: null,
            representedBy: null,
            contactPerson: null,
            contactEmail: null,
            contactPhone: null,
            email: null,
            password1: '',
            password2: '',
            reportRecepients: [],
            reportRecipientsCount: 1,
            selectedReportRecipient: null
        };

        if (this.state.token && this.state.id) {
            Axios.get(process.env.REACT_APP_API_BASE + '/clients/'+this.state.id, {
                headers: {
                    'Authorization': 'Bearer ' + this.state.token
                },
            } ).then(response => {
                console.log(response);
                if (response.status === 200) {
                    this.setState({
                        companyName: response.data.client.companyName,
                        name: response.data.client.companyName,
                        label: response.data.client.label,
                        streetAddress: response.data.client.streetAddress,
                        streetNumber: response.data.client.streetNumber,
                        city: response.data.client.city,
                        zip: response.data.client.zip,
                        country: response.data.client.country,
                        vat: response.data.client.vat,
                        representedBy: response.data.client.representedBy,
                        contactPerson: response.data.client.contactPerson,
                        contactEmail: response.data.client.contactEmail,
                        contactPhone: response.data.client.contactPhone,
                        email: response.data.client.email,
                        reportRecepients: response.data.client.reportRecepients,
                        reportRecipientsCount: (response.data.client.reportRecepients.length > 0) ? response.data.client.reportRecepients.length : 1
                    });
                }
            }).catch(error => {
                console.log('error', error);
            });
        }


        this.handleChange = this.handleChange.bind(this);
        this.handleDefaultRecipientChange = this.handleDefaultRecipientChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.doUpdateUser = this.doUpdateUser.bind(this);
        this.renderRecipients = this.renderRecipients.bind(this);
        this.increaseRecipientCount = this.increaseRecipientCount.bind(this);
        this.decreaseRecipientCount = this.decreaseRecipientCount.bind(this);
        this.removeRecipient = this.removeRecipient.bind(this);
    }

    handleSubmit(e) {
        e.preventDefault();

        // console.log(this.state);

        if (this.state.password1 !== this.state.password2) {
            this.setState({
                'formErrors': 'Passwords don\'t match'
            });

            return null;
        } else {
            this.setState({
                'formErrors': ''
            });
        }

        // if (!this.state.email || !this.state.name) {
        //     this.setState({
        //         'formErrors': 'Missing fields'
        //     });
        //
        //     return null;
        // }
        // else if (this.state.password1 !== this.state.password2) {
        //     this.setState({
        //         'formErrors': 'Passwords don\'t match'
        //     });
        //
        //     return null;
        // }
        // else {
        //     this.setState({
        //         'formErrors': ''
        //     });
        // }
        //
        this.doUpdateUser();
    }

    //Performs user update
    doUpdateUser() {
        let formData = {
            companyName: this.state.companyName,
            name: this.state.companyName,
            label: this.state.label,
            streetAddress: this.state.streetAddress,
            streetNumber: this.state.streetNumber,
            city: this.state.city,
            zip: this.state.zip,
            country: this.state.country,
            vat: this.state.vat,
            representedBy: this.state.representedBy,
            contactPerson: this.state.contactPerson,
            contactEmail: this.state.contactEmail,
            contactPhone: this.state.contactPhone,
            // email: this.state.email,
            reportRecepients: []
        };

        if (this.state.password1 && this.state.password2) {
            formData.password = this.state.password1;
        }

        if (this.state.reportRecepients.length > 0) {
            //Do nothing because Rok is a moron
        }

        console.log(formData);

        Axios.put(process.env.REACT_APP_API_BASE + '/clients/'+this.state.id, formData, {
            headers: {
                'Authorization': 'Bearer ' + this.state.token
            }
        } )
            .then(response => {
                console.log(response);
                if (response.status === 200) {
                    SuccessModal.fire({
                        title: 'Successfully updated client',
                    }).then(() => {
                        this.props.history.push('/dashboard/clients');
                    });
                }
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    'formErrors': error.response.data.message
                })
            })
    }


    handleChange(e) {

        //Sets state with the appropriate key, to the matching input's value
        this.setState({
            [e.target.name]: e.target.value
        });
    }



    handleRepeaterChange(e, step) {
        let items = this.state.reportRecepients;

        items[step] = e.target.value;

        this.setState({
            reportRecepients: items
        });
    }


    increaseRecipientCount(e) {
        e.preventDefault();

        let count = this.state.reportRecipientsCount + 1;

        this.setState({
            reportRecipientsCount: count
        });
    }

    decreaseRecipientCount(e) {
        if (e) {
            e.preventDefault();
        }

        let count = this.state.reportRecipientsCount -1;

        if (this.state.selectedReportRecipient < count) {
            this.setState({
                selectedReportRecipient: null
            });
        }

        this.setState({
            reportRecipientsCount: count > 0 ? count : 1,
        })
    }

    removeRecipient(e, step) {
        e.preventDefault();
        console.log(step);

        this.decreaseRecipientCount();

        let recipients = this.state.reportRecepients;
            recipients = removeItemOnce(recipients, step);

        this.setState({
            reportRecepients: recipients
        });
    }


    handleDefaultRecipientChange(e) {
        //Sets state with the appropriate key, to the matching input's value
        this.setState({
            [e.target.name]: parseInt(e.target.value)
        });
    }

    renderRecipients() {
        let recipientsArr = [];

        for (let step = 0; step < this.state.reportRecipientsCount; step++) {
            recipientsArr.push(
                <div className={"mb-3"}>
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <label htmlFor="contactEmail">E-mail address</label>
                            <input type="email" onChange={(e) => this.handleRepeaterChange(e, step)} value={this.state.reportRecepients[step]} className={"form-control"} data-count={step} name={"reportRecepients["+step+"]"} id={"contactEmail"}  />
                        </div>
                        <div className="col-12 col-md-6 d-flex justify-content-between align-items-center">
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="selectedReportRecipient"
                                    id={"selectedReportRecipient-"+step}
                                    value={step}
                                    onChange={this.handleDefaultRecipientChange}
                                    checked={this.state.selectedReportRecipient === step}
                                />
                                <label className="form-check-label" htmlFor={"selectedReportRecipient-"+step}>
                                    Default Recipient
                                </label>
                            </div>

                            <button onClick={(e) => this.removeRecipient(e, step)} className={"btn-min"}>Remove option</button>
                        </div>
                    </div>
                    <div className="row mt-1">
                        <div className="col-12">
                            <button onClick={this.increaseRecipientCount} className={"btn-min ml-3"}>Add additional option</button>
                        </div>
                    </div>
                </div>
            )

        }

        return recipientsArr;
    }

    render() {

        return (
            <div className="container" id="main">
                <div className="row mb-5">
                    <div className="col-12 col-md-8">
                        <Link to={"/dashboard/clients/"} className={"btn-min"}>
                            <FontAwesomeIcon icon={faChevronLeft} />
                            <span className="ml-1">View all Clients</span>
                        </Link>
                        <h1 className={"mt-1"}>Editing Client: {this.state.companyName ? this.state.companyName : null}</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-lg-7">
                        <div className=" no-shadow no-border">
                            <div className="row">
                                <div className="col-12">
                                    <form action="" className={"mb-5"} onSubmit={this.handleSubmit}>

                                        <section className={"mb-5"}>
                                            <h4>Company details</h4>
                                            <div className="row mb-3">
                                                <div className="col-12 col-md-6">
                                                    <label htmlFor="companyName">Company name</label>
                                                    <input type="text" className={"form-control"} name={"companyName"} id={"companyName"} onChange={this.handleChange} value={this.state.companyName} />
                                                </div>
                                                <div className="col-12 col-md-6">
                                                    <label htmlFor="label">Label</label>
                                                    <input type="text" className={"form-control"} name={"label"} id={"label"} onChange={this.handleChange} value={this.state.label} />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col-12 col-md-6">
                                                    <label htmlFor="streetAddress">Street address</label>
                                                    <input type="text" className={"form-control"} name={"streetAddress"} id={"streetAddress"} onChange={this.handleChange} value={this.state.streetAddress} />
                                                </div>
                                                <div className="col-12 col-md-6">
                                                    <label htmlFor="streetNumber">Street number</label>
                                                    <input type="text" className={"form-control"} name={"streetNumber"} id={"streetNumber"} onChange={this.handleChange} value={this.state.streetNumber} />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col-12 col-md-6">
                                                    <label htmlFor="city">City</label>
                                                    <input type="text" className={"form-control"} name={"city"} id={"city"} onChange={this.handleChange} value={this.state.city} />
                                                </div>
                                                <div className="col-12 col-md-6">
                                                    <div className="row">
                                                        <div className="col-12 col-md-6">
                                                            <label htmlFor="zip">ZIP</label>
                                                            <input type="text" className={"form-control"} name={"zip"} id={"zip"} onChange={this.handleChange} value={this.state.zip} />
                                                        </div>
                                                        <div className="col-12 col-md-6">
                                                            <label htmlFor="country">Country</label>
                                                            <input type="text" className={"form-control"} name={"country"} id={"country"} onChange={this.handleChange} value={this.state.country} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 col-md-6">
                                                    <label htmlFor="vat">VAT number</label>
                                                    <input type="text" className={"form-control"} name={"vat"} id={"vat"} onChange={this.handleChange} value={this.state.vat} />
                                                </div>
                                                <div className="col-12 col-md-6">
                                                    <label htmlFor="representedBy">Represented by</label>
                                                    <input type="text" className={"form-control"} name={"representedBy"} id={"representedBy"} onChange={this.handleChange} value={this.state.representedBy} />
                                                </div>
                                            </div>
                                        </section>

                                        <section className={"mb-5"}>
                                            <h4>Contact person</h4>
                                            <div className="row mb-3">
                                                <div className="col-12 col-md-6">
                                                    <label htmlFor="contactPerson">Full name</label>
                                                    <input type="text" className={"form-control"} name={"contactPerson"} id={"contactPerson"} onChange={this.handleChange} value={this.state.contactPerson} />
                                                </div>
                                                <div className="col-12 col-md-6">
                                                    <label htmlFor="contactEmail">E-mail address</label>
                                                    <input type="email" className={"form-control"} name={"contactEmail"} id={"contactEmail"} onChange={this.handleChange} value={this.state.contactEmail} />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 col-md-6">
                                                    <label htmlFor="contactPhone">Phone</label>
                                                    <input type="text" className={"form-control"} name={"contactPhone"} id={"contactPhone"} onChange={this.handleChange} value={this.state.contactPhone} />
                                                </div>
                                            </div>
                                        </section>

                                        <section>
                                            <h4>Report Recipients</h4>
                                            {this.state.reportRecipientsCount ? this.renderRecipients() : null}

                                        </section>


                                        <section>
                                            <h4>General</h4>
                                            <div className="form-group">
                                                <label htmlFor="email">Email</label>
                                                <input type="email" className={"form-control"} name={"email"} id={"email"} disabled value={this.state.email} />
                                            </div>
                                            <div className="row mt-3">
                                                <div className="col-12 col-md-6">
                                                    <div className="form-group">
                                                        <label htmlFor="password1">Password</label>
                                                        <input type="password" className={"form-control"} name={"password1"} id={"password1"} value={this.state.password1} onChange={this.handleChange} />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6">
                                                    <div className="form-group">
                                                        <label htmlFor="password2">Password Confirmation</label>
                                                        <input type="password" className={"form-control"} name={"password2"} id={"password2"} value={this.state.password2} onChange={this.handleChange} />
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="errors">
                                                    <h6 className={"mb-0"}>{this.state.formErrors}</h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-12">
                                                <button type={"submit"} className={"btn btn-primary"}>Submit</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

EditClient.propTypes = {};

export default withRouter(EditClient);
