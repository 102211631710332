import React from "react";
import {Link} from "react-router-dom";
import Store from "store";
import Axios from "axios";
import MakeCodes from "./MakeCodes/MakeCodes";
import CarModels from "./CarModels/CarModels";

class SingleMake extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            token: Store.get('accessToken').token,
            id: this.props.match.params.entryId
        };
    }

    componentDidMount() {
        if (this.state.id) {
            Axios.get(process.env.REACT_APP_API_BASE + '/makes/' + this.state.id, {
                headers: {
                    'Authorization': 'Bearer ' + this.state.token
                },
            }).then(response => {
                console.log(response);
                if (response.status === 200) {
                    this.setState({
                        name: response.data.make.name
                    })
                }
            }).catch(error => {
                console.log('error', error);
                if (error.status === 401) {
                    console.log('attempting reauth');
                }
            });
        }
    }

    render() {
        return (
            <div className="pb-0" id="main">
                <div className="container">
                    <div className="row align-items-center mb-5">
                        <div className="col-12 col-md-8">
                            <h1 className={"my-0"}>Make: {this.state.name}</h1>
                        </div>
                        <div className="col-12 col-md-4 d-flex justify-content-end">
                            <Link to={"/dashboard/vin-decoder/"} className={"btn btn-secondary"}>
                                <span className="ml-1">View all makes</span>
                            </Link>
                        </div>
                    </div>
                    <div className="row mb-5">
                        <div className="col-12">
                            <MakeCodes makeId={this.state.id} />
                        </div>
                    </div>
                    <div className="row mb-5">
                        <div className="col-12">
                            <CarModels makeId={this.state.id}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

SingleMake.propTypes = {};

export default SingleMake;
