import countries from "../fixedData/countries.json";

export const parseCargo = (str) => {
    if (!str) return false;
    console.log(str);

    let strArr = str.split("\n");
    let items = [];

    for (let item of strArr) {
        let [vin, destination, transportCompany, transport, voyage, bl, portOfOrigin, transshipmentPort, portOfDischarging, microLocation, info] = item.split("\t");
        let i = {
            vin,
            destination,
            voyage,
            bl,
            microLocation,
            info
        };

        let country = null;
        if (destination) {
            country = countries?.filter(country => country.code === destination);
        }

        if (country) {
            i.destination = country[0].name;
        }

        if (transportCompany) {
            i.transportCompany = parseInt(transportCompany);
        }
        if (transport) {
            i.transport = parseInt(transport);
        }
        if (portOfOrigin) {
            i.portOfOrigin = parseInt(portOfOrigin);
        }
        if (transshipmentPort) {
            i.transshipmentPort = parseInt(transshipmentPort);
        }
        if (portOfDischarging) {
            i.portOfDischarging = parseInt(portOfDischarging);
        }

        Object.keys(i).forEach(k => i[k] = i[k] || null);
        items.push(i);
    }

    return items;
}
