import React from "react";
import Axios from "axios";
import Admin from "../../Admins/Admin/Admin";
import Make from "./Make/Make";
import Store from "store";

class Makes extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            items: null,
            token: Store.get('accessToken').token,
        };

    }

    componentDidMount() {
        Axios.get(process.env.REACT_APP_API_BASE + '/makes', {
            headers: {
                'Authorization': 'Bearer ' + this.state.token
            },
        } ).then(response => {
            console.log(response);
            if (response.status === 200) {
                this.setState({
                    items: response.data.makes
                })
            }
        }).catch(error => {
            console.log('error', error);
            if (error.status === 401) {
                console.log('attempting reauth');
            }
        });
    }



    render() {
        if (this.state.items) {
            return (
                <div className={"layout-grid-3"}>
                    {
                        this.state.items.map((item, index) => {
                            return (
                                <Make key={"k-" + item.id} item={item} />
                            );
                        })
                    }
                </div>
            );
        } else {
            return null;
        }
    }
}

Makes.propTypes = {};

export default Makes;
