import React from "react";
import {Link, withRouter} from "react-router-dom";
import Orders from "../Pages/Dashboard/Orders/Orders";
import Axios from "axios";
import Store from "store";
import {Form} from "react-bootstrap";
import {isAdmin, isManager, isClient} from "../utils/utils";
import ConditionalContent from "../ConditionalContent";

const phases = [
    {
        id: 1,
        name: 'Announcement'
    },
    {
        id: 2,
        name: 'Order created'
    },
    {
        id: 3,
        name: 'Inspection in Progress'
    },
    {
        id: 4,
        name: 'Inspection Completed'
    },
    {
        id: 5,
        name: 'Preliminary Report Sent'
    },
    {
        id: 6,
        name: 'Final Report Sent'
    },
    {
        id: 7,
        name: 'Order Completed'
    },
    {
        id: 8,
        name: 'Invoice Issued'
    }
];

class GroupedOrders extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            orders: [],
            token: Store.get('accessToken').token,
            role: Store.get('accessToken').role,
            cargoType: ['vehicles', 'container', 'coils', 'ingo']
        };

        this.fetchOrders = this.fetchOrders.bind(this);
        this.handleCargoTypeChange = this.handleCargoTypeChange.bind(this);
    }

    componentDidMount() {
        this.fetchOrders();
    }

    fetchOrders() {
        let config = {};

        config.headers = {
            'Authorization': 'Bearer ' + this.state.token
        };


        config.params = {};
        config.params.cargo_type = this.state.cargoType;

        Axios.get(process.env.REACT_APP_API_BASE + '/orders/wds', config).then(response => {
            if (response.status === 200) {
                this.setState({
                    orders: response.data.orders,
                    orderCount: response.data.orders.length
                });
                this.props.updateCount(response.data.orders.length);
            } else {
                throw new Error('Smt went wrong with fetching orders');
            }
        }).catch(err => {
            console.log(err);
        });
    }

    handleCargoTypeChange(e) {

        if (e.target.checked) {

            if (!this.state.cargoType?.includes(e.target.value)) {
                this.setState({
                    cargoType: [...this.state.cargoType, e.target.value]
                }, () => {
                    this.fetchOrders();
                });
            }
        }
        else {
            if (this.state.cargoType.includes(e.target.value)) {
                this.setState({
                    cargoType: this.state.cargoType.filter(item => item !== e.target.value)
                }, () => {
                    this.fetchOrders();
                });
            }
        }

    }

    render() {
        return (
            <section className={"pt-5"}>
                <div className="container">
                    <div className="row mb-5">
                        <div className="col-12">
                            <Form>
                                <strong className={"me-3"}>Cargo Type</strong>
                                {['vehicles', 'coils', 'container', 'ingo'].map((type, i) => (
                                    <Form.Check
                                        inline
                                        key={`cargo-type-${i}`}
                                        id={`cargo-type-${i}`}
                                        type={"checkbox"}
                                        className={"text-capitalize"}
                                        name={type}
                                        value={type}
                                        label={type}
                                        checked={this.state.cargoType?.includes(type)}
                                        onChange={this.handleCargoTypeChange}
                                    />
                                ))}
                            </Form>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="d-flex justify-content-between align-items-center">
                                <div className={"d-flex align-items-center"}>
                                    <h2 className={"mb-0"}>Open orders and inspections</h2>
                                    <span className="circle-count ms-3">{this.state.orderCount}</span>
                                </div>
                                <div className={"d-flex justify-content-end align-items-center"}>
                                    <Link to={"/dashboard/stock"} className="btn btn-secondary me-2">View Stock</Link>
                                    <Link to={"/dashboard/unallocated"} className="btn btn-secondary me-2">View unallocated items</Link>
                                    <ConditionalContent check={isAdmin()}>
                                        <Link className={"btn btn-primary"} to={"/dashboard/orders/add-new"}>Start new order</Link>
                                    </ConditionalContent>
                                    <ConditionalContent check={isClient()}>
                                        <Link className={"btn btn-primary"} to={"/dashboard/orders/client-add-new"}>Start new order</Link>
                                    </ConditionalContent>
                                    <ConditionalContent check={isManager()}>
                                        <Link className={"btn btn-primary"} to={"/dashboard/orders/manager-add-new"}>Start new order</Link>
                                    </ConditionalContent>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col-12">
                            <div className={"grouped-orders-wrapper layout-grid-1"}>
                                {phases.map(phase => {
                                    if (this.state.orders?.filter(item => item.phase === phase.id).length > 0) {
                                        return (
                                            <div className={"order-group"}>
                                                <h5>{phase.name}</h5>
                                                <Orders items={this.state.orders} phase={phase.id}/>
                                            </div>
                                        )
                                    }


                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        );
    }
}

GroupedOrders.propTypes = {};

export default withRouter(GroupedOrders);
