import React from "react";
import BaseSelect from "react-select";

class ModelFilter extends React.Component {
    constructor(props) {

        super(props);

        this.state = {
            options: []
        };

        this.populateOptions = this.populateOptions.bind(this);
    }

    componentDidMount() {
        this.populateOptions();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.items !== this.props.items) {
            this.populateOptions();
        }
    }

    populateOptions () {
        let options = this.props.items?.map(item => {
            return {
                'value': item[this.props.idField],
                'label': item[this.props.labelField]
            }
        });

        this.setState({options});
    }


    render() {

        return (
            <div>
                <label htmlFor={this.props.elementName}>{this.props.label}</label>
                <BaseSelect
                    closeMenuOnSelect={true}
                    isMulti
                    options={this.state.options}
                    name={this.props.elementName}
                    value={this.props.value}
                    className="basic-multi-select filter-multi-select"
                    classNamePrefix="select"
                    onChange={this.props.handleChange}
                />
            </div>
        );
    }
}

ModelFilter.propTypes = {};

export default ModelFilter;
