import React from "react";
import {generateRandomId, getDamageSetOptions} from "../../../../../utils/utils";
import OptionsInstances from "./OptionsInstances/OptionsInstances";

class Options extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            items: [],
            tempItems: []
        };

        this.addNewInstance = this.addNewInstance.bind(this);
        this.removeNewInstance = this.removeNewInstance.bind(this);
        this.fetchItems = this.fetchItems.bind(this);
    }

    componentDidMount() {
        this.fetchItems().then(r=> {

        })
            .catch(err => {
                console.log('smt went wrong');
            });
    }

    fetchItems() {
        return getDamageSetOptions(this.props.parentId, this.props.type)
            .then(resp => {
                this.setState({
                    items: resp
                })
            })
            .catch(err => {
                console.log(err);
            });
    }


    addNewInstance() {
        let tempId = generateRandomId(this.props.type);
        let tempArr = this.state.tempItems;

        if (!tempArr.includes(tempId)) {
            tempArr.push(tempId);
            this.setState({
                tempItems: tempArr
            });
        } else {
            this.addNewInstance();
        }
    }

    removeNewInstance(tempId) {
        if(!tempId) return;
        let instances = [];
        instances = this.state.tempItems;

        let newInstances = instances.filter( e => {
            return e !== tempId;
        });

        this.setState({
            tempItems: newInstances
        });
    }

    render() {
        return (
            <div>
                <div className="layout-grid-1 mt-3">
                    <OptionsInstances items={this.state.items} {...this.props} updateItems={this.fetchItems} removeItem={this.removeNewInstance}/>
                    <OptionsInstances items={this.state.tempItems} {...this.props} updateItems={this.fetchItems} removeItem={this.removeNewInstance}/>
                </div>
                <div className={"mt-2"}>
                    <button onClick={() => {this.addNewInstance()}} className={"btn-min text-secondary"}>Add new option</button>
                </div>
            </div>
        );
    }
}

Options.propTypes = {};

export default Options;
