/* ---------- */
/*    Data    */
/* ---------- */
import Autosuggest from 'react-autosuggest';
import SearchSuggestion from "../SearchSuggestion/SearchSuggestion";
import React from "react";
import Axios from "axios";
import Store from "store";
import {withRouter} from "react-router-dom";





function fetchCargoItems(vin) {


    return new Promise(async (resolve, reject) => {
        let config = {};


        config.headers = {
            'Authorization': 'Bearer ' + Store.get('accessToken').token
        };

        if (vin) {
            config.params = {};
            config.params['vin'] = vin;
        }



        Axios.get(process.env.REACT_APP_API_BASE + '/items', config).then(response => {

            if (response.status === 200) {

                if (response.data.items) {

                    resolve(response.data.items);
                }

            } else {
                throw new Error('Smt went wrong with fetching orders');
            }
        }).catch(err => {
            console.log(err);
        });
    });


}

/* ----------- */
/*    Utils    */
/* ----------- */

// https://developer.mozilla.org/en/docs/Web/JavaScript/Guide/Regular_Expressions#Using_Special_Characters
function escapeRegexCharacters(str) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

/* --------------- */
/*    Component    */
/* --------------- */

function getSuggestionValue(suggestion) {
    return suggestion.name;
}

function renderSuggestion(suggestion) {
    return (
        <SearchSuggestion suggestion={suggestion}/>
    );
}

function getMatchingItems(value) {

    return new Promise((resolve, reject) => {
        const escapedValue = escapeRegexCharacters(value?.trim());

        if (escapedValue === '') {
            return [];
        }

        fetchCargoItems(value).then(r => {
            resolve(r);
        });
    });
}


class Search extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            value: '',
            suggestions: [],
            isLoading: false
        };

        this.lastRequestId = null;

        this.loadSuggestions = this.loadSuggestions.bind(this);
        this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this);
        this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this);
        this.suggestionSelected = this.suggestionSelected.bind(this);
    }

    loadSuggestions(value) {
        // Cancel the previous request
        if (this.lastRequestId !== null) {
            clearTimeout(this.lastRequestId);
        }

        this.setState({
            isLoading: true
        });

        getMatchingItems(value).then(suggestions => {

            this.lastRequestId = setTimeout(() => {
                this.setState({
                    isLoading: false,
                    suggestions
                });
            }, 1);
        })

    }

    onChange = (event, { newValue }) => {
        this.setState({
            value: newValue
        });
    };

    onSuggestionsFetchRequested = ({ value }) => {
        this.loadSuggestions(value);
    };



    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    suggestionSelected(event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) {
        this.setState({
            value: ''
        },() => {
            // console.log(this.props.history);
            this.props.history.push('/dashboard/orders/order/'+suggestion.order_id);
            // this.context.history.push('/path')
        });
    };

    render() {
        const { value, suggestions, isLoading } = this.state;
        const inputProps = {
            placeholder: "Enter VIN",
            value,
            onChange: this.onChange
        };


        return (
            <div>
                <Autosuggest
                    suggestions={suggestions}
                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                    onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                    getSuggestionValue={getSuggestionValue}
                    renderSuggestion={renderSuggestion}
                    onSuggestionSelected={this.suggestionSelected}
                    focusInputOnSuggestionClick={false}
                    inputProps={inputProps}

                />
            </div>
        );
    }
}
withRouter.propTypes = {};
export default withRouter(Search);
