import React from "react";
import {Link} from "react-router-dom";
import Style from "./DamageSetsShortcuts.module.scss";
import Swal from "sweetalert2";
import Axios from "axios";
import Store from "store";
import withReactContent from 'sweetalert2-react-content';
import DsInputShortcut from "../Orders/SingleOrder/CargoItem/DamagesWrapper/Damage/DsInput/DsInputShortcut";
import {getDamageSets, getDamageSetsById} from "../../../utils/utils";
import BaseSelect from 'react-select';

const AddNewShortcutModal = withReactContent(Swal);

class DamageSetsShortcuts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            token: Store.get('accessToken').token,
            dsPart: null,
            dsType: null,
            dsSeverity: null,
            dsCause: null,
            dsRepairMode: null,
            dsClass: null,
            dsDln: null,
            damageSetsArr: [],
            selectedDamageSetId: null,
            damageSetOptions: [],
        };


        this.renderItems = this.renderItems.bind(this);
        this.fetchItems = this.fetchItems.bind(this);
        this.openAddNewShortcutModal = this.openAddNewShortcutModal.bind(this);
        this.renderAddNewShortcutContent = this.renderAddNewShortcutContent.bind(this);
        this.handleDamageSetChange = this.handleDamageSetChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }


    componentDidMount() {
        // Get DamageSets
        let tempDamageSets = [];
        getDamageSets()
        .then(resp => {
            for (const item of resp) {
                tempDamageSets.push({
                    'value': item.id,
                    'label': item.name
                });
            }
        })
        .then(() => {
            this.setState({
                damageSetsArr: tempDamageSets
            });
        });    

        if (this.state.token) {
            this.fetchItems();
        }
    }

    openAddNewShortcutModal() {
        AddNewShortcutModal.fire({
            title: 'Create new damageset shortcut',
            showConfirmButton: false,
            width: '60%',
            html: this.renderAddNewShortcutContent(),
            
        })
    }

    handleSubmit() {

    }

    handleDamageSetChange(obj, e) {
        

        getDamageSetsById(obj.value)
        .then(resp => {
            this.setState({
                damageSetOptions: resp.damageSetOptions
            })
            console.log(this.state. damageSetOptions);
        })
    }

    renderAddNewShortcutContent() {
        return (<div>
            <form action="" onSubmit={this.handleSubmit}>
                <div className="row mb-3">
                    <div className="col-6 col-lg-6">
                        <label className="mb-2" htmlFor="name">Shortcut name</label>
                        <input type="text" className={"form-control"} name={"name"} id={"name"} value={this.state.name} onChange={this.handleChange}/>
                    </div>
                    <div className="col-6 col-lg-6">
                        <label className="mb-2">Damage set</label>
                        <div className="">
                        <BaseSelect
                                            closeMenuOnSelect={true}
                                            options={this.state.damageSetsArr}
                                            name="damageSetId"
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            onChange={this.handleDamageSetChange}
                                        />
                        </div>
                    </div>
                    <label className="mt-2 mb-2">Damage set options</label>
                    <div className="layout-grid-7">
                        <div className="ds-item">
                            <DsInputShortcut existingValue={this.state.dsPart} type={"part"} label={"Part"} name={"dsPart"} updateParent={this.updateDs} damageSetOptions={this.state.damageSetOptions} />
                        </div>
                        <div className="ds-item">
                            <DsInputShortcut existingValue={this.state.dsType} type={"type"} label={"Type"} name={"dsType"} updateParent={this.updateDs} damageSetOptions={this.state.damageSetOptions}/>
                        </div>
                        <div className="ds-item">
                            <DsInputShortcut existingValue={this.state.dsSeverity} type={"severity"} label={"Severity"} name={"dsSeverity"} updateParent={this.updateDs} damageSetOptions={this.state.damageSetOptions}/>
                        </div>
                        <div className="ds-item">
                            <DsInputShortcut existingValue={this.state.dsCause} type={"cause"} label={"Cause"} name={"dsCause"} updateParent={this.updateDs} damageSetOptions={this.state.damageSetOptions}/>
                        </div>
                        <div className="ds-item">
                            <DsInputShortcut existingValue={this.state.dsRepairMode} type={"repair_mode"} label={"Repair Mode"} name={"dsRepairMode"} updateParent={this.updateDs} damageSetOptions={this.state.damageSetOptions}/>
                        </div>
                        <div className="ds-item">
                            <DsInputShortcut existingValue={this.state.dsClass} type={"class"} label={"Class"} name={"dsClass"} updateParent={this.updateDs} damageSetOptions={this.state.damageSetOptions}/>
                        </div>
                        <div className="ds-item">
                            <DsInputShortcut existingValue={this.state.dsDln} type={"dln"} label={"DLN"} name={"dsDln"} updateParent={this.updateDs} damageSetOptions={this.state.damageSetOptions}/>
                        </div>
                    </div>
                </div>     
                
                               

                <div className={"mt-4"}>
                    <button type={"submit"} className={"btn btn-primary"}>Create shortcut</button>
                </div>                    

            </form>

        </div>
        )
    }

    fetchItems() {
        Axios.get(process.env.REACT_APP_API_BASE + '/shortcuts', {
            headers: {
                'Authorization': 'Bearer ' + this.state.token
            },
        } ).then(response => {
            console.log(response);
            if (response.status === 200) {
                this.setState({
                    items: response.data.shortcuts
                })
            }
        }).catch(error => {
            console.log('error', error);
            if (error.status === 401) {
                console.log('attempting reauth');
            }
        });
    }


    renderItems() {
        if (this.state.items) {
            return this.state.items.map((item, index) => {
                console.log(item);
                return (
                    <Link className={Style.item + ' card no-shadow'} to={"/dashboard/damage-set-shortcut/edit/"+item.id}>
                        <div className="card-body bg-white py-3">
                            <span className="d-block mb-1">Name: {item.name}</span>
                            <span className={"d-block strong"}>Damage set: {item.damage_set_name}</span>
                        </div>
                    </Link>
                );
            });
        }  
    }

    render() {

        return (
            <div className="container" id="main">
                <div className="row mb-5">
                    <div className="col-12 d-flex justify-content-between align-items-center">
                        <h1 className={"mb-0"}>Damage Sets Shortcuts</h1>
                        <Link to={"/dashboard/new-damage-set-shortcut"} className={"btn btn-primary"}>Add new</Link>

                        {/*<button onClick={this.openAddNewShortcutModal} className={"btn btn-primary"}>Add new</button>*/}
                        
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className=" no-shadow no-border">
                            <div className="layout-grid-4 small-gap">
                                {this.renderItems()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

DamageSetsShortcuts.propTypes = {};

export default DamageSetsShortcuts;
