import React from "react";

class Attachment extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        return (
            <div className={"d-flex"}>
                <a href={this.props.data.path} target={"_blank"} rel={"noreferrer"}>
                    <span className="name d-block">{this.props.data.filename || 'Untitled'}</span>
                </a>
            </div>
        );
    }
}

Attachment.propTypes = {};

export default Attachment;
