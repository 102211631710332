import React from "react";
import {Link, withRouter} from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

import Axios from "axios";
import Store from "store";

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import DsInputShortcut from "../../Orders/SingleOrder/CargoItem/DamagesWrapper/Damage/DsInput/DsInputShortcut";
import BaseSelect from 'react-select';
import {getDamageSets, getDamageSetsById} from "../../../../utils/utils";
import ConditionalContent from "../../../../ConditionalContent";

const SuccessModal = withReactContent(Swal);

class AddNewDamageSetShortcut extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            token: Store.get('accessToken').token,
            dsPart: null,
            dsType: null,
            dsSeverity: null,
            dsCause: null,
            dsRepairMode: null,
            dsClass: null,
            dsDln: null,
            damageSetsArr: [],
            selectedDamageSetId: null,
            damageSetOptions: [],
        };


        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.fetchItems = this.fetchItems.bind(this);
        this.handleDamageSetChange = this.handleDamageSetChange.bind(this);
        this.updateDs = this.updateDs.bind(this);
    }

    componentDidMount() {
        // Get DamageSets
        let tempDamageSets = [];
        getDamageSets()
        .then(resp => {
            for (const item of resp) {
                tempDamageSets.push({
                    'value': item.id,
                    'label': item.name
                });
            }
        })
        .then(() => {
            this.setState({
                damageSetsArr: tempDamageSets
            });
        });    

        if (this.state.token) {
            this.fetchItems();
        }
    }

    fetchItems() {
        Axios.get(process.env.REACT_APP_API_BASE + '/shortcuts', {
            headers: {
                'Authorization': 'Bearer ' + this.state.token
            },
        } ).then(response => {
            console.log(response);
            if (response.status === 200) {
                this.setState({
                    items: response.data.shortcuts
                })
            }
        }).catch(error => {
            console.log('error', error);
            if (error.status === 401) {
                console.log('attempting reauth');
            }
        });
    }


    handleSubmit(e) {
        e.preventDefault();

        let formData = {
            damage_set_id: this.state.selectedDamageSetId,
            name: this.state.name,
            ds_part: this.state.dsPart !== null ? this.state.dsPart.id : '',
            ds_type: this.state.dsType !== null ? this.state.dsType.id : '',
            ds_severity: this.state.dsSeverity !== null ? this.state.dsSeverity.id : '',
            ds_cause: this.state.dsCause !== null ? this.state.dsCause.id : '',
            ds_repair_mode: this.state.dsRepairMode !== null ? this.state.dsRepairMode.id : '',
            ds_class: this.state.dsClass !== null ? this.state.dsClass.id : '',
            ds_dln: this.state.dsDln !== null ? this.state.dsDln.id : '',
        };

        console.log('submit shortcut');
        console.log(formData);

        Axios.post(process.env.REACT_APP_API_BASE + '/shortcuts', formData, {
            headers: {
                'Authorization': 'Bearer ' + this.state.token
            }
        } )
            .then(response => {
                console.log(response);
                if (response.status === 200) {
                    SuccessModal.fire({
                        title: 'Successfully added Damage Set',
                    }).then(() => {
                        this.props.history.push('/dashboard/damage-sets-shortcuts');
                    });
                }
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    'formErrors': error.response.data.message
                })
            })
    }

    updateDs(name, value) {
        this.setState({
            [name]: value
        })
    }

    handleChange(e) {

        //Sets state with the appropriate key, to the matching input's value
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handleDamageSetChange(obj, e) {

        getDamageSetsById(obj.value)
        .then(resp => {
            console.log(resp);
            this.setState({
                selectedDamageSetId: obj.value,
                damageSetOptions: resp.damageSetOptions
            })
        })
    }

    render() {

        return (
            <div className="container" id="main">
                <div className="row mb-5">
                    <div className="col-12 col-md-8">
                        <Link to={"/dashboard/damage-sets/"} className={"btn-min"}>
                            <FontAwesomeIcon icon={faChevronLeft} />
                            <span className="ml-1">View all damage set shortcuts</span>
                        </Link>
                        <h1 className={"mt-1"}>Add new Damage Set Shortcut</h1>
                    </div>
                </div>
            <form action="" onSubmit={this.handleSubmit}>
                <div className="row mb-3">
                    <div className="col-6 col-lg-6">
                        <label className="mb-2" htmlFor="name">Shortcut name</label>
                        <input type="text" className={"form-control"} name={"name"} id={"name"} value={this.state.name} onChange={this.handleChange}/>
                    </div>
                    <div className="col-6 col-lg-6">
                        <label className="mb-2">Damage set</label>
                        <div className="">
                        <BaseSelect
                                            closeMenuOnSelect={true}
                                            options={this.state.damageSetsArr}
                                            name="damageSetId"
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            onChange={this.handleDamageSetChange}
                                        />
                        </div>
                    </div>
                    <label className="mt-2 mb-2">Damage set options</label>
                    <ConditionalContent check={this.state.damageSetOptions.length !== 0}>
                        <div className="layout-grid-7">

                            <div className="ds-item">
                                <DsInputShortcut existingValue={this.state.dsPart} type={"part"} label={"Part"} name={"dsPart"} updateParent={this.updateDs} damageSetOptions={this.state.damageSetOptions} />
                            </div>
                            <div className="ds-item">
                                <DsInputShortcut existingValue={this.state.dsType} type={"type"} label={"Type"} name={"dsType"} updateParent={this.updateDs} damageSetOptions={this.state.damageSetOptions}/>
                            </div>
                            <div className="ds-item">
                                <DsInputShortcut existingValue={this.state.dsSeverity} type={"severity"} label={"Severity"} name={"dsSeverity"} updateParent={this.updateDs} damageSetOptions={this.state.damageSetOptions}/>
                            </div>
                            <div className="ds-item">
                                <DsInputShortcut existingValue={this.state.dsCause} type={"cause"} label={"Cause"} name={"dsCause"} updateParent={this.updateDs} damageSetOptions={this.state.damageSetOptions}/>
                            </div>
                            <div className="ds-item">
                                <DsInputShortcut existingValue={this.state.dsRepairMode} type={"repair_mode"} label={"Repair Mode"} name={"dsRepairMode"} updateParent={this.updateDs} damageSetOptions={this.state.damageSetOptions}/>
                            </div>
                            <div className="ds-item">
                                <DsInputShortcut existingValue={this.state.dsClass} type={"class"} label={"Class"} name={"dsClass"} updateParent={this.updateDs} damageSetOptions={this.state.damageSetOptions}/>
                            </div>
                            <div className="ds-item">
                                <DsInputShortcut existingValue={this.state.dsDln} type={"dln"} label={"DLN"} name={"dsDln"} updateParent={this.updateDs} damageSetOptions={this.state.damageSetOptions}/>
                            </div>
                        </div>
                    </ConditionalContent>
                    <ConditionalContent check={this.state.damageSetOptions.length === 0}>
                        <p>Damage set is not selected.</p>
                    </ConditionalContent>
                </div>     
                
                               

                <div className={"mt-4"}>
                    <button type={"submit"} className={"btn btn-primary"}>Create shortcut</button>
                </div>                    

            </form>
            </div>
        );
    }
}

AddNewDamageSetShortcut.propTypes = {};

export default withRouter(AddNewDamageSetShortcut);
