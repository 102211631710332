import React from "react";
import Option from "./Option/Option";

class OptionsInstances extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};

    }

    render() {
        if (this.props.items && this.props.items.length > 0) {
            return this.props.items.map(item => {

                let key = item.id ? 'do-' + item.type + '-' + item.id : 'do-temp-'+item;
                return <Option item={item} {...this.props} key={key}/>
            });
        } else {
            return null;
        }
    }
}

OptionsInstances.propTypes = {};

export default OptionsInstances;
