import React from "react";
import {deleteImage, getImage} from "../../../../../../../../../utils/utils";
import {faDownload, faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Style from "./Image.module.scss";
import { saveAs } from 'file-saver';
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
const confirmDelete = withReactContent(Swal);

class Image extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            id: this.props.imgId,
            data: null
        };

        this.deleteImage = this.deleteImage.bind(this);
    }

    componentDidMount() {

        if (this.state.id) {
            getImage(this.state.id).then(resp => {
                this.setState({
                    data: resp
                });
            })
        }
    }

    deleteImage(e) {
        e.preventDefault();

        confirmDelete.fire({
            title: 'Are you sure you want to delete?',
            showCancelButton: true,
        })
            .then((r) => {
                if (r.isConfirmed) {
                    return deleteImage(this.state.id);
                } else {
                    return false;
                }
            })
            .then(r => {
                if (r !== false) {
                    this.props.refreshImages();
                }
            })
            .catch(err => {
                console.log(err);
            });


    }

    render() {
        if (this.state.data) {
            return (
                <div className={"dmg-img-wrapper position-relative"}>
                    <div className={Style.controls}>
                        <button className={Style.control} onClick={this.deleteImage}>
                            <FontAwesomeIcon icon={faTrash} />
                        </button>
                        <button rel={"noreferrer"}
                                onClick={(e) => {
                                    e?.preventDefault();
                                    fetch(this.state.data.path)
                                        .then(res => res.blob())
                                        .then(blob => saveAs(blob, 'filename'))
                                        .catch(err => console.log(err))
                                }
                                }
                                className={Style.control} >
                            <FontAwesomeIcon icon={faDownload} />
                        </button>
                    </div>
                    <img className={"mw-100"} src={this.state.data.path} alt={`Damage image ${this.state.id}`}/>
                </div>
            );
        } else {
            return null;
        }
    }
}

Image.propTypes = {};

export default Image;
