import React from "react";
import {Link} from "react-router-dom";


import Axios from "axios";
import Store from "store";

import Users from "./Users/Users";
import {Dropdown} from "react-bootstrap";
import Style from './allUsers.module.scss';

const roles = [
    {
        role: 'admin',
        name: "Administrators"
    },
    {
        role: 'accounting',
        name: 'Accounting'
    },
    {
        role: 'inspector',
        name: 'Inspectors'
    },
    {
        role: 'manager',
        name: 'Managers'
    }
]

class AllUsers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            token: Store.get('accessToken').token
        };

        this.renderItems = this.renderItems.bind(this);
        this.fetchItems = this.fetchItems.bind(this);
    }


    componentDidMount() {
        if (this.state.token) {
            this.fetchItems();
        }
    }

    fetchItems() {
        Axios.get(process.env.REACT_APP_API_BASE + '/users', {
            headers: {
                'Authorization': 'Bearer ' + this.state.token
            },
        } ).then(response => {
            if (response.status === 200) {
                this.setState({
                    items: response.data
                })
            }
        }).catch(error => {
            console.log('error', error);
        });
    }


    renderItems() {
        if(this.state.items) {
            if(this.state.items) {
                return roles.map(role => {
                    let role_items = this.state.items.filter(item => item.role === role.role);
                    console.log(role_items);
                    return (
                        <div className="allusers mb-5">
                            <h3 className="mb-2">{role.name}</h3>
                            <Users role={role.role} items={role_items} />
                        </div>
                    )

                })
            }
        }
    }

    render() {

        return (
            <div className="container" id="main">
                <div className="row mb-5">
                    <div className="col-12 d-flex justify-content-between align-items-center">
                        <h1 className={"mb-0"}>Users</h1>
                        <Dropdown>
                            <Dropdown.Toggle className={Style.addNewUser} variant="success" id="">
                                Add new user
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => {return this.props.history.push("/dashboard/admins/add-new");}}>Administrator</Dropdown.Item>
                                <Dropdown.Item onClick={() => {return this.props.history.push("/dashboard/accountants/add-new");}}>Accountant</Dropdown.Item>
                                <Dropdown.Item onClick={() => {return this.props.history.push("/dashboard/inspectors/add-new");}}>Inspector</Dropdown.Item>
                                <Dropdown.Item onClick={() => {return this.props.history.push("/dashboard/managers/add-new");}}>Manager</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="">
                                {this.renderItems()}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

AllUsers.propTypes = {};

export default AllUsers;