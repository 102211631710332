import React from "react";
import {NavLink, withRouter} from "react-router-dom";
import './SideNavigation.scss';
import {isAdmin} from "../../../utils/utils";
import ConditionalContent from "../../../ConditionalContent";
import Store from "store";

class SideNavigation extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            open: true,
            role: Store.get('accessToken').role
        };

        this.toggleSideNav = this.toggleSideNav.bind(this);
    }

    toggleSideNav() {
        this.setState({
            open: !this.state.open
        });
    }

    render() {
        return (
            <div id="side-navigation" className={(this.state.open) ? 'open' : 'closed'}>
                <div className="menu">
                    
                    <ul className="nav-menu">

                        <li className="menu-item">
                            <NavLink to="/dashboard/" activeClassName="selected" exact>
                                <span className={"label"}>Dashboard</span>
                            </NavLink>
                        </li>
                        <li className="menu-item has-border">
                            <NavLink to="/dashboard/orders" activeClassName="selected">
                                <span className={"label"}>Orders</span>
                            </NavLink>
                        </li>

                        <ConditionalContent check={isAdmin()} className={"mt-auto"}>
                            <li className="menu-item settings">
                                <span>Settings</span>
                            </li>
                            <li className="menu-item small">
                                <NavLink to="/dashboard/users" activeClassName="selected">
                                    <span className={"label"}>Users</span>
                                </NavLink>
                            </li>
                            <li className="menu-item small">
                                <NavLink to="/dashboard/clients" activeClassName="selected">
                                    <span className={"label"}>Clients</span>
                                </NavLink>
                            </li>
                            <li className="menu-item small">
                                <NavLink to="/dashboard/transports" activeClassName="selected">
                                    <span className={"label"}>Transports</span>
                                </NavLink>
                            </li>
                            <li className="menu-item small">
                                <NavLink to="/dashboard/transport-companies" activeClassName="selected">
                                    <span className={"label"}>Transport Companies</span>
                                </NavLink>
                            </li>
                            <li className="menu-item small">
                                <NavLink to="/dashboard/ports" activeClassName="selected">
                                    <span className={"label"}>Ports</span>
                                </NavLink>
                            </li>
                            <li className="menu-item small">
                                <NavLink to="/dashboard/damage-sets" activeClassName="selected">
                                    <span className={"label"}>Damage Sets</span>
                                </NavLink>
                            </li>
                            <li className="menu-item small">
                                <NavLink to="/dashboard/damage-sets-shortcuts" activeClassName="selected">
                                    <span className={"label"}>Damage Sets Shortcuts</span>
                                </NavLink>
                            </li>
                            <li className="menu-item small">
                                <NavLink to="/dashboard/vin-decoder" activeClassName="selected">
                                    <span className={"label"}>Vin Decoder</span>
                                </NavLink>
                            </li>
                            <li className="menu-item small">
                                <NavLink to="/dashboard/mapping" activeClassName="selected">
                                    <span className={"label"}>Mapping</span>
                                </NavLink>
                            </li>
                            <li className="menu-item small">
                                <NavLink to="/dashboard/destinations" activeClassName="selected">
                                    <span className={"label"}>Destinations</span>
                                </NavLink>
                            </li>
                        </ConditionalContent>
                    </ul>
                </div>
            </div>
        );
    }
}


export default withRouter(SideNavigation);
