import React from "react";
import {WiRain, WiCloud, WiDaySunny, WiFog, WiSnow, WiHail} from "react-icons/wi";
import Style from "./Inspection.module.scss";
import ParentStyle from "../CargoItem.module.scss";
import {displayDate, getInspector} from "../../../../../../utils/utils";
import AllUsers from "../../../../AllUsers/AllUsers";

class Inspection extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            inspector: null
        };

        this.displayTemp = this.displayTemp.bind(this);
        this.fetchInspector = this.fetchInspector.bind(this);
    }

    componentDidMount() {
        this.fetchInspector();
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.fetchInspector();
        }
    }

    fetchInspector() {
        
        if (this.props.data && this.props.data['inspected_by']) {

            this.setState({
                inspector: this.props.allUsers.find(x => x.id === this.props.data.inspected_by)
            })
            


            /*getInspector(this.props.data.inspected_by)
                .then(r => {
                    this.setState({
                        inspector: r
                    })
                })
                .catch(err => {
                    console.log(err);
               });*/
        }
    }


    displayIcon(weather) {
        let weatherIcon = null;

        if (weather) {
            // eslint-disable-next-line default-case
            switch (weather) {
                case "cloudy":
                    weatherIcon = <WiCloud />;
                    break;
                case "sunny":
                    weatherIcon = <WiDaySunny />;
                    break;
                case "rain":
                    weatherIcon = <WiRain />;
                    break;
                case "fog":
                    weatherIcon = <WiFog />;
                    break;
                case "snow":
                    weatherIcon = <WiSnow />;
                    break;
                case "hail":
                    weatherIcon = <WiHail />;
                    break;
            }
        }

        return weatherIcon;
    }

    displayTemp() {
        if (this.props.data.temperature) {
            return (
                <span className="temperature">{this.props.data.temperature}° C</span>
            )
        }
    }

    render() {
        if (this.props.data) {
            return (
                <>
                    <div className={ParentStyle.inlineMetaWrapper}>
                        <span className={ParentStyle.label}>Inspected by: {this.state.inspector ? this.state.inspector.name : null}</span>
                        <span className={ParentStyle.value}>({this.props.data? displayDate(this.props.data['created_at']) : null})</span>
                    </div>
                    <div className={Style.item + " d-flex align-items-center"}>
                        <span className={Style.icon} title={this.props.data.weather}>{this.displayIcon(this.props.data.weather)}</span>
                        {this.displayTemp()}
                    </div>
                </>

            );
        } else {
            return null;
        }
    }
}

Inspection.propTypes = {};

export default Inspection;
