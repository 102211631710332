import React from "react";
import Axios from "axios";
import Store from "store";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
const SuccessModal = withReactContent(Swal);

class ModelCode extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            position: this.props.data ? this.props.data.position : '',
            code: this.props.data ? this.props.data.code : '',
            isTemp: !!this.props.tempId,
            token: Store.get('accessToken').token
        };


        this.removeCode = this.removeCode.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.updateCode = this.updateCode.bind(this);
        this.addNewCode = this.addNewCode.bind(this);
    }

    componentDidMount() {
        console.log('single instance', this.props);
    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        }, () => {
            console.log(this.state);
        });
    }

    handleSubmit(e) {
        e.preventDefault();

        if (this.state.isTemp) {
            this.addNewCode().then(() => {
                this.props.removeInstance(this.props.tempId);
                this.props.refreshCodes();
            });
        } else {
            this.updateCode();
        }
    }

    updateCode() {
        return new Promise((resolve, reject) => {
            let data = {
                position: this.state.position,
                code: this.state.code,
            };

            Axios.put(process.env.REACT_APP_API_BASE + '/modelcodes/'+this.props.codeId, data, {
                headers: {
                    'Authorization': 'Bearer ' + this.state.token
                }
            } )
                .then(response => {
                    console.log(response);
                    if (response.status === 200) {
                        SuccessModal.fire({
                            title: 'Successfully updated model code',
                        });
                        resolve('success');
                    }
                })
                .catch(error => {
                    console.log(error);
                    this.setState({
                        'formErrors': error.response.data.message
                    }, () => {
                        reject(error.response.data.message);
                    });
                })
        });
    }

    addNewCode() {
        return new Promise((resolve, reject) => {
            let data = {
                position: this.state.position,
                code: this.state.code,
                "model_id": this.props.modelId,
                "make_id": parseInt(this.props.makeId)
            };


            Axios.post(process.env.REACT_APP_API_BASE + '/modelcodes', data, {
                headers: {
                    'Authorization': 'Bearer ' + this.state.token
                }
            } )
                .then(response => {
                    if (response.status === 201) {
                        SuccessModal.fire({
                            title: 'Successfully added model code',
                        });
                        resolve('success');
                    }
                })
                .catch(error => {
                    console.log(error);
                    this.setState({
                        'formErrors': error.response.data.message
                    }, () => {
                        reject(error.response.data.message);
                    });
                })
        });
    }




    removeCode(e) {
        e.preventDefault();
        let code = this.state.isTemp ? this.props.tempId : this.props.codeId;

        if (this.state.isTemp) {
            this.props.removeInstance(code);
        } else {
            this.props.removeCode(code);
        }
    }

    render() {
        return (
            <div>
                <div >
                    <form action="" onSubmit={this.handleSubmit}>

                        <div className="row align-items-end">
                            <div className="col-12 col-md-9">
                                <div className="row">
                                    <div className="col-auto">
                                        <label className="" htmlFor="position">Starting position</label>
                                        <input type="number" className="form-control" id="position" placeholder="Starting position" required value={this.state.position} onChange={this.handleChange} name={"position"}/>
                                    </div>
                                    <div className="col-auto">
                                        <label className="" htmlFor="location">Code</label>
                                        <input type="text" className="form-control" id="code" placeholder="Code" required value={this.state.code} onChange={this.handleChange} name={"code"}/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-3">
                                <div className={"d-flex justify-content-end"}>
                                    <button type={"submit"} className={"btn btn-primary me-2"}>Update</button>
                                    <button onClick={this.removeCode} className={"btn btn-primary"}>Remove</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

ModelCode.propTypes = {};

export default ModelCode;
