import React from "react";
import Axios from "axios";
import Store from "store";
import { saveAs } from 'file-saver';
import { WithContext as ReactTags } from 'react-tag-input';

const KeyCodes = {
    comma: 188,
    enter: 13
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

class CreatePreliminaryReport extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            token: Store.get('accessToken').token,
            id: this.props.match.params.entryId,
            mappings: [],
            make: false,
            model: false,
            vin: false,
            part: false,
            type: false,
            severity: false,
            cause: false,
            repair_mode: false,
            class: false,
            dln: false,
            comment: false,
            first_seen: false,
            inspection_place: false,
            microlocation: false,
            country: false,
            port_of_loading: false,
            transshipment_port: false,
            port_of_discharging: false,
            inspected_by: false,
            transport_company: false,
            transport_id: false,
            transport_type: false,
            order: false,
            privacyPolicies: [],
            privacyPolicy: '',
            custom_comment: '',
            order_id: false,
            recipients: []
        };
        this.radioSelected = this.radioSelected.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleAddition = this.handleAddition.bind(this);
        this.handleMappingChange = this.handleMappingChange.bind(this);

    }

    componentDidMount() {
        Axios.get(process.env.REACT_APP_API_BASE + '/privacypolicies', {
            headers: {
                'Authorization': 'Bearer ' + this.state.token
            }
        }).then(res => {
            this.setState({
                privacyPolicies: res.data.privacy_policies
            })
        })

        Axios.get(process.env.REACT_APP_API_BASE + '/mappings', {
            headers: {
                'Authorization': 'Bearer ' + this.state.token
            }
        })
            .then(res => {
                this.setState({
                    mappings: res.data
                })
            })
    }

    radioSelected(e) {
        let selection = e.target.value;
        if(selection === 'true') {
            this.setState({
                [e.target.name]: true
            })
        } else {
            this.setState({
                [e.target.name]: false
            })
        }
    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleMappingChange(e) {
        Axios.get(process.env.REACT_APP_API_BASE + '/mappings/' + e.target.value, {
            headers: {
                'Authorization': 'Bearer ' + this.state.token
            }
        })
            .then(res => {
                let data = res.data;
                console.log(res.data);
                console.log(res.data.make);
                if(data.make === 1) {
                    this.setState({
                        make: true
                    })
                } else {
                    this.setState({
                        make: false
                    })
                }

                if(data.model === 1) {
                    this.setState({
                        model: true
                    })
                } else {
                    this.setState({
                        model: false
                    })
                }

                if(data.vin === 1) {
                    this.setState({
                        vin: true
                    })
                } else {
                    this.setState({
                        vin: false
                    })
                }

                if(data.part === 1) {
                    this.setState({
                        part: true
                    })
                } else {
                    this.setState({
                        part: false
                    })
                }

                if(data.type === 1) {
                    this.setState({
                        type: true
                    })
                } else {
                    this.setState({
                        type: false
                    })
                }

                if(data.severity === 1) {
                    this.setState({
                        severity: true
                    })
                } else {
                    this.setState({
                        severity: false
                    })
                }

                if(data.cause === 1) {
                    this.setState({
                        cause: true
                    })
                } else {
                    this.setState({
                        cause: false
                    })
                }

                if(data.repair_mode === 1) {
                    this.setState({
                        repair_mode: true
                    })
                } else {
                    this.setState({
                        repair_mode: false
                    })
                }

                if(data.class === 1) {
                    this.setState({
                        class: true
                    })
                } else {
                    this.setState({
                        class: false
                    })
                }

                if(data.dln === 1) {
                    this.setState({
                        dln: true
                    })
                } else {
                    this.setState({
                        dln: false
                    })
                }

                if(data.comment === 1) {
                    this.setState({
                        comment: true
                    })
                } else {
                    this.setState({
                        comment: false
                    })
                }

                if(data.first_seen === 1) {
                    this.setState({
                        first_seen: true
                    })
                } else {
                    this.setState({
                        first_seen: false
                    })
                }

                if(data.inspection_place === 1) {
                    this.setState({
                        inspection_place: true
                    })
                } else {
                    this.setState({
                        inspection_place: false
                    })
                }

                if(data.microlocation === 1) {
                    this.setState({
                        microlocation: true
                    })
                } else {
                    this.setState({
                        microlocation: false
                    })
                }

                if(data.country === 1) {
                    this.setState({
                        country: true
                    })
                } else {
                    this.setState({
                        country: false
                    })
                }

                if(data.port_of_loading === 1) {
                    this.setState({
                        port_of_loading: true
                    })
                } else {
                    this.setState({
                        port_of_loading: false
                    })
                }

                if(data.transshipment_port === 1) {
                    this.setState({
                        transshipment_port: true
                    })
                } else {
                    this.setState({
                        transshipment_port: false
                    })
                }

                if(data.port_of_discharging === 1) {
                    this.setState({
                        port_of_discharging: true
                    })
                } else {
                    this.setState({
                        port_of_discharging: false
                    })
                }

                if(data.inspected_by === 1) {
                    this.setState({
                        inspected_by: true
                    })
                } else {
                    this.setState({
                        inspected_by: false
                    })
                }

                if(data.transport_company === 1) {
                    this.setState({
                        transport_company: true
                    })
                } else {
                    this.setState({
                        transport_company: false
                    })
                }

                if(data.transport_id === 1) {
                    this.setState({
                        transport_id: true
                    })
                } else {
                    this.setState({
                        transport_id: false
                    })
                }

                if(data.transport_type === 1) {
                    this.setState({
                        transport_type: true
                    })
                } else {
                    this.setState({
                        transport_type: false
                    })
                }

                if(data.order === 1) {
                    this.setState({
                        order: true
                    })
                } else {
                    this.setState({
                        order: false
                    })
                }
            })
    }

    handleSubmit(e) {
        e.preventDefault();
        console.log(this.state);

        let sendData = {
            make: this.state.make,
            model: this.state.model,
            vin: this.state.vin,
            part: this.state.part,
            type: this.state.type,
            severity: this.state.severity,
            cause: this.state.cause,
            repair_mode: this.state.repair_mode,
            class: this.state.class,
            dln: this.state.dln,
            comment: this.state.comment,
            first_seen: this.state.first_seen,
            inspection_place: this.state.inspection_place,
            microlocation: this.state.microlocation,
            country: this.state.country,
            port_of_loading: this.state.port_of_loading,
            transshipment_port: this.state.transshipment_port,
            port_of_discharging: this.state.port_of_discharging,
            inspected_by: this.state.inspected_by,
            transport_company: this.state.transport_company,
            transport_id: this.state.transport_id,
            transport_type: this.state.transport_type,
            order: this.state.order,
            privacyPolicy: this.state.privacyPolicy,
            custom_comment: this.state.custom_comment,
        }

        let config = {
            responseType: "blob"
        };
        config.headers = {
            'Authorization': 'Bearer ' + Store.get('accessToken').token
        };

        Axios.post(process.env.REACT_APP_API_BASE + '/orders/' + this.state.id + '/reports/preliminary', sendData, config)
            .then(res => res.data)
            .then(blob => saveAs(blob, 'preliminary-report'))

        /*Axios.post(process.env.REACT_APP_API_BASE + '/orders/' + this.state.id + '/reports/preliminary', sendData, {
            headers: {
                'Authorization': 'Bearer ' + this.state.token
            }
        }).then(res => {
            console.log(res);
        })*/
    }

    handleAddition(e) {
        let recipients = [...this.state.recipients];
        recipients.push(e.text);
        console.log(recipients);
        this.setState({
            recipients: recipients
        })
    }

    render() {
        return (
            <div className="container pb-10" id="main">
                <div className="row mb-2">
                    <div className="col-12 col-md-8">
                        <h1 className={"mt-1"}>Generate preliminary report: {this.state.id}</h1>
                    </div>
                </div>

                <div className="form-group mt-2">
                    <h6>Select mapping</h6>
                    <select className={"form-select"} name={"mapping"} id={"mapping"} onChange={this.handleMappingChange}>
                        <option value="">Select</option>
                        {this.state.mappings.map(mapping => {
                            return <option value={mapping.id}>{mapping.company.name}</option>
                        })}
                    </select>
                </div>

                <div className="row">
                    <div className="col-12 col-md-8">
                        <h2>Select data to include in report</h2>

                        <form action="" className={"mb-5"} onSubmit={this.handleSubmit}>

                            <div className={'layout-grid-3'}>
                                <div>
                                    <h6 className="mt-5">Make</h6>

                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="true" className="form-check-input" checked={this.state.make === true} type="radio" name="make"
                                               id="flexRadioDefault1">
                                        </input>Yes
                                    </div>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="false" className="form-check-input" checked={this.state.make === false} type="radio" name="make"
                                               id="flexRadioDefault2">
                                        </input>No
                                    </div>
                                </div>

                                <div>
                                    <h6 className="mt-5">Model</h6>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="true" className="form-check-input" checked={this.state.model === true} type="radio" name="model"
                                               id="flexRadioDefault1">
                                        </input>Yes
                                    </div>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="false" className="form-check-input" checked={this.state.model === false} type="radio" name="model"
                                               id="flexRadioDefault2">
                                        </input>No
                                    </div>
                                </div>

                                <div>
                                    <h6 className="mt-5">VIN</h6>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="true" className="form-check-input" checked={this.state.vin === true} type="radio" name="vin"
                                               id="flexRadioDefault1">
                                        </input>Yes
                                    </div>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="false" className="form-check-input" checked={this.state.vin === false} type="radio" name="vin"
                                               id="flexRadioDefault2">
                                        </input>No
                                    </div>
                                </div>

                                <div>
                                    <h6 className="mt-5">Part</h6>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="true" className="form-check-input" checked={this.state.part === true} type="radio" name="part"
                                               id="flexRadioDefault1">
                                        </input>Yes
                                    </div>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="false" className="form-check-input" checked={this.state.part === false} type="radio" name="part"
                                               id="flexRadioDefault2">
                                        </input>No
                                    </div>
                                </div>

                                <div>
                                    <h6 className="mt-5">Type</h6>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="true" className="form-check-input" checked={this.state.type === true} type="radio" name="type"
                                               id="flexRadioDefault1">
                                        </input>Yes
                                    </div>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="false" className="form-check-input" checked={this.state.type === false} type="radio" name="type"
                                               id="flexRadioDefault2">
                                        </input>No
                                    </div>
                                </div>

                                <div>
                                    <h6 className="mt-5">Severity</h6>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="true" className="form-check-input" checked={this.state.severity === true} type="radio" name="severity"
                                               id="flexRadioDefault1">
                                        </input>Yes
                                    </div>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="false" className="form-check-input" checked={this.state.severity === false} type="radio" name="severity"
                                               id="flexRadioDefault2">
                                        </input>No
                                    </div>
                                </div>

                                <div>
                                    <h6 className="mt-5">Cause</h6>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="true" className="form-check-input" checked={this.state.cause === true} type="radio" name="cause"
                                               id="flexRadioDefault1">
                                        </input>Yes
                                    </div>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="false" className="form-check-input" checked={this.state.cause === false} type="radio" name="cause"
                                               id="flexRadioDefault2">
                                        </input>No
                                    </div>
                                </div>

                                <div>
                                    <h6 className="mt-5">Repair mode</h6>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="true" className="form-check-input" checked={this.state.repair_mode === true} type="radio" name="repair_mode"
                                               id="flexRadioDefault1">
                                        </input>Yes
                                    </div>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="false" className="form-check-input" checked={this.state.repair_mode === false} type="radio" name="repair_mode"
                                               id="flexRadioDefault2">
                                        </input>No
                                    </div>
                                </div>

                                <div>
                                    <h6 className="mt-5">Class</h6>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="true" className="form-check-input" checked={this.state.class === true} type="radio" name="class"
                                               id="flexRadioDefault1">
                                        </input>Yes
                                    </div>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="false" className="form-check-input" checked={this.state.class === false} type="radio" name="class"
                                               id="flexRadioDefault2">
                                        </input>No
                                    </div>
                                </div>

                                <div>
                                    <h6 className="mt-5">DLN</h6>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="true" className="form-check-input" checked={this.state.dln === true} type="radio" name="dln"
                                               id="flexRadioDefault1">
                                        </input>Yes
                                    </div>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="false" className="form-check-input" checked={this.state.dln === false} type="radio" name="dln"
                                               id="flexRadioDefault2">
                                        </input>No
                                    </div>
                                </div>

                                <div>
                                    <h6 className="mt-5">Comment</h6>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="true" className="form-check-input" checked={this.state.comment === true} type="radio" name="comment"
                                               id="flexRadioDefault1">
                                        </input>Yes
                                    </div>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="false" className="form-check-input" checked={this.state.comment === false} type="radio" name="comment"
                                               id="flexRadioDefault2">
                                        </input>No
                                    </div>
                                </div>

                                <div>
                                    <h6 className="mt-5">First seen</h6>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="true" className="form-check-input" checked={this.state.first_seen === true} type="radio" name="first_seen"
                                               id="flexRadioDefault1">
                                        </input>Yes
                                    </div>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="false" className="form-check-input" checked={this.state.first_seen === false} type="radio" name="first_seen"
                                               id="flexRadioDefault2">
                                        </input>No
                                    </div>
                                </div>

                                <div>
                                    <h6 className="mt-5">Inspection place</h6>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="true" className="form-check-input" checked={this.state.inspection_place === true} type="radio" name="inspection_place"
                                               id="flexRadioDefault1">
                                        </input>Yes
                                    </div>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="false" className="form-check-input" type="radio" checked={this.state.inspection_place === false} name="inspection_place"
                                               id="flexRadioDefault2">
                                        </input>No
                                    </div>
                                </div>

                                <div>
                                    <h6 className="mt-5">Microlocation</h6>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="true" className="form-check-input" checked={this.state.microlocation === true} type="radio" name="microlocation"
                                               id="flexRadioDefault1">
                                        </input>Yes
                                    </div>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="false" className="form-check-input" type="radio" checked={this.state.microlocation === false} name="microlocation"
                                               id="flexRadioDefault2">
                                        </input>No
                                    </div>
                                </div>

                                <div>
                                    <h6 className="mt-5">Country</h6>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="true" className="form-check-input" checked={this.state.country === true} type="radio" name="country"
                                               id="flexRadioDefault1">
                                        </input>Yes
                                    </div>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="false" className="form-check-input" type="radio" checked={this.state.country === false} name="country"
                                               id="flexRadioDefault2">
                                        </input>No
                                    </div>
                                </div>

                                <div>
                                    <h6 className="mt-5">Port of loading</h6>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="true" className="form-check-input" checked={this.state.port_of_loading === true} type="radio" name="port_of_loading"
                                               id="flexRadioDefault1">
                                        </input>Yes
                                    </div>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="false" className="form-check-input" type="radio" checked={this.state.port_of_loading === false} name="port_of_loading"
                                               id="flexRadioDefault2">
                                        </input>No
                                    </div>
                                </div>

                                <div>
                                    <h6 className="mt-5">Transshipment port</h6>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="true" className="form-check-input" checked={this.state.transshipment_port === true} type="radio" name="transshipment_port"
                                               id="flexRadioDefault1">
                                        </input>Yes
                                    </div>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="false" className="form-check-input" type="radio" checked={this.state.transshipment_port === false} name="transshipment_port"
                                               id="flexRadioDefault2">
                                        </input>No
                                    </div>
                                </div>

                                <div>
                                    <h6 className="mt-5">Port of discharging</h6>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="true" className="form-check-input" checked={this.state.port_of_discharging === true} type="radio" name="port_of_discharging"
                                               id="flexRadioDefault1">
                                        </input>Yes
                                    </div>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="false" className="form-check-input" type="radio" checked={this.state.port_of_discharging === false} name="port_of_discharging"
                                               id="flexRadioDefault2">
                                        </input>No
                                    </div>
                                </div>

                                <div>
                                    <h6 className="mt-5">Inspected by</h6>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="true" className="form-check-input" checked={this.state.inspected_by === true} type="radio" name="inspected_by"
                                               id="flexRadioDefault1">
                                        </input>Yes
                                    </div>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="false" className="form-check-input" type="radio" checked={this.state.inspected_by === false} name="inspected_by"
                                               id="flexRadioDefault2">
                                        </input>No
                                    </div>
                                </div>

                                <div>
                                    <h6 className="mt-5">Transport company</h6>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="true" className="form-check-input" checked={this.state.transport_company === true} type="radio" name="transport_company"
                                               id="flexRadioDefault1">
                                        </input>Yes
                                    </div>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="false" className="form-check-input" type="radio" checked={this.state.transport_company === false} name="transport_company"
                                               id="flexRadioDefault2">
                                        </input>No
                                    </div>
                                </div>

                                <div>
                                    <h6 className="mt-5">Transport ID</h6>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="true" className="form-check-input" checked={this.state.transport_id === true} type="radio" name="transport_id"
                                               id="flexRadioDefault1">
                                        </input>Yes
                                    </div>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="false" className="form-check-input" type="radio" checked={this.state.transport_id === false} name="transport_id"
                                               id="flexRadioDefault2">
                                        </input>No
                                    </div>
                                </div>

                                <div>
                                    <h6 className="mt-5">Transport type</h6>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="true" className="form-check-input" checked={this.state.transport_type === true} type="radio" name="transport_type"
                                               id="flexRadioDefault1">
                                        </input>Yes
                                    </div>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="false" className="form-check-input" type="radio" checked={this.state.transport_type === false} name="transport_type"
                                               id="flexRadioDefault2">
                                        </input>No
                                    </div>
                                </div>

                                <div>
                                    <h6 className="mt-5">Order ID</h6>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="true" className="form-check-input" checked={this.state.order === true} type="radio" name="order"
                                               id="flexRadioDefault1">
                                        </input>Yes
                                    </div>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="false" className="form-check-input" type="radio" checked={this.state.order === false} name="order"
                                               id="flexRadioDefault2">
                                        </input>No
                                    </div>
                                </div>
                            </div>

                            <div className="form-group mt-5">
                                <h6>Privacy policy</h6>
                                <select className={"form-select"} name={"privacyPolicy"} id={"privacyPolicy"} onChange={this.handleChange}>
                                    <option value="">Select</option>
                                    {this.state.privacyPolicies.map(policy => {
                                        return <option value={policy.id}>{policy.text}</option>
                                    })}
                                </select>
                            </div>

                            <div className="form-group mt-5">
                                <h6>Custom comment</h6>
                                <textarea className={'form-control'} rows="4" name="custom_comment" width="100" value={this.state.custom_comment} onChange={this.handleChange}></textarea>
                            </div>

                            <button type={'submit'} className={'btn btn-primary'}>Send</button>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

CreatePreliminaryReport.propTypes = {};

export default CreatePreliminaryReport;
