import React from "react";
import {
    Link,
    Route,
    withRouter
} from "react-router-dom";
import Store from "store";


import Navigation from "./Navigation/Navigation";
import SideNavigation from "./SideNavigation/SideNavigation";
import AccountSettings from "./AccountSettings/AccountSettings";
import EditAdmin from "./Admins/EditAdmin/EditAdmin";
import AddNewAdmin from "./Admins/AddNewAdmin/AddNewAdmin";
import AddNewAccountant from "./Accountants/AddNewAccountant/AddNewAccountant";
import AddNewInspector from "./Inspectors/AddNewInspector/AddNewInspector";
import AddNewManager from "./Managers/AddNewManager/AddNewManager";
import Admins from "./Admins/Admins";
import Orders from "./Orders/Orders";
import AddNewOrder from "./Orders/AddNewOrder/AddNewOrder";
import EditOrder from "./Orders/EditOrder/EditOrder";
import SingleOrder from "./Orders/SingleOrder/SingleOrder";
import AllOrders from "./Orders/AllOrders/AllOrders";
import AddNewCargoItem from "./Orders/SingleOrder/AddNewCargoItem/AddNewCargoItem";


import EditClient from "./Clients/EditClient/EditClient";
import Clients from "./Clients/Clients";
import AddNewClient from "./Clients/AddNewClient/AddNewClient";

import ports from "./Ports/Ports";
import AddNewPort from "./Ports/AddNewPort/AddNewPort";
import EditPort from "./Ports/EditPort/EditPort";

import Transports from "./Transports/Transports";
import AddNewTransport from "./Transports/AddNewTransport/AddNewTransport";
import EditTransport from "./Transports/EditTransport/EditTransport";
import DamageSets from "./DamageSets/DamageSets";
import AddNewDamageSet from "./DamageSets/AddNewDamageSet/AddNewDamageSet";
import EditDamageSet from "./DamageSets/EditDamageSet/EditDamageSet";
import EditTransportCompany from "./TransportCompanies/EditTransportCompany/EditTransportCompany";
import TransportCompanies from "./TransportCompanies/TransportCompanies";
import AddNewTransportCompany from "./TransportCompanies/AddNewTransportCompany/AddNewTransportCompany";
import VinDecoder from "./VinDecoder/VinDecoder";
import AddNewMake from "./VinDecoder/Makes/AddNewMake/AddNewMake";
import SingleMake from "./VinDecoder/Makes/SingleMake/SingleMake";
import EditCargoItem from "./Orders/SingleOrder/EditCargoItem/EditCargoItem";
import AresEye from "./AresEye/AresEye";
import Stock from "./Stock/Stock";
import GroupedOrders from "../../GroupedOrders/GroupedOrders";
import Unallocated from "./Unallocated/Unallocated";
import History from "./History/History";
import AllUsers from "./AllUsers/AllUsers";
import EditAccountant from "./Accountants/EditAccountant/EditAccountant";
import EditInspector from "./Inspectors/EditInspector/EditInspector";
import EditManager from "./Managers/EditManager/EditManager";
import CreatePreliminaryReport from "./PreliminaryReport/CreatePreliminaryReport/CreatePreliminaryReport";
import ClientAddNewOrder from "./Orders/ClientAddNewOrder/ClientAddNewOrder";
import ManagerAddNewOrder from "./Orders/ManagerAddNewOrder/ManagerAddNewOrder";
import CreateFinalReport from "./FinalReport/CreateFinalReport";
import Mappings from "./Mapping/Mappings";
import Destinations from "./Destinations/Destinations";
import AddNewDestination from "./Destinations/AddNewDestination/AddNewDestination";
import EditDestination from "./Destinations/EditDestination/EditDestination";
import CreateNewMapping from "./Mapping/CreateNewMapping/CreateNewMapping";
import CreateNewClientMapping from "./Mapping/CreateNewMapping/CreateNewClientMapping";
import EditMapping from "./Mapping/EditMapping/EditMapping";
import DamageSetsShortcuts from "./DamageSetsShortcuts/DamageSetsShortcuts";
import AddNewDamageSetShortcut from "./DamageSetsShortcuts/AddNewDamageSetShortcut/AddNewDamageSetShortcut";
import EditDamageSetShortcut from "./DamageSetsShortcuts/AddNewDamageSetShortcut/EditDamageSetShortcut";


class Dashboard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            user: Store.get('user'),
            token: Store.get('accessToken').token,
            role: Store.get('accessToken').role,
            orderCount: null,
        };

        this.userUpdate = this.userUpdate.bind(this);
        this.updateCounter = this.updateCounter.bind(this);
    }

    userUpdate() {
        this.setState({
            user: Store.get('user')
        })
    }


    updateCounter(count) {
        this.setState({
            orderCount: count
        })
    }

    componentDidMount() {
        console.log(this.state.role);
    }

    render() {
        return (
            <div id="wrapper" style={{
                display: "flex",
                width: "100%",
            }}>
                <Navigation user={this.state.user}/>
                <nav id="sidebar">
                <SideNavigation/>

                </nav>
                <div id="content">
                    <div class="container-fluid container-main">
                    
                    <Route path="/dashboard/users" exact component={AllUsers}/>

                    <Route path="/dashboard/admins/add-new" exact component={AddNewAdmin}/>
                    <Route path="/dashboard/admins/edit/:entryId" exact component={() => <EditAdmin userUpdate={this.userUpdate} />}/>

                    <Route path="/dashboard/accountants/add-new" exact component={AddNewAccountant}/>
                    <Route path="/dashboard/accountants/edit/:entryId" exact component={() => <EditAccountant userUpdate={this.userUpdate} />}/>

                    <Route path="/dashboard/inspectors/add-new" exact component={AddNewInspector}/>
                    <Route path="/dashboard/inspectors/edit/:entryId" exact component={() => <EditInspector userUpdate={this.userUpdate} />}/>

                    <Route path="/dashboard/managers/add-new" exact component={AddNewManager}/>
                    <Route path="/dashboard/managers/edit/:entryId" exact component={() => <EditManager userUpdate={this.userUpdate} />}/>

                    <Route path="/dashboard/ares-eye" exact component={AresEye}/>
                    <Route path="/dashboard/ares-eye/:searchQuery" exact component={() => <AresEye />}/>

                    <Route path="/dashboard/history" exact component={History}/>
                    <Route path="/dashboard/history/:searchQuery" exact component={() => <History />}/>

                    <Route path="/dashboard/stock" exact component={Stock}/>
                    <Route path="/dashboard/unallocated" exact component={Unallocated}/>

                    <Route path="/dashboard/clients" exact component={Clients}/>
                    <Route path="/dashboard/clients/add-new" exact component={AddNewClient}/>
                    <Route path="/dashboard/clients/edit/:entryId" exact component={() => <EditClient userUpdate={this.userUpdate} />}/>

                    <Route path="/dashboard/ports" exact component={ports}/>
                    <Route path="/dashboard/ports/add-new" exact component={AddNewPort}/>
                    <Route path="/dashboard/ports/edit/:entryId" exact component={() => <EditPort userUpdate={this.userUpdate} />}/>

                    <Route path="/dashboard/mapping" exact component={Mappings}/>
                    <Route path="/dashboard/mapping/add-new" exact component={CreateNewMapping}/>
                    <Route path="/dashboard/mapping/edit/:entryId" exact component={EditMapping}/>
                    <Route path="/dashboard/mapping/clients/add-new" exact component={CreateNewClientMapping}/>
                    <Route path="/dashboard/mapping/clients/edit/:entryId" exact component={EditMapping}/>

                    <Route path="/dashboard/transports" exact component={Transports}/>
                    <Route path="/dashboard/transports/add-new" exact component={AddNewTransport}/>
                    <Route path="/dashboard/transports/edit/:entryId" exact component={() => <EditTransport userUpdate={this.userUpdate} />}/>

                    <Route path="/dashboard/transport-companies" exact component={TransportCompanies}/>
                    <Route path="/dashboard/transport-companies/add-new" exact component={AddNewTransportCompany}/>
                    <Route path="/dashboard/transport-companies/edit/:entryId" exact component={() => <EditTransportCompany />}/>


                    <Route path="/dashboard/damage-sets" exact component={DamageSets}/>
                    <Route path="/dashboard/damage-sets/add-new" exact component={AddNewDamageSet}/>
                    <Route path="/dashboard/damage-sets/edit/:entryId" exact component={() => <EditDamageSet userUpdate={this.userUpdate} />}/>

                    <Route path="/dashboard/damage-sets-shortcuts" exact component={DamageSetsShortcuts}/>
                    <Route path="/dashboard/new-damage-set-shortcut" exact component={AddNewDamageSetShortcut}/>
                    <Route path="/dashboard/damage-set-shortcut/edit/:entryId" exact component={() => <EditDamageSetShortcut userUpdate={this.userUpdate} />}/>


                    <Route path="/dashboard/orders" exact component={AllOrders}/>
                    <Route path="/dashboard/orders/add-new" exact component={AddNewOrder}/>
                    <Route path="/dashboard/orders/client-add-new" exact component={ClientAddNewOrder}/>
                    <Route path="/dashboard/orders/manager-add-new" exact component={ManagerAddNewOrder}/>
                    <Route path="/dashboard/orders/edit/:entryId" exact component={EditOrder}/>
                    <Route path="/dashboard/orders/order/:entryId" exact component={SingleOrder}/>
                    <Route path="/dashboard/orders/order/:entryId/add-cargo-item" exact component={AddNewCargoItem}/>
                    <Route path="/dashboard/orders/order/:entryId/edit-cargo-item/:itemId" exact component={EditCargoItem}/>
                    <Route path="/dashboard/orders/:entryId/preliminaryreport" exact component={CreatePreliminaryReport} />
                    <Route path="/dashboard/orders/:entryId/finalreport" exact component={CreateFinalReport} />

                    <Route path="/dashboard/account-settings" exact component={AccountSettings}/>

                    <Route path="/dashboard/destinations" exact component={Destinations} />
                    <Route path="/dashboard/destinations/add-new" exact component={AddNewDestination} />
                    <Route path="/dashboard/destinations/edit/:entryId" exact component={EditDestination} />

                    <Route path="/dashboard/vin-decoder" exact component={VinDecoder}/>
                    <Route path="/dashboard/vin-decoder/makes/add-new" exact component={AddNewMake}/>
                    <Route path="/dashboard/vin-decoder/makes/make/:entryId" exact component={SingleMake}/>

                    <Route path="/dashboard" exact>
                    <div id="main" className={"pt-6"}>
                        <section className={"bg-white"}>
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-12 col-md-6">
                                        <h4>Welcome back, {this.state.user?.name}</h4>

                                    </div>
                                </div>
                            </div>
                        </section>
                        

                        <GroupedOrders updateCount={this.updateCounter}/>



                    </div>
                </Route>
                    

                    </div>
                </div>
            </div>

            /*<div id="wrapper">

                <Navigation user={this.state.user}/>
                <SideNavigation/>

                <Route path="/dashboard/users" exact component={AllUsers}/>

                <Route path="/dashboard/admins/add-new" exact component={AddNewAdmin}/>
                <Route path="/dashboard/admins/edit/:entryId" exact component={() => <EditAdmin userUpdate={this.userUpdate} />}/>

                <Route path="/dashboard/accountants/add-new" exact component={AddNewAccountant}/>
                <Route path="/dashboard/accountants/edit/:entryId" exact component={() => <EditAccountant userUpdate={this.userUpdate} />}/>

                <Route path="/dashboard/inspectors/add-new" exact component={AddNewInspector}/>
                <Route path="/dashboard/inspectors/edit/:entryId" exact component={() => <EditInspector userUpdate={this.userUpdate} />}/>

                <Route path="/dashboard/managers/add-new" exact component={AddNewManager}/>
                <Route path="/dashboard/managers/edit/:entryId" exact component={() => <EditManager userUpdate={this.userUpdate} />}/>

                <Route path="/dashboard/ares-eye" exact component={AresEye}/>
                <Route path="/dashboard/ares-eye/:searchQuery" exact component={() => <AresEye />}/>

                <Route path="/dashboard/history" exact component={History}/>
                <Route path="/dashboard/history/:searchQuery" exact component={() => <History />}/>

                <Route path="/dashboard/stock" exact component={Stock}/>
                <Route path="/dashboard/unallocated" exact component={Unallocated}/>

                <Route path="/dashboard/clients" exact component={Clients}/>
                <Route path="/dashboard/clients/add-new" exact component={AddNewClient}/>
                <Route path="/dashboard/clients/edit/:entryId" exact component={() => <EditClient userUpdate={this.userUpdate} />}/>

                <Route path="/dashboard/ports" exact component={ports}/>
                <Route path="/dashboard/ports/add-new" exact component={AddNewPort}/>
                <Route path="/dashboard/ports/edit/:entryId" exact component={() => <EditPort userUpdate={this.userUpdate} />}/>

                <Route path="/dashboard/transports" exact component={Transports}/>
                <Route path="/dashboard/transports/add-new" exact component={AddNewTransport}/>
                <Route path="/dashboard/transports/edit/:entryId" exact component={() => <EditTransport userUpdate={this.userUpdate} />}/>

                <Route path="/dashboard/transport-companies" exact component={TransportCompanies}/>
                <Route path="/dashboard/transport-companies/add-new" exact component={AddNewTransportCompany}/>
                <Route path="/dashboard/transport-companies/edit/:entryId" exact component={() => <EditTransportCompany />}/>


                <Route path="/dashboard/damage-sets" exact component={DamageSets}/>
                <Route path="/dashboard/damage-sets/add-new" exact component={AddNewDamageSet}/>
                <Route path="/dashboard/damage-sets/edit/:entryId" exact component={() => <EditDamageSet userUpdate={this.userUpdate} />}/>


                <Route path="/dashboard/orders" exact component={AllOrders}/>
                <Route path="/dashboard/orders/add-new" exact component={AddNewOrder}/>
                <Route path="/dashboard/orders/client-add-new" exact component={ClientAddNewOrder}/>
                <Route path="/dashboard/orders/manager-add-new" exact component={ManagerAddNewOrder}/>
                <Route path="/dashboard/orders/edit/:entryId" exact component={EditOrder}/>
                <Route path="/dashboard/orders/order/:entryId" exact component={SingleOrder}/>
                <Route path="/dashboard/orders/order/:entryId/add-cargo-item" exact component={AddNewCargoItem}/>
                <Route path="/dashboard/orders/order/:entryId/edit-cargo-item/:itemId" exact component={EditCargoItem}/>
                <Route path="/dashboard/orders/:entryId/preliminaryreport" exact component={CreatePreliminaryReport} />
                <Route path="/dashboard/orders/:entryId/finalreport" exact component={CreateFinalReport} />

                <Route path="/dashboard/account-settings" exact component={AccountSettings}/>

                <Route path="/dashboard/vin-decoder" exact component={VinDecoder}/>
                <Route path="/dashboard/vin-decoder/makes/add-new" exact component={AddNewMake}/>
                <Route path="/dashboard/vin-decoder/makes/make/:entryId" exact component={SingleMake}/>


                <Route path="/dashboard" exact>
                    <div id="main" className={"pt-6"}>
                        <section className={"bg-white"}>
                            <div className="container py-5">
                                <div className="row">
                                    <div className="col-12 col-md-6">
                                        <h4>Welcome back, {this.state.user?.name}</h4>

                                    </div>
                                </div>
                            </div>
                        </section>
                        

                        <GroupedOrders updateCount={this.updateCounter}/>



                    </div>
                </Route>
            </div>*/
        );
    }

}

export default withRouter(Dashboard);
