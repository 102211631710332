import React from "react";
import Store from "store";
import Axios from "axios";
import {
    getOrder,
    getOrderItems,
    getPorts,
    getTransportCompanies, getTransportModes,
    getTransports, validateDuplicateVin
} from "../../../../../utils/utils";
import {Link} from "react-router-dom";
import Select from "react-select";

import countries from "../../../../../fixedData/countries.json";


import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import {vinValidation} from "../../../../../utils/VinValidation";
const SuccessModal = withReactContent(Swal);
const ErrModal = withReactContent(Swal);

class AddNewCargoItem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            token: Store.get('accessToken').token,
            orderId: this.props.match.params.entryId,
            vin: '',
            weather: '',
            portOfOriginArr: null,
            portOfOrigin: null,
            transshipmentPort: null,
            portOfDischarging: null,
            destination: null,
            transportArr: null,
            transport: null,
            orderItems: [],
            bl: null,
            info: null,
            voyage: null,
            transportCompany: null,
            transportCompaniesArr: [],
            transportModesArr: [],
            transportMode: null
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.countriesSelect = this.countriesSelect.bind(this);
        this.attachItemToOrder = this.attachItemToOrder.bind(this);
    }


    attachItemToOrder(itemId) {
        return new Promise((resolve, reject) => {
            if (!itemId) {
                reject('missing id');
            }

            let items = [];
            if (this.state.orderItems && this.state.orderItems.length > 0) {
                for (let orderItem of this.state.orderItems) {
                    items.push(orderItem.id);
                }
            }

            items.push(itemId);

            /*Axios.put(process.env.REACT_APP_API_BASE + '/orders/' + this.state.orderId, {
                'items': items,
            }, {
                headers: {
                    'Authorization': 'Bearer ' + this.state.token
                }
            } )
                .then(response => {
                    if (response.status === 200) {
                        resolve(response);
                    }
                })
                .catch(error => {
                    reject(error);
                })*/
        });
    }


    handleSubmit(e) {
        e.preventDefault();

        if (vinValidation[this.state.cargoType] && !vinValidation[this.state.cargoType](this.state.vin)) {
            return ErrModal.fire({
                icon: "error",
                title: "VIN validation failed"
            });
        }

        validateDuplicateVin([{vin:this.state.vin}], this.state.orderId)
            .then((r) => {

                if (!r.valid.length > 0) {
                    throw new Error('Duplicate cargo item');
                }

                let data = {
                    vin: this.state.vin,
                    portOfOrigin: this.state.portOfOrigin,
                    transshipmentPort: this.state.transshipmentPort,
                    portOfDischarging: this.state.portOfDischarging,
                    destination: this.state.destination,
                    transport: this.state.transport,
                    bl: this.state.bl,
                    info: this.state.info,
                    voyage: this.state.voyage,
                    order_id: this.state.orderId,
                    transportCompany: this.state.transportCompany,
                    transportMode: this.state.transportMode
                };

                return Axios.post(process.env.REACT_APP_API_BASE + '/items', data, {
                    headers: {
                        'Authorization': 'Bearer ' + this.state.token
                    }
                } )
            })
            .then(response => {
                if (response.status === 201) {
                    SuccessModal.fire({
                        icon: "success",
                        title: 'Successfully added cargo item to order',
                    }).then(() => {
                        this.props.history.push('/dashboard/orders/order/' + this.state.orderId);
                    });
                }
                if(response.status === 202) {
                    SuccessModal.fire({
                        icon: "success",
                        title: 'Successfully added cargo item to order. WARNING: make/model not detected',
                    }).then(() => {
                        this.props.history.push('/dashboard/orders/order/' + this.state.orderId);
                    });
                }
            })
            .catch((error) => {
                ErrModal.fire({
                    icon: "error",
                    title: "Something went wrong."
                });
            });



    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handleSelectChange(obj, e) {

        this.setState({
            [e.name]: obj.value
        })
    }


    componentDidMount() {

        let tempPorts = [];
        getPorts().then(resp => {
            for (const item of resp) {
                tempPorts.push({
                    'value': item.id,
                    'label': item.name + ' (' + item.code + ')'
                });
            }
        }).then(() => {
            this.setState({
                'portOfOriginArr': tempPorts
            });
        });

        let tempTransports = [];
        getTransports().then(resp => {
            console.log(resp);
            for (const item of resp) {
                tempTransports.push({
                    'value': item.id,
                    'label': item.identificationName + ' (' + item.transportDetailsId + ')'
                });
            }
        }).then(() => {
            this.setState({
                'transportsArr': tempTransports
            });
        });


        let tempTransportModes = [];
        getTransportModes(this.state.orderId).then(resp => {
            for (const item of resp) {
                tempTransportModes.push({
                    'value': item.id,
                    'label': item.field_1 + ' - ' + item.field_2
                });
            }
        }).then(() => {
            this.setState({
                'transportModesArr': tempTransportModes
            });
        });

        let tempTransportCompanies = [];
        getTransportCompanies().then(resp => {
            for (const item of resp) {
                tempTransportCompanies.push({
                    'value': item.id,
                    'label': item.name
                });
            }
        }).then(() => {
            this.setState({
                'transportCompaniesArr': tempTransportCompanies
            });
        });

        getOrder(this.state.orderId).then(r => {
            this.setState({
                cargoType: r.cargo_type
            })
        });


        getOrderItems(this.state.orderId).then(resp => {
            if (resp) {
                this.setState({
                    orderItems: resp
                });
            }
        });

    }


    countriesSelect() {
        if (countries) {
            return (
                <select className={"form-select"} name={"destination"} id={"destination"} value={this.state.destination} onChange={this.handleChange}>
                    <option value="">Select Destination</option>
                    {countries.map(item => {
                        return (
                            <option value={item.name}>{item.name}</option>
                        );
                    })}
                </select>
            )
        }
    }



    render() {
        return (
            <div className="container pb-10" id="main">
                <div className="row mb-5">
                    <div className="col-12 col-md-8">
                        <Link to={"/dashboard/orders/order/"+this.state.orderId} className={"btn-min"}>
                            <span className="ml-1">Back to order</span>
                        </Link>
                        <h1 className={"mt-1"}>Add new cargo item</h1>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 col-md-8">
                        <form action="" className={"mb-5"} onSubmit={this.handleSubmit}>

                            <div className="row mb-3">
                                <div className="col-12">
                                    <div className="form-group">
                                        <label htmlFor="vin">VIN*</label>
                                        <input
                                            type={"text"}
                                            className={"form-control"}
                                            onChange={this.handleChange}
                                            value={this.state.vin}
                                            name={"vin"}
                                            required
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row mb-3">
                                <div className="col-12 col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="portOfOrigin">Port of Origin</label>
                                        <Select
                                            closeMenuOnSelect={true}
                                            options={this.state.portOfOriginArr}
                                            onChange={this.handleSelectChange}
                                            name={"portOfOrigin"}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="transshipmentPort">Transshipment Port</label>
                                        <Select
                                            closeMenuOnSelect={true}
                                            options={this.state.portOfOriginArr}
                                            onChange={this.handleSelectChange}
                                            name={"transshipmentPort"}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="portOfDischarging">Port of Discharging</label>
                                        <Select
                                            closeMenuOnSelect={true}
                                            options={this.state.portOfOriginArr}
                                            onChange={this.handleSelectChange}
                                            name={"portOfDischarging"}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row mb-3">
                                <div className="col-12 col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="destination">Destination</label>
                                        {this.countriesSelect()}
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="destination">BL</label>
                                        <input
                                            type={"text"}
                                            placeholder={"Enter BL"}
                                            className={"form-control"}
                                            onChange={this.handleChange}
                                            value={this.state.bl}
                                            name={"bl"}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="voyage">Voyage</label>
                                        <input
                                            type={"text"}
                                            placeholder={"Enter Voyage"}
                                            className={"form-control"}
                                            onChange={this.handleChange}
                                            value={this.state.voyage}
                                            name={"voyage"}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="transport">Transport</label>
                                        <Select
                                            closeMenuOnSelect={true}
                                            options={this.state.transportModesArr}
                                            onChange={this.handleSelectChange}
                                            name={"transportMode"}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="transport">Transport Company</label>
                                        <Select
                                            closeMenuOnSelect={true}
                                            options={this.state.transportCompaniesArr}
                                            onChange={this.handleSelectChange}
                                            name={"transportCompany"}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row mb-3">
                                <div className="form-group">
                                    <label htmlFor="info">Additional Info</label>
                                    <textarea name="info" id="info" className={"form-control"} value={this.state.info} onChange={this.handleChange} />
                                </div>
                            </div>

                            <div className="row mt-3">
                                <div className="col-12">
                                    <button type={"submit"} className={"btn btn-primary"}>Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

AddNewCargoItem.propTypes = {};

export default AddNewCargoItem;
